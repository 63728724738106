var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.verifyAndSubmit)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Voornaam")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.first_name,
                                          expression: "data.first_name",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "first_name",
                                        type: "text",
                                        label: "Voornaam",
                                        placeholder: "Voornaam",
                                      },
                                      domProps: { value: _vm.data.first_name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "first_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Achternaam")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.last_name,
                                          expression: "data.last_name",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "last_name",
                                        type: "text",
                                        label: "Achternaam",
                                        placeholder: "Achternaam",
                                      },
                                      domProps: { value: _vm.data.last_name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "last_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: {
                        rules: "required|email",
                        bails: false,
                        tag: "div",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Emailadres")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.email,
                                          expression: "data.email",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "email",
                                        type: "email",
                                        label: "Emailadres",
                                        placeholder: "Emailadres",
                                      },
                                      domProps: { value: _vm.data.email },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "email",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Telefoonnummer")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.phone,
                                          expression: "data.phone",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "phone",
                                        type: "text",
                                        label: "Telefoonnummer",
                                        placeholder: "Telefoonnummer",
                                      },
                                      domProps: { value: _vm.data.phone },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "phone",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-16",
                                    attrs: { for: "message" },
                                  },
                                  [_vm._v("Bericht")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "textarea",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.message,
                                          expression: "data.message",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "message",
                                        label: "Bericht",
                                        placeholder:
                                          "Plaats hier je Bericht...",
                                      },
                                      domProps: { value: _vm.data.message },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "message",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "textarea",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 mb-gridh" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            attrs: {
                              required: "required",
                              type: "checkbox",
                              value: "",
                              id: "checkakkoord",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "checkakkoord" },
                            },
                            [
                              _vm._v("Ik ga akkoord met het "),
                              _c(
                                "a",
                                {
                                  class: {
                                    "text-gold": _vm.theme === "gold",
                                    "text-primary": _vm.theme === "light",
                                  },
                                  attrs: {
                                    href: "/privacy-policy",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("privacybeleid")]
                              ),
                              _vm._v(" van Copandi "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex align-items-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn d-flex align-items-center justify-content-center",
                            class: {
                              "btn-gold": _vm.theme === "gold",
                              "btn-primary": _vm.theme === "light",
                            },
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v("\n          Verstuur\n          "),
                            _c("el-loader", {
                              staticClass: "ml-16",
                              attrs: { loading: _vm.loading },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.success && !_vm.loading
                          ? _c("div", { staticClass: "ml-40" }, [
                              _c("div", [_vm._v(_vm._s(_vm.successMessage))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "ml-40" },
                              _vm._l(_vm.errors, function (error, key) {
                                return _c(
                                  "div",
                                  {
                                    key: "error_" + key,
                                    staticClass: "text-danger",
                                  },
                                  [_vm._v(_vm._s(error))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }