var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blog-slider row", class: _vm.wrapperClass },
    _vm._l(_vm.blogs, function (blog) {
      return _c(
        "a",
        {
          key: blog.id,
          staticClass: "blog-item d-block col-span-12 col-md-4",
          attrs: { href: _vm.route("blog.show", { slug: blog.slug }) },
        },
        [
          blog.acf && blog.acf.image && blog.acf.image.url
            ? _c("img", {
                staticClass: "lazyload blog-image mb-16 rounded",
                attrs: { "data-src": blog.acf.image.url, alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h5", {
            staticClass: "title font-weight-bold mb-27",
            domProps: {
              innerHTML: _vm._s(
                blog.title.rendered.replace(/(<([^>]+)>)/gi, "")
              ),
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "text-gray mb-0",
            domProps: {
              innerHTML: _vm._s(
                blog.acf.intro.replace(/(<([^>]+)>)/gi, "").substring(0, 100) +
                  "..."
              ),
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }