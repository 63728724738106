var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var handleSubmit = ref.handleSubmit
          return [
            !_vm.showFiles
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("ValidationProvider", {
                          staticClass: "col-12 mb-16",
                          attrs: {
                            rules: "required",
                            bails: false,
                            tag: "div",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var ariaMsg = ref.ariaMsg
                                  var ariaInput = ref.ariaInput
                                  return [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-8 small",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("* Voornaam")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "input",
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.first_name,
                                              expression: "data.first_name",
                                            },
                                          ],
                                          staticClass: "form-control mb-0",
                                          attrs: {
                                            name: "first_name",
                                            type: "text",
                                            label: "Voornaam",
                                            placeholder: "Voornaam",
                                          },
                                          domProps: {
                                            value: _vm.data.first_name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data,
                                                "first_name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        },
                                        "input",
                                        ariaInput,
                                        false
                                      )
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      _vm._b(
                                        { staticClass: "text-danger" },
                                        "span",
                                        ariaMsg,
                                        false
                                      ),
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          staticClass: "col-12 mb-16",
                          attrs: {
                            rules: "required|email",
                            bails: false,
                            tag: "div",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var ariaMsg = ref.ariaMsg
                                  var ariaInput = ref.ariaInput
                                  return [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-8 small",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("* Emailadres")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "input",
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.email,
                                              expression: "data.email",
                                            },
                                          ],
                                          staticClass: "form-control mb-0",
                                          attrs: {
                                            name: "email",
                                            type: "email",
                                            label: "Emailadres",
                                            placeholder: "Emailadres",
                                          },
                                          domProps: { value: _vm.data.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        },
                                        "input",
                                        ariaInput,
                                        false
                                      )
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      _vm._b(
                                        { staticClass: "text-danger" },
                                        "span",
                                        ariaMsg,
                                        false
                                      ),
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          staticClass: "col-12 mb-16",
                          attrs: {
                            rules: "required",
                            bails: false,
                            tag: "div",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var ariaMsg = ref.ariaMsg
                                  var ariaInput = ref.ariaInput
                                  return [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-8 small",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("* Telefoonnummer")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "input",
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.phone,
                                              expression: "data.phone",
                                            },
                                          ],
                                          staticClass: "form-control mb-0",
                                          attrs: {
                                            name: "phone",
                                            type: "text",
                                            label: "Telefoonnummer",
                                            placeholder: "Telefoonnummer",
                                          },
                                          domProps: { value: _vm.data.phone },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        },
                                        "input",
                                        ariaInput,
                                        false
                                      )
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      _vm._b(
                                        { staticClass: "text-danger" },
                                        "span",
                                        ariaMsg,
                                        false
                                      ),
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-gold d-flex align-items-center justify-content-center btn-primary",
                              attrs: { type: "submit" },
                            },
                            [
                              _vm._v(
                                "\n          Download documenten\n          "
                              ),
                              _c("el-loader", {
                                staticClass: "ml-16",
                                attrs: { loading: _vm.loading },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "d-flex flex-column mx-n8" },
                  _vm._l(_vm.docs, function (doc) {
                    return _c(
                      "a",
                      {
                        key: doc.id,
                        staticClass:
                          "d-flex flex-row w-100 align-items-center p-8 document-line",
                        attrs: { href: doc.url, target: "_blank" },
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.svg(
                                "file-download",
                                "file-download flex-none mr-16"
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-secondary w-100 font-weight-bold break-line",
                          },
                          [_vm._v(_vm._s(doc.filename))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }