<style lang="scss" scoped>
.estate-card {
  cursor: default;
  text-decoration: none;
  display: block;
  border: 1px solid #f7f7f7;
  background-color: #ffffff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);

  .estate-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
  }

  .wrapped-line{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 135px;
  }

  &:not(.nc) {
    cursor: pointer;
  }
  &.nc{
    cursor: default;
  }

  .estate-image {
    height: 224px;
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gold-price {
    color: #bba876 !important;
  }

  .estate-footer {
    display: flex;
    align-items: center;
    .icon {
      height: 20px;
      width: 20px;
    }
    .dot {
      height: 16px;
      width: 0.5px;
      background-color: #888888;
      margin: 0 12px;
    }

    .estate-line {
      color: #888888;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      gap: 12px;
    }
  }
  ::v-deep .card-icon {
    width: 22px;
    &.stroke-primary {
      path {
        stroke: #83bf56;
      }
    }
  }
}
</style>
<template>
  <component :is="[3, 4, 5, 6, 16].includes(data.purposeStatus.id) ? 'div' : 'a'" v-if="data" :href="computedRoute" class="estate-card" :class="[wrapperClass, { nc: [3, 4, 5, 6, 16].includes(data.purposeStatus.id) }]">
    <div class="estate-image">
      <img :data-srcset="imageStyle" class="lazyload" />
      <estate-special-label :estate="data"></estate-special-label>
    </div>

    <div class="d-flex flex-column p-16">
      <div class="row">
        <div class="col-6 d-flex justify-content-end align-items-center border-right-lg border-dark">
            <h5 v-if="data.parentId" class="estate-title text-secondary h6">{{ data.name }}</h5> 
            <h5 v-else-if="!data.project_id" class="estate-title text-secondary h6">{{ data.city }}</h5> 
            <h5 v-else class="estate-title text-secondary h6">
                {{ data.description_title }}
            </h5>
        </div>

        <div class="col-6 d-flex justify-content-start align-items-center">
           <h4 v-if="!data.displayPrice" class="h6 estate-price text-primary display-1" :class="propertySpecialType === 'gold' ? 'gold-price' : ''">
            Prijs op aanvraag
           </h4>
           <h5 v-else-if="data.price && [1,2].includes(data.status.id) && ![3, 4, 5, 6, 16].includes(data.purposeStatus.id)" class="h6 estate-price text-primary display-1" :class="propertySpecialType === 'gold' ? 'gold-price' : ''">
               {{ formatPrice(data.price, 'EUR') }} <template v-if="[2].includes(data.purposeStatus.id)">/ maand</template>
           </h5>
          <h5 v-else-if="data.purposeStatus.id === 2" class="estate-price text-primary h6 display-1">Verhuurd</h5>
          <h5 v-else-if="data.purposeStatus.id === 3" class="estate-price text-primary h6 display-1">Verkocht</h5>
          <h5 v-else-if="[12, 13, 21].includes(data.purposeStatus.id)" class="estate-price text-primary h6 display-1">In optie</h5>
          <h5 v-else-if="[5, 6, 16].includes(data.purposeStatus.id)" class="estate-price text-primary h6 display-1">Bod aanvaard</h5>
          <h5 v-else-if="data.is_project" class="estate-price text-primary h6 display-1">Project</h5>
        </div>
      </div>

      <div class="row mt-3">
        <div class="estate-footer col-12 d-flex justify-content-center align-items-center">
          <div v-if="data.subCategory.id" class="estate-line wrapped-line">
            {{ formatSubType(data.subCategory.id) }}
          </div>
          <div v-else-if="data.category.id" class="estate-line wrapped-line">
            {{ formatType(data.category.id) }}
          </div>

          <div v-if="data.category.id" class="dot"></div>

          <div v-if="data.rooms" class="estate-line d-flex justify-content-center align-items-center">
            <span v-html="svg('Slaapkamer', 'card-icon')"></span>
            {{ data.rooms }}
          </div>

          <div v-if="(data.rooms && data.groundArea) || (data.rooms && data.area)" class="dot"></div>

          <div v-if="data.groundArea" class="estate-line d-flex justify-content-center align-items-center">
            <span v-html="svg('PerceelOppervlakte', 'card-icon')"></span>
            <span>{{ Math.round(data.groundArea) }}m<sup>2</sup></span>
          </div>

          <div v-if="data.groundArea && data.area" class="dot"></div>

          <div v-if="data.area" class="estate-line d-flex justify-content-center align-items-center">
            <span v-html="svg('house', 'card-icon stroke-primary')"></span>
            <span>{{ Math.round(data.area) }}m<sup>2</sup></span>
          </div>

          <div v-if="data.box && data.is_project" class="estate-line">Blok {{ data.box }}</div>
          <div v-if="data.floor && data.is_project" class="dot"></div>
          <div v-if="data.floor && data.is_project" class="estate-line">Vloer {{ data.floor }}</div>
        </div>
      </div>
    </div>
  </component :is="[5, 6, 16].includes(data.purposeStatus.id) ? 'div' : 'a'">
</template>

<script>
import estateCard from '@/mixins/estate-card';
import estateSpecialLabel from '@/components/estate-special-label'

export default {
  mixins: [estateCard],
  components: {
    estateSpecialLabel
  },
};
</script>
