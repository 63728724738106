var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{staticClass:"position-relative w-100",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{staticClass:"wrapper",class:{ saved: _vm.success },attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.svg('phone', 'icon mr-16'))}}),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form-control",attrs:{"name":"gsm","type":"text","placeholder":"Jouw GSM nummer ..."},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}},'input',ariaInput,false))]}}],null,true)}),_vm._v(" "),_c('button',{staticClass:"btn",class:{ 'is-disabled': !valid, 'has-success': _vm.success },attrs:{"type":"submit","disabled":!valid && !_vm.success}},[(!_vm.success)?_c('span',[_vm._v("Verstuur")]):_c('span',[_vm._v("Aanvraag verzonden")]),_vm._v(" "),_c('el-loader',{staticClass:"ml-16",attrs:{"loading":_vm.loading}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }