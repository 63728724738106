<style lang="scss" scoped>
.select {
  display: inline-flex;
  flex-flow: row;
  gap: 8px;
  height: 54px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;

  .item {
    cursor: pointer;
    &.active ~ *,
    &.active,
    &:hover ~ *,
    &:hover {
      ::v-deep .star {
        path {
          fill: #83c056;
          stroke: #83c056;
        }
      }
    }
    ::v-deep .star {
      height: 20px;
      path {
        transition: path 0.15s ease-in-out, stroke 0.15s ease-in-out;
        stroke: #e1e1e1;
      }
    }
    &:hover {
    }
    &.active {
    }
  }
}
.description {
  margin-left: 12px;
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}
label {
  color: var(--gray);
  font-family: CoreSansC; // Old Lato
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>

<template>
  <div>
    <label class="d-block">Kwaliteit</label>
    <div class="select">
      <span class="description" v-if="localValue">{{ localValue.name }}</span>
      <div class="item" v-for="item in items" :key="item.name" :class="{ active: localValue && localValue.value === item.value }" @click="handleInput(item)">
        <span v-html="svg('star', 'star')"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String },
    value: { type: String },
  },

  data: () => {
    return {
      items: [
        { name: 'Premium', value: 'luxury' },
        { name: 'Kwalitatief', value: 'high_quality' },
        { name: 'Normaal', value: 'normal' },
        { name: 'Eenvoudig', value: 'simple' },
      ],
      localValue: undefined,
    };
  },

  mounted() {
    if (this.value) {
      this.localValue = this.items.find((v) => v.value === this.value);
    }
  },

  methods: {
    handleInput(value) {
      this.localValue = value;
      this.$emit('input', this.localValue.value);
    },
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        return;
      }
      this.localValue = this.items.find((v) => v.value === newValue);
    },
  },
};
</script>
