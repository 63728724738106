var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('section',{staticClass:"row mb-56"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-row"},[(!_vm.projectId)?_c('div',{staticClass:"col-12 col-lg-3 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Regio")]),_vm._v(" "),_c('v-select',{staticClass:"form-select",attrs:{"options":_vm.cities,"placeholder":"Regio"},on:{"input":function (val) {
                _vm.data.city = val.key;
              }},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}})],1):_vm._e(),_vm._v(" "),(!_vm.projectId)?_c('div',{staticClass:"col-12 col-lg-3 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Type")]),_vm._v(" "),_c('v-select',{staticClass:"form-select",attrs:{"options":_vm.types,"placeholder":"Type"},on:{"input":function (val) {
                _vm.data.type = val.key;
              }},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1):_vm._e(),_vm._v(" "),(!_vm.projectId)?_c('div',{staticClass:"col-12 col-lg-3 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Te koop / Te huur")]),_vm._v(" "),_c('div',{staticClass:"toggl"},[_c('div',{staticClass:"off",class:{ active: _vm.data.saleOrLet === 'sale' },on:{"click":function($event){_vm.data.saleOrLet = 'sale'}}},[_vm._v("Te koop")]),_vm._v(" "),_c('div',{staticClass:"on",class:{ active: _vm.data.saleOrLet === 'let' },on:{"click":function($event){_vm.data.saleOrLet = 'let'}}},[_vm._v("Te huur")])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-3 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Status")]),_vm._v(" "),_c('v-select',{staticClass:"form-select",attrs:{"options":_vm.saleOrOption,"placeholder":"Status"},on:{"input":function (val) {
                _vm.data.verkochtOptie = val.key;
              }},model:{value:(_vm.selectedSaleOrOption),callback:function ($$v) {_vm.selectedSaleOrOption=$$v},expression:"selectedSaleOrOption"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-2 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Minimum prijs")]),_vm._v(" "),_c('v-select',{staticClass:"form-select",attrs:{"options":_vm.min,"placeholder":"Minimum prijs"},on:{"input":function (val) {
                _vm.data.minprice = val.key;
              }},model:{value:(_vm.selectedMin),callback:function ($$v) {_vm.selectedMin=$$v},expression:"selectedMin"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-2 mb-16"},[_c('label',{staticClass:"mb-8 small font-weight-bold"},[_vm._v("Maximum prijs")]),_vm._v(" "),_c('v-select',{staticClass:"form-select",attrs:{"options":_vm.max,"placeholder":"Maximum prijs"},on:{"input":function (val) {
                _vm.data.maxprice = val.key;
              }},model:{value:(_vm.selectedMax),callback:function ($$v) {_vm.selectedMax=$$v},expression:"selectedMax"}})],1)])])]),_vm._v(" "),_c('section',{staticClass:"row page pt-56",staticStyle:{"background-color":"#121212"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-grid"},[_c('div',{staticClass:"d-flex justify-content-between mb-40 align-items-center"},[_c('h5',{staticClass:"mb-0 d-none d-lg-block"},[_vm._v(_vm._s(_vm.totalEstates > 0 ? _vm.totalEstates : 'Geen')+" panden gevonden")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-self-end"},[_c('v-select',{staticClass:"form-select sort\n              ",attrs:{"options":_vm.orders,"placeholder":"sorteer"},on:{"input":function (val) {
                    _vm.data.order = val.key;
                  }},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_vm._v(" "),_c('div',[_c('div',{staticClass:"toggl ml-16"},[_c('div',{staticClass:"off px-24",class:{ active: _vm.mode === 'cards' },on:{"click":function($event){_vm.mode = 'cards'}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.svg('grid', 'icon'))}})]),_vm._v(" "),(_vm.projectId === null)?_c('div',{staticClass:"on px-24",class:{ active: _vm.mode === 'map' },on:{"click":function($event){_vm.mode = 'map'}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.svg('marker-hollow', 'icon'))}})]):_c('div',{staticClass:"on px-24",class:{ active: _vm.mode === 'list' },on:{"click":function($event){_vm.mode = 'list'}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.svg('list', 'icon'))}})])])])],1)]),_vm._v(" "),_c('gold-estate-spotlight',{attrs:{"params":_vm.data,"mode":_vm.mode,"cta-cards":_vm.ctaCards,"is-project":_vm.projectId !== null,"show-total":true,"show-sort":true,"per-page":_vm.ctaCards ? 8 : 9,"per-row":"3","can-load-more":"true"},on:{"total-updated":function (total) { return (_vm.totalEstates = total); }}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }