export default {
  props: {
    data: {
      default: null,
    },
    wrapperClass: {
      default: 'h-100',
    },
  },

  computed: {
    imageStyle() {
      if (this.data.pictures[0]) return `${this.data.pictures[0].urlXXL} 1x, /images/default-project-image.png 1x`;
      return `/images/default-project-image-gold.png 1x`;
    },
    computedRoute() {
        if (this.data.status.id === 3 || this.data.status.id === 4 || this.data.status.id === 17) return 'javascript:void(0)';
        if (this.data.status.id === 1)  return this.route(`${window.location.host.indexOf('gold') >= 0 ? 'gold.' : ''}resultaten.show`, { slug: this.data.id + '-' + this.data.city + '-' + this.data.name });
    },
    isViewDay() {
      if (this.data.open_homes_start_date) {
        let home_start = this.$moment(this.data.open_homes_start_date).format('dddd DD MMMM');
        return `Kijkdag ${home_start}`;
      }

      return false;
    },
  },
};
