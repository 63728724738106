<template>
  <div class="tag sold" v-if="[3, 17].includes(estate.purposeStatus.id)"><span>Verkocht</span></div>
  <div class="tag rented" v-else-if="[4].includes(estate.purposeStatus.id)"><span>Verhuurd</span></div>
  <div class="tag option" v-else-if="[12, 13, 21].includes(estate.purposeStatus.id)"><span>In optie</span></div>
  <div class="tag option" v-else-if="[5, 6, 16].includes(estate.purposeStatus.id)"><span>Bod aanvaard</span></div>
  <div v-else-if="estate.displayStatusId === 3">
    <div v-if="propertySpecialType === 'opbrengsteigendom'" class="tag-special dark">opbrengsteigendom</div>
    <div v-else-if="propertySpecialType === 'exclusief-vastgoed'" class="tag-special brand">exclusief vastgoed</div>
    <div v-else-if="propertySpecialType === 'handelspand'" class="tag-special gold">handelspand</div>
    <div v-else-if="propertySpecialType === 'bouwgrond'" class="tag-special blue">bouwgrond</div>
    <div v-else-if="propertySpecialType === 'renovatie-eigendom'" class="tag-special copper">renovatie-eigendom</div>
    <div v-else-if="propertySpecialType === 'gold'" class="tag-gold">
      <span><img src="/images/copandi-gold-no-text.png" alt="Copandi Gold logo" /></span>
    </div>
  </div>
  <div v-else-if="propertySpecialType === 'gold'">
    <div class="tag-gold">
      <span><img src="/images/copandi-gold-no-text.png" alt="Copandi Gold logo" /></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['estate'],

  computed: {
    propertySpecialType() {
      return this.getPropertySpecialType(this.estate);
    },
  },
  methods: {
    getPropertySpecialType(estate) {
      if (
        [
          4975913, 5608066, 5216356, 4972321, 5442352, 5454921, 5572777, 5313349, 5304076, 5561224, 5327820, 5584417, 5584224, 5329150, 5380814, 5359481, 5384759, 5568145, 5395717, 5553144, 5537359, 5501578, 5568431, 5568128, 5587690, 5512518, 5407686, 5548264, 5568998, 5599613, 5627762, 5702769,
          5640976, 5631584, 5706030, 5707034,
        ].includes(estate.id)
      ) {
        return 'gold';
      }

      if (estate.displayStatusId !== 3) {
        return false;
      }

      if (estate.investmentEstate) {
        return 'opbrengsteigendom';
      }
      if (estate.subCategory.id === 2) {
        return 'exclusief-vastgoed';
      }
      if (estate.subCategory.id === 42) {
        return 'handelspand';
      }
      if (estate.subCategory.id === 26) {
        return 'bouwgrond';
      }
      if (estate.state && (estate.state.id === 5 || estate.state.id === 6)) {
        return 'renovatie-eigendom';
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  position: absolute;
  color: #ffffff;
  background: #83c056;
  opacity: 0.8;
  padding: 12px 0px;
  top: 45%;
  width: 100%;
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}

.tag span {
  position: relative;
  opacity: 1;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 600; // Old bold
  text-transform: uppercase;
}

.tag-special,
.tag-gold {
  position: absolute;
  left: 24px;
  opacity: 1;
  height: 30px;
  padding: 4px 16px;
  bottom: 24px;
  text-align: center;
}
.tag-gold {
  color: #ffffff;
  background: #131313;
}

.tag-gold span {
  position: relative;
  opacity: 1;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 600; // Old bold
  text-transform: uppercase;
}
.tag-gold img {
  height: 100%;
}

.tag-special {
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600; // Old bold
  display: flex;
  align-items: center;
}
.tag-special.dark {
  color: #fff;
  background: #2d2c2c;
}
.tag-special.brand {
  color: #fff;
  background: #82c357;
}
.tag-special.blue {
  color: #fff;
  background: #4866af;
}
.tag-special.gold {
  color: #2d2c2c;
  font-weight: 600; // Old 900
  background: linear-gradient(90deg, #e2c27d 0%, #d5d0ad 25%, #af8643 50%, #e2c27d 100%);
}

.tag-special.copper {
  color: #2d2c2c;
  font-weight: 600; // Old 900
  background: #e8b429;
}
</style>
