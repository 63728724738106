var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid print-layout estate-detail" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass:
              "container d-flex flex-column justify-content-between align-items-center py-80",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row py-40" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 d-flex justify-content-center align-items-center",
                },
                [
                  _c("img", {
                    staticClass: "cover-image",
                    attrs: {
                      src: _vm.property.pictures[0].urlXXL,
                      alt: "cover-image",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row py-40" }, [
              _c(
                "div",
                { staticClass: "col-auto border-right-lg border-dark" },
                [
                  _c("h1", { staticClass: "h4" }, [
                    _vm._v(_vm._s(_vm.property.name)),
                  ]),
                ]
              ),
              _vm._v(" "),
              [1, 2].includes(_vm.property.purposeStatus.id) &&
              _vm.property.price
                ? _c("div", { staticClass: "col-auto text-center" }, [
                    _c("h4", {
                      staticClass: "price mb-8 text-primary",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPrice(_vm.property.price, "EUR")
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "row py-40 d-flex justify-content-center align-items-center flex-row",
              },
              [
                _vm.property.type
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-auto border-right border-dark d-flex justify-content-center align-items-center",
                      },
                      [
                        _c("p", { staticClass: "icon-with-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.__("plots." + _vm.property.type)) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.property.sizes_plot_area_size
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-auto border-right border-dark d-flex justify-content-center align-items-center",
                      },
                      [
                        _c("p", { staticClass: "icon-with-text" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-8",
                              attrs: {
                                fill: "#000000",
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 100 100",
                                width: "30",
                                height: "30",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "#000000",
                                  "stroke-linejoin": "round",
                                  "stroke-miterlimit": "10",
                                  "stroke-width": "2",
                                  d: "M14,76V52l20-30l28,14 l24-22v62",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  fill: "none",
                                  stroke: "#000000",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-miterlimit": "10",
                                  "stroke-width": "2",
                                  d: "M86,36v50H14V66l20-16l28,4L86,36z",
                                },
                              }),
                              _vm._v(" "),
                              _c("g", [
                                _c("line", {
                                  attrs: {
                                    x1: "81.5",
                                    x2: "82.5",
                                    y1: "50.228",
                                    y2: "49.511",
                                    fill: "none",
                                    stroke: "#000000",
                                    "stroke-linecap": "round",
                                    "stroke-miterlimit": "10",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  attrs: {
                                    x1: "76.5",
                                    x2: "78.5",
                                    y1: "53.815",
                                    y2: "52.38",
                                    fill: "none",
                                    stroke: "#000000",
                                    "stroke-linecap": "round",
                                    "stroke-miterlimit": "10",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  attrs: {
                                    x1: "67.5",
                                    x2: "73.5",
                                    y1: "60.272",
                                    y2: "55.967",
                                    fill: "none",
                                    stroke: "#000000",
                                    "stroke-linecap": "round",
                                    "stroke-miterlimit": "10",
                                  },
                                }),
                                _vm._v(" "),
                                _c("polyline", {
                                  attrs: {
                                    fill: "none",
                                    stroke: "#000000",
                                    "stroke-linecap": "round",
                                    "stroke-miterlimit": "10",
                                    points:
                                      "22.435,69 35,59.5 63,63.5 64.5,62.424",
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.property.sizes_plot_area_size) +
                              " m2\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.property.sizes_liveable_area_size
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-auto border-right border-dark d-flex justify-content-center align-items-center",
                      },
                      [
                        _c("p", { staticClass: "icon-with-text" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-8",
                              attrs: {
                                width: "19",
                                height: "22",
                                viewBox: "0 0 19 22",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z",
                                  stroke: "black",
                                  "stroke-width": "0.7",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M3.44043 20.7402V10.7402H9.44043V20.7402",
                                  stroke: "black",
                                  "stroke-width": "0.7",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M13 15C13 13.4379 13 11 13 11H16V15",
                                  stroke: "black",
                                  "stroke-width": "0.7",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                            ]
                          ),
                          _vm._v(
                            "\n\n            " +
                              _vm._s(_vm.property.sizes_liveable_area_size) +
                              " m2\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.property.bedrooms != _vm.NULL
                  ? _c(
                      "div",
                      { staticClass: "col-auto border-right border-dark" },
                      [
                        _c("p", { staticClass: "icon-with-text" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "mr-8",
                              staticStyle: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                "stroke-linejoin": "round",
                                "stroke-miterlimit": "1.41421",
                              },
                              attrs: {
                                width: "19",
                                height: "22",
                                viewBox: "0 0 30 20",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                "xml:space": "preserve",
                                "xmlns:serif": "http://www.serif.com/",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform: "matrix(1,0,0,1,-250,-584.999)",
                                  },
                                },
                                [
                                  _c("path", {
                                    staticStyle: {
                                      fill: "white",
                                      "fill-rule": "nonzero",
                                    },
                                    attrs: {
                                      id: "Forma-1",
                                      "serif:id": "Forma 1",
                                      d: "M276.177,592.364L276.177,592.334L276.177,587.795C276.177,585.751 274.689,584.999 273.293,584.999L255.614,584.999C254.218,584.999 252.729,585.72 252.729,587.795L252.729,592.364C251.334,592.515 250,593.417 250,595.491L250,599.039C250,599.64 250.496,600.121 251.116,600.121L251.489,600.121L251.489,602.916C251.489,603.518 251.985,603.999 252.605,603.999L253.66,603.999C254.28,603.999 254.776,603.518 254.776,602.916L254.776,600.09L274.223,600.09L274.223,602.916C274.223,603.518 274.72,603.999 275.34,603.999L276.395,603.999C277.015,603.999 277.511,603.518 277.511,602.916L277.511,600.09L277.883,600.09C278.504,600.09 279,599.61 279,599.008L279,595.491C278.907,593.417 277.573,592.515 276.177,592.364ZM253.784,587.795L253.846,587.795C253.846,586.382 254.807,586.081 255.614,586.081L273.293,586.081C274.099,586.081 275.061,586.382 275.061,587.795L275.061,592.334L274.223,592.334L274.223,589.749C274.223,589.148 273.727,588.667 273.107,588.667L266.283,588.667C265.663,588.667 265.167,589.148 265.167,589.749L265.167,592.334L263.864,592.334L263.864,589.749C263.864,589.148 263.368,588.667 262.747,588.667L255.924,588.667C255.304,588.667 254.807,589.148 254.807,589.749L254.807,592.334L253.784,592.334L253.784,587.795ZM273.107,589.749L273.107,592.334L266.283,592.334L266.283,589.749L273.107,589.749ZM262.809,589.749L262.809,592.334L255.986,592.334L255.986,589.749L262.809,589.749ZM253.629,602.916L252.574,602.916L252.574,600.09L253.629,600.09L253.629,602.916ZM276.332,602.916L275.278,602.916L275.278,600.09L276.332,600.09L276.332,602.916ZM277.79,599.039L276.332,599.039L275.278,599.039L253.629,599.039L252.574,599.039L251.085,599.039L251.085,597.445L277.79,597.445L277.79,599.039ZM277.79,596.363L251.085,596.363L251.085,595.491C251.085,593.687 252.45,593.417 253.225,593.417L275.65,593.417C276.457,593.417 277.79,593.687 277.79,595.491L277.79,596.363Z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.property.bedrooms) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-auto mt-2 mt-lg-0" }, [
                  _c("p", { staticClass: "icon-with-text text-primary" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.property.street) +
                        "\n            " +
                        _vm._s(_vm.property.number) +
                        ",\n            " +
                        _vm._s(_vm.property.postal_code) +
                        "\n            " +
                        _vm._s(_vm.property.city) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "container d-flex flex-column justify-content-between align-items-center py-12",
          },
          [
            _c("div", { staticClass: "row mb-40 mb-lg-80 estate-detail" }, [
              _vm._m(1),
              _vm._v(" "),
              _vm.property.description
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", {
                      staticClass: "rich-text",
                      domProps: { innerHTML: _vm._s(_vm.property.description) },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container py-24" }, [
          _vm._m(2),
          _vm._v(" "),
          _vm.property.status !== "sold" &&
          _vm.property.status !== "rented" &&
          _vm.property.price_amount &&
          _vm.property.price_hidden === "0"
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 col-lg-3 info-block mb-24 mb-lg-0" },
                  [
                    _c("p", { staticClass: "info-title" }, [
                      _vm._v("Vraagprijs"),
                    ]),
                    _vm._v(" "),
                    _c("h5", {
                      staticClass: "info-description",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatPrice(
                            _vm.property.price_amount,
                            _vm.property.price_currency
                          )
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.property.status === "sold"
                  ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                      _c("p", { staticClass: "info-title" }, [
                        _vm._v("Status"),
                      ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Verkocht"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.property.status === "rented"
                  ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                      _c("p", { staticClass: "info-title" }, [
                        _vm._v("Status"),
                      ]),
                      _vm._v(" "),
                      _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Verhuurd"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.property.legal_property_and_land_cadastral_income !== null
                  ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                      _c("p", { staticClass: "info-title" }, [
                        _vm._v("kadastraal inkomen"),
                      ]),
                      _vm._v(" "),
                      _c("h5", {
                        staticClass: "info-description",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.formatPrice(
                              _vm.property
                                .legal_property_and_land_cadastral_income,
                              _vm.property.price_currency
                            )
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm.property.general_condition !== null
              ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                  _c("p", { staticClass: "info-title" }, [_vm._v("Staat")]),
                  _vm._v(" "),
                  _vm.property.general_condition === "poor"
                    ? _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Renoveren"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.general_condition === "fair"
                    ? _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Matig"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.general_condition === "good"
                    ? _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Goed"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.general_condition === "mint"
                    ? _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Uitstekend"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.general_condition === "new"
                    ? _c("h5", { staticClass: "info-description" }, [
                        _vm._v("Nieuw"),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.property.building_construction_year
              ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                  _c("p", { staticClass: "info-title" }, [_vm._v("Bouwjaar")]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "info-description" }, [
                    _vm._v(_vm._s(_vm.property.building_construction_year)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.property.building_renovation_year
              ? _c("div", { staticClass: "col-6 col-lg-3 info-block" }, [
                  _c("p", { staticClass: "info-title" }, [
                    _vm._v("Renovatiejaar"),
                  ]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "info-description" }, [
                    _vm._v(_vm._s(_vm.property.building_renovation_year)),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-fluid py-24" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "container" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row mb-n16" },
                [
                  _vm._l(_vm.property.amenities, function (amenity) {
                    return _vm.property.amenities
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-6 col-lg-3 d-flex align-items-center mb-16",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-primary mr-8 font-weight-bold",
                              },
                              [_vm._v("+")]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "mb-0 specification-item" },
                              [
                                _vm._v(
                                  _vm._s(_vm.__("amenities." + amenity.title))
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.property.features_comfort_home_automation
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Domotica"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_water_softener
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Waterverzachter"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_fireplace
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Open haard"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_walk_in_closet
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Inloopkast"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_home_cinema
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Thuisbioscoop"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_wine_cellar
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Wijnkelder"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_sauna
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Sauna"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_fitness_room
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Fitness Kamer"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_comfort_furnished
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Gemeubileerd"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_ecology_solar_panels
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Zonnepanelen"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_ecology_solar_boiler
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Zonneboiler"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_ecology_rainwater_harvesting
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Regenwateropvang"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_ecology_insulated_roof
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Geïsoleerd dak"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_heating_cooling_central_heating
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Centrale verwarming"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_heating_cooling_floor_heating
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Vloerverwarming"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_heating_cooling_air_conditioning
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Airconditioning"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_security_alarm
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Beveiligings alarm"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_security_concierge
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Conciërge"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.property.features_security_video_surveillance
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 col-lg-3 d-flex align-items-center mb-16",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-primary mr-8 font-weight-bold",
                            },
                            [_vm._v("+")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0 specification-item" }, [
                            _vm._v("Camera bewaking"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container py-24" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row rooms" },
            [
              _vm.property.sizes_liveable_area_size
                ? _c("div", { staticClass: "col-12 col-lg-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "room d-flex flex-row justify-content-between",
                      },
                      [
                        _c("p", { staticClass: "mb-0 room-title" }, [
                          _vm._v("Bewoonbare oppervlakte"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "room-size mb-0" }, [
                          _vm._v(
                            _vm._s(
                              Math.round(
                                _vm.property.sizes_liveable_area_size,
                                2
                              )
                            ) +
                              " " +
                              _vm._s(
                                _vm.__(
                                  "plots." +
                                    _vm.property.sizes_liveable_area_unit
                                )
                              )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-12 col-lg-4",
                  attrs: { if: "property.sizes_plot_area_size" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "room d-flex flex-row justify-content-between",
                    },
                    [
                      _c("p", { staticClass: "mb-0 room-title" }, [
                        _vm._v("Perceeloppervlakte"),
                      ]),
                      _vm._v(" "),
                      _vm.property.sizes_plot_area_unit == "are"
                        ? _c("p", { staticClass: "room-size mb-0" }, [
                            _vm._v(
                              _vm._s(
                                Math.round(
                                  _vm.property.sizes_plot_area_size * 100,
                                  2
                                )
                              ) + " "
                            ),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.__("plots.sq_m")),
                              },
                            }),
                          ])
                        : _c("p", { staticClass: "room-size mb-0" }, [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.property.sizes_plot_area_size, 2)
                              ) + " {{ "
                            ),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.__(
                                    "plots." + _vm.property.sizes_plot_area_unit
                                  )
                                ),
                              },
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.property.property_rooms, function (room) {
                return _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "room d-flex flex-row justify-content-between",
                    },
                    [
                      room["type"] === "custom"
                        ? _c("p", { staticClass: "mb-0 room-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(room["size_description"]) +
                                "\n            "
                            ),
                          ])
                        : _c("p", { staticClass: "mb-0 room-title" }, [
                            _vm._v(_vm._s(_vm.__("rooms." + room["type"]))),
                          ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "room-size mb-0" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(room["size"]) +
                            "\n              " +
                            _vm._s(_vm.__("plots." + room["unit"])) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-fluid py-24 energy bg-white" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-8 mb-40" }, [
                  _c("h4", { staticClass: "detail-title mb-40" }, [
                    _vm._v("Energie"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-n24" }, [
                    _vm.property.legal_energy_epc_value != _vm.NULL
                      ? _c("div", { staticClass: "col-6 info-block mb-24" }, [
                          _c("p", { staticClass: "info-title" }, [
                            _vm._v("EPC waarde"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "info-description" }, [
                            _vm._v(
                              _vm._s(_vm.property.legal_energy_epc_value) +
                                " kWh/m"
                            ),
                            _c("sup", [_vm._v("2")]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.legal_energy_total_epc_value != _vm.NULL
                      ? _c("div", { staticClass: "col-6 info-block mb-24" }, [
                          _c("p", { staticClass: "info-title" }, [
                            _vm._v("Totaal EPC waarde"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "info-description" }, [
                            _vm._v(
                              _vm._s(
                                _vm.property.legal_energy_total_epc_value
                              ) + " kWh"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.legal_energy_e_level != _vm.NULL
                      ? _c("div", { staticClass: "col-6 info-block mb-24" }, [
                          _c("p", { staticClass: "info-title" }, [
                            _vm._v("E-level"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "info-description" }, [
                            _vm._v(_vm._s(_vm.property.legal_energy_e_level)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.legal_energy_epc_reference !== null
                      ? _c("div", { staticClass: "col-6 info-block mb-24" }, [
                          _c("p", { staticClass: "info-title" }, [
                            _vm._v("Referentienummer EPC-rapport"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "info-description" }, [
                            _vm._v(
                              _vm._s(_vm.property.legal_energy_epc_reference)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.legal_energy_epc_category !== null
                      ? _c("div", { staticClass: "col-6 info-block mb-24" }, [
                          _c("p", { staticClass: "info-title" }, [
                            _vm._v("EPC-niveau"),
                          ]),
                          _vm._v(" "),
                          _c("h5", { staticClass: "info-description" }, [
                            _vm._v(
                              _vm._s(_vm.property.legal_energy_epc_category)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-lg-4" }, [
                  _c("h4", { staticClass: "detail-title mb-40" }, [
                    _vm._v("Kenmerken"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row energy-specifications" }, [
                    _vm.property.features_energy_gas == true
                      ? _c("div", { staticClass: "col-12" }, [_vm._m(5)])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.features_energy_heat_pump == true
                      ? _c("div", { staticClass: "col-12" }, [_vm._m(6)])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.features_energy_electricity == true
                      ? _c("div", { staticClass: "col-12" }, [_vm._m(7)])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.property.features_ecology_double_glazing == true
                      ? _c("div", { staticClass: "col-12" }, [_vm._m(8)])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container py-24" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-7 mb-80 mb-lg-0" }, [
              _vm.property.price_costs !== null
                ? _c("div", { staticClass: "row mb-40" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h4", { staticClass: "detail-title mb-40" }, [
                        _vm._v("Kosten & Taxen"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.property.price_costs)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row legal-specifications" },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_building_permit
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.building_permit")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.building_permit")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_subdivision_authorisation
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.subdivision_authorisation"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.subdivision_authorisation"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_priority_purchase_right
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.priority_purchase_right")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.priority_purchase_right")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_urban_planning_breach
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.urban_planning_breach")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.urban_planning_breach")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_as_built_report
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.as_built_report")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.as_built_report")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_expropriation_plan
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.expropriation_plan")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.expropriation_plan")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_heritage_list
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.heritage_list")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(_vm.__("regulations.heritage_list")) +
                                  "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_pending_legal_proceedings
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.pending_legal_proceedings"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.pending_legal_proceedings"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_registered_building
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.registered_building")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.registered_building")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_site_untapped_activity
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.site_untapped_activity")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__("regulations.site_untapped_activity")
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_regulations_urban_planning_certificate
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.urban_planning_certificate"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.urban_planning_certificate"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _vm.property.legal_property_and_land_flood_risk
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.legal_property_and_land_flood_risk"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Ja"),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "legal d-flex flex-row justify-content-between",
                          },
                          [
                            _c("p", { staticClass: "mb-0 legal-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.__(
                                    "regulations.legal_property_and_land_flood_risk"
                                  )
                                ) + "?"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "legal-value mb-0" }, [
                              _vm._v("Neen"),
                            ]),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.legalMentions(), function (mention) {
                    return _vm.property.legal_legal_mentions != null
                      ? _c("div", { staticClass: "col-12" }, [
                          mention != ""
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "legal d-flex flex-row justify-content-between",
                                },
                                [
                                  _c("p", { staticClass: "mb-0 legal-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.legalMentionQuestion(mention)[0]
                                      ) + "?"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "legal-value mb-0" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.legalMentionQuestion(mention)[1]
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e()
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row py-40" }, [
      _c(
        "div",
        {
          staticClass:
            "col-12 d-flex justify-content-center align-items-center",
        },
        [
          _c("img", {
            attrs: { src: "/images/logo_white.svg", alt: "logo-cover-image" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", { staticClass: "info-title" }, [
        _vm._v("Details van deze woning"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "detail-title mb-24 mb-lg-40 text-start" }, [
          _vm._v("Prijs & Staat"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "detail-title mb-40" }, [_vm._v("Kenmerken")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "detail-title mb-40" }, [
          _vm._v("Oppervlaktes"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "specification d-flex flex-row justify-content-between" },
      [
        _c("p", { staticClass: "mb-0 specification-title" }, [
          _vm._v("Aardgas"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "specification-value mb-0" }, [_vm._v("Ja")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "specification d-flex flex-row justify-content-between" },
      [
        _c("p", { staticClass: "mb-0 specification-title" }, [
          _vm._v("Centrale verwarming"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "specification-value mb-0" }, [_vm._v("Ja")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "specification d-flex flex-row justify-content-between" },
      [
        _c("p", { staticClass: "mb-0 specification-title" }, [
          _vm._v("Elektriciteit"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "specification-value mb-0" }, [_vm._v("Ja")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "specification d-flex flex-row justify-content-between" },
      [
        _c("p", { staticClass: "mb-0 specification-title" }, [
          _vm._v("Dubbel Glas"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "specification-value mb-0" }, [_vm._v("Ja")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "detail-title mb-40" }, [
          _vm._v("Stedenbouwkundige & juridische vermeldingen"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }