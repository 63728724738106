import { render, staticRenderFns } from "./estate-special-label.vue?vue&type=template&id=636f692a&scoped=true&"
import script from "./estate-special-label.vue?vue&type=script&lang=js&"
export * from "./estate-special-label.vue?vue&type=script&lang=js&"
import style0 from "./estate-special-label.vue?vue&type=style&index=0&id=636f692a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636f692a",
  null
  
)

export default component.exports