var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.verifyAndSubmit)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", { staticClass: "mb-40" }, [
                        _vm._v("Uw gegevens"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Voornaam")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.first_name,
                                          expression: "data.first_name",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "first_name",
                                        type: "text",
                                        label: "Voornaam",
                                        placeholder: "Voornaam",
                                      },
                                      domProps: { value: _vm.data.first_name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "first_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Achternaam")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.last_name,
                                          expression: "data.last_name",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "last_name",
                                        type: "text",
                                        label: "Achternaam",
                                        placeholder: "Achternaam",
                                      },
                                      domProps: { value: _vm.data.last_name },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "last_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: {
                        rules: "required|email",
                        bails: false,
                        tag: "div",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Emailadres")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.email,
                                          expression: "data.email",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "email",
                                        type: "email",
                                        label: "Emailadres",
                                        placeholder: "Emailadres",
                                      },
                                      domProps: { value: _vm.data.email },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "email",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Telefoonnummer")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.phone,
                                          expression: "data.phone",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "phone",
                                        type: "text",
                                        label: "Telefoonnummer",
                                        placeholder: "Telefoonnummer",
                                      },
                                      domProps: { value: _vm.data.phone },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "phone",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12" }, [
                      _c("h5", { staticClass: "my-40" }, [
                        _vm._v("Info over "),
                        _c("span", { staticClass: "text-primary" }, [
                          _vm._v("jouw woning"),
                        ]),
                        _vm._v(" te koop"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Straatnaam + Huisnummer")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.address,
                                          expression: "data.address",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "address",
                                        type: "text",
                                        label: "Straatnaam + Huisnummer",
                                        placeholder: "Straatnaam + Huisnummer",
                                      },
                                      domProps: { value: _vm.data.address },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "address",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 col-xl-3 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Postcode")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.postal_code,
                                          expression: "data.postal_code",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "postal_code",
                                        type: "text",
                                        label: "Postcode",
                                        placeholder: "Postcode",
                                      },
                                      domProps: { value: _vm.data.postal_code },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "postal_code",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 col-xl-3 mb-gridh",
                      attrs: { rules: "required", bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("* Gemeente")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.city,
                                          expression: "data.city",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "city",
                                        type: "text",
                                        label: "Gemeente",
                                        placeholder: "Gemeente",
                                      },
                                      domProps: { value: _vm.data.city },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "city",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("Bouwjaar")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.build_year,
                                          expression: "data.build_year",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "build_year",
                                        type: "text",
                                        label: "Bouwjaar",
                                        placeholder: "Bouwjaar",
                                      },
                                      domProps: { value: _vm.data.build_year },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "build_year",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  { staticClass: "mb-16", attrs: { for: "" } },
                                  [_vm._v("Rennovatiejaar")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "input",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.renovation_year,
                                          expression: "data.renovation_year",
                                        },
                                      ],
                                      staticClass: "form-control mb-0",
                                      attrs: {
                                        name: "renovation_year",
                                        type: "text",
                                        label: "Rennovatiejaar",
                                        placeholder: "Rennovatiejaar",
                                      },
                                      domProps: {
                                        value: _vm.data.renovation_year,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data,
                                            "renovation_year",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    },
                                    "input",
                                    ariaInput,
                                    false
                                  )
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-16",
                                    attrs: { for: "totalBedrooms" },
                                  },
                                  [_vm._v("Aantal slaapkamers")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.bedrooms,
                                          expression: "data.bedrooms",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        name: "bedrooms",
                                        id: "totalBedrooms",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.data,
                                            "bedrooms",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    "select",
                                    ariaInput,
                                    false
                                  ),
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "1", selected: "" } },
                                      [_vm._v("1")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("2"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("3"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "4" } }, [
                                      _vm._v("4"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "5" } }, [
                                      _vm._v("5"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      staticClass: "col-12 col-lg-6 mb-gridh",
                      attrs: { bails: false, tag: "div" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              var ariaMsg = ref.ariaMsg
                              var ariaInput = ref.ariaInput
                              return [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mb-16",
                                    attrs: { for: "totalBathrooms" },
                                  },
                                  [_vm._v("Aantal badkamers")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.bathrooms,
                                          expression: "data.bathrooms",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        name: "bathrooms",
                                        id: "totalBathrooms",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.data,
                                            "bathrooms",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    "select",
                                    ariaInput,
                                    false
                                  ),
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "1", selected: "" } },
                                      [_vm._v("1")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("2"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("3"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "4" } }, [
                                      _vm._v("4"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "5" } }, [
                                      _vm._v("5"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  _vm._b(
                                    { staticClass: "text-danger" },
                                    "span",
                                    ariaMsg,
                                    false
                                  ),
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 col-lg-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.garden,
                              expression: "data.garden",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "garden" },
                          domProps: {
                            checked: Array.isArray(_vm.data.garden)
                              ? _vm._i(_vm.data.garden, null) > -1
                              : _vm.data.garden,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.data.garden,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.data,
                                      "garden",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.data,
                                      "garden",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.data, "garden", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "garden" },
                          },
                          [_vm._v("Tuin")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6 col-lg-3" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.garage,
                              expression: "data.garage",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "garage" },
                          domProps: {
                            checked: Array.isArray(_vm.data.garage)
                              ? _vm._i(_vm.data.garage, null) > -1
                              : _vm.data.garage,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.data.garage,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.data,
                                      "garage",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.data,
                                      "garage",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.data, "garage", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "garage" },
                          },
                          [_vm._v("Garage")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mt-80 d-flex align-items-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn d-flex align-items-center justify-content-center btn-primary",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v("\n          Verstuur\n          "),
                            _c("el-loader", {
                              staticClass: "ml-16",
                              attrs: { loading: _vm.loading },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.success && !_vm.loading
                          ? _c("div", { staticClass: "ml-40" }, [
                              _c("div", [_vm._v("Je verzoek werd verzonden!")]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.length > 0
                          ? _c(
                              "div",
                              { staticClass: "ml-40" },
                              _vm._l(_vm.errors, function (error, key) {
                                return _c(
                                  "div",
                                  {
                                    key: "error_" + key,
                                    staticClass: "text-danger",
                                  },
                                  [_vm._v(_vm._s(error))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }