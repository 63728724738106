var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                !_vm.success
                  ? _c(
                      "form",
                      {
                        staticClass: "form-inline w-100 position-relative",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.onSubmit)
                          },
                        },
                      },
                      [
                        _c("validation-provider", {
                          staticClass: "w-100",
                          attrs: { rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var ariaMsg = ref.ariaMsg
                                  var ariaInput = ref.ariaInput
                                  return [
                                    _c(
                                      "span",
                                      _vm._b(
                                        { staticClass: "text-danger" },
                                        "span",
                                        ariaMsg,
                                        false
                                      ),
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "input",
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.email,
                                              expression: "data.email",
                                            },
                                          ],
                                          staticClass: "form-control w-100",
                                          attrs: {
                                            name: "email",
                                            type: "email",
                                            placeholder: "Emailadres",
                                          },
                                          domProps: { value: _vm.data.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        },
                                        "input",
                                        ariaInput,
                                        false
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn d-flex align-items-center justify-content-center btn-primary",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v("\n        Inschrijven\n        "),
                            _c("el-loader", {
                              staticClass: "ml-16",
                              attrs: { loading: _vm.loading },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("p", { staticClass: "success-message" }, [
                      _vm._v(
                        "\n        Bedankt, we houden je spoedig op de hoogte  "
                      ),
                      _c("br"),
                      _vm._v(" van het laatste nieuws.\n    "),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }