<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.form-select {
  height: 60px;
  min-width: 220px;
  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 2px;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    padding: 15px 1rem;

    input {
      &::placeholder {
        color: #9a9a9a;
        font-weight: 400;
        opacity: 1;
      }
    }
  }
}
.estate-slider {
  @media (min-width: 992px) {
    height: 514px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.table {
  margin: 0 -16px;
  width: calc(100% + 32px);
  thead {
    th {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      border: none;
    }
  }
}
.table th,
.table td {
  border: none;
  padding: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table tr {
  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }
}
.table td {
  color: var(--gray);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  vertical-align: middle;
}
</style>
<template>
  <div>
    <div :class="[wrapperClass, { 'row mb-ngrid': multiLine }]" ref="slider">
      <template v-if="!serverError">
        <template v-if="estateInfo.data.length > 0">
          <!-- Mode -->

          <!-- Cards -->
          <template v-if="mode === 'cards'" v-for="(estate, key) in estateInfo.data">
            <div v-if="key % 8 === 4" :key="`cta_${key}`" :class="multiLineClass" :data-aos="animate ? 'fade-up' : undefined">
              <component :is="`${theme === 'gold' ? 'gold-estate-cta-card' : 'estate-cta-card'}`" :data="ctaCards[key % 3]" :background="`${key % 3 === 0 ? 'bg-gray-light' : 'bg-primary'}`"></component>
            </div>

            <div :data-aos="animate ? 'fade-up' : undefined" :class="multiLineClass">
              <component :is="`${theme === 'gold' ? 'gold-estate-card' : 'estate-card'}`" :data="estate"></component>
            </div>
          </template>

          <!-- Mode -->
          <!-- Cards -->
          <template v-if="mode === 'list'">
            <div class="col-12 table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th width="400" scope="col">Naam</th>
                    <th scope="col" class="text-right">Vraagprijs</th>
                    <th scope="col" class="text-right">Opp.</th>
                    <th scope="col" class="text-right">Slaapkamers</th>
                    <th scope="col" class="text-right">Verdieping</th>
                    <th scope="col" class="text-right">Blok</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="estate in estateInfo.data" :key="estate.id">
                    <td width="400" data-label="Naam">{{ estate.name }}</td>

                    <td data-label="Vraagprijs" class="text-right" v-if="(estate.price && estate.status.id === 1) || (estate.price && estate.status.id === 2)">{{ formatPrice(estate.price, 'EUR') }}</td>
                    <td data-label="Vraagprijs" class="text-right" v-else-if="estate.status.id === 'sold'">Verkocht</td>
                    <td data-label="Vraagprijs" class="text-right" v-else-if="estate.status.id === 'rented'">Verhuurd</td>

                    <td data-label="Opp." class="text-right">{{ estate.area ? formatRoomSize(estate.area, 'sq_m') : '' }}</td>
                    <td data-label="Slaapkamers" class="text-right">{{ estate.rooms }}</td>
                    <td data-label="Verdieping" class="text-right">{{ estate.floor }}</td>
                    <td data-label="Blok" class="text-right">{{ estate.box }}</td>
                    <td data-label="">
                      <a :href="route('resultaten.show', { slug: estate.id + '-' + estate.city + '-' + estate.name })" class="btn btn-primary float-right">Ik ben geïnteresseerd</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>

          <!-- Mode -->
          <!-- Cards -->
          <div v-if="mode === 'map'" class="col-12 col-lg-8">
            <google-map :markers="markers" :coordinates="false"></google-map>
          </div>
          <div v-if="mode === 'map'" class="col-12 col-lg-4">
            <div class="estate-slider">
              <estate-card v-for="estate in estateInfo.data" :key="estate.id" :data="estate" wrapperClass="mb-grid"></estate-card>
              <div v-if="canLoadMore && pageData.current !== pageData.last" class="d-flex align-items-center justify-content-center">
                <div v-if="canLoadMore && pageData.current !== pageData.last - 1 && mode === 'map'" class="btn btn-outline-primary" @click="loadMore">Toon meer panden</div>
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="!loading" class="row w-100">
          <div class="w-50 mx-auto text-center" style="background: whitesmoke; color: #333; padding: 15px; border-radius: 4px">Er konden geen panden gevonden worden</div>
        </div>

        <div v-if="loading" class="col-12">
          <div class="w-50 mx-auto text-center">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="col-12">
        <div class="w-50 mx-auto text-center" style="background: whitesmoke; color: #333; padding: 15px; border-radius: 4px">Er ging iets mis</div>
      </div>
    </div>
    <div v-if="!!canLoadMore && pageData.current !== pageData.last && (mode === 'cards' || mode === 'list') && estateInfo.data.length > 0" class="row mt-grid">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="btn btn-outline-primary" @click="loadMore">Toon meer panden</div>
      </div>
    </div>
  </div>
</template>

<script>
import estateSpotlight from '@/mixins/estate-spotlight';

export default {
  mixins: [estateSpotlight],
};
</script>
