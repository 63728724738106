import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    props: {
        theme: {
            default: 'light'
        },
        page: {
            default: 1,
        },
        perPage: {
            default: 3,
        },
        perRow: {
            default: 3,
        },
        canLoadMore: {
            default: false,
        },
        mobileSlider: {
            default: true,
        },
        wrapperClass: {
            default: 'mb-0',
        },
        mode: {
            default: 'cards',
        },
        ctaCards: {
            required: false,
            default: false,
        },
        noQueryParams: {
            required: false,
            default: false,
        },
        animate: {
            required: false,
            default: false,
        },
        isProject: {
            required: false,
            default: false,
        },
        autoscroll: {
            required: false,
            default: false,
        },
        params: {
            default: () => ({
                per_page: 9,
                page: 0,
                type: "",
                bedrooms: "",
                sort: "putOnlineDateTime,desc",
                cities: "",
                price: false,
            }),
        },
    },

    data() {
        return {
            localPerPage: parseInt(this.perPage),
            localPerRow: parseInt(this.perRow),
            multiLine: false,
            tns: null,
            serverError: false,
            pageLoaded: false,
            loading: false,
            estateInfo: {
                data: [],
                next_page_url: `/api/v2/estates${this.theme === 'gold' ? '-gold' : ''}`,
                total: 0,
                current_page: 1,
                last_page: 1,
            },
            pageData: {
                current: 0,
                next: 1,
                last: 1,
                total: 0
            }
        };
    },

    async mounted() {
        console.log('mounted');
        if (this.localPerPage > this.localPerRow) {
            this.multiLine = true;
        }

        if (!this.canLoadMore) {
            this.fetchEstates();
        }

        this.pageLoaded = true;
    },

    methods: {
        fetchEstates: _.debounce(function (page = this.params.page, per_page = this.localPerPage, callback) {
            console.log('fetchEstates debounce');
            this.serverError = false;
            this.loading = true;

            axios
                .get(this.estateInfo.next_page_url, {
                    params: {
                        ...this.params,
                        page: page,
                        per_page: per_page,
                    }
                })
                .then(estateInfo => {

                    this.estateInfo.data = [...this.estateInfo.data, ...estateInfo.data.data];

                    this.pageData.current = estateInfo.data.page;
                    this.pageData.next = this.pageData.current + 1;
                    this.pageData.total = parseInt(estateInfo.data.total, 10);
                    this.pageData.last = Math.round(this.pageData.total / this.localPerPage);
                    if (!this.noQueryParams) {
                        this.updateQueryParam('page', estateInfo.data.page)
                    }

                    if (callback) callback();

                    this.$emit('total-updated', estateInfo.data.total);

                    this.loading = false;
                    this.initSlider();

                    console.log('scroll to')
                })
                .catch(e => {
                    this.serverError = true;
                    this.loading = false;
                });
        }, 1000),

        fetchProjects: _.debounce(function () {
            console.log('fetchProjects debounce');
            this.serverError = false;
            this.loading = true;

            axios
                .get('/api/v2/projects/' + this.isProject)
                .then(estateInfo => {
                    this.estateInfo.data = estateInfo.data.data;
                    this.pageData.current = estateInfo.data.page;
                    this.pageData.next = this.pageData.current + 1;
                    this.pageData.total = parseInt(estateInfo.data.total, 10);
                    this.pageData.last = Math.round(this.pageData.total / this.localPerPage);

                    this.$emit('total-updated', estateInfo.data.total);
                    this.loading = false;
                    this.initSlider();

                })
                .catch(e => {
                    this.serverError = true;
                    this.loading = false;
                });
        }, 500),

        loadMore() {
            console.log('loadMore');
            this.params.page = this.pageData.next;
            this.fetchEstates();
        },

        initSlider() {
            if (this.multiLine) {
                return;
            }
            const self = this;
            let width = self.$refs.slider.clientWidth;
            this.$nextTick(() => {
                self.tns = tns({
                    container: self.$refs.slider,
                    gutter: 32,
                    controls: false,
                    touch: true,
                    nav: false,
                    loop: false,
                    rewind: true,
                    preventScrollOnTouch: 'auto',
                    items: 1,
                    fixedWidth: width * 0.9,
                    autoWidth: false,
                    responsive: {
                        960: {
                            fixedWidth: false,
                            autoWidth: true,
                            items: self.localPerRow,
                            arrowKeys: true,
                            mouseDrag: true,
                        },
                    },
                });
            });
        },

        updateQueryParam(key, value) {
            if ('URLSearchParams' in window) {
                var searchParams = new URLSearchParams(window.location.search)
                searchParams.set(key, value);
                var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                history.pushState(null, '', newRelativePathQuery);
            }
        }
    },

    computed: {
        multiLineClass() {
            if (!this.multiLine) {
                return '';
            }
            if (this.localPerRow === 2) {
                return 'col-12 col-lg-6 mb-grid';
            }
            if (this.localPerRow === 3) {
                return 'col-12 col-lg-6 col-xl-4 mb-grid';
            }
            if (this.localPerRow === 3) {
                return 'col-12 col-lg-6 col-xl-3 mb-grid';
            }
            return 'col-12';
        },
        markers() {
            return this.estateInfo.data.map(estate => {
                return {
                    lat: estate.location_geo_latitude, lng: estate.location_geo_longitude,
                    slug: estate.slug,
                    status: estate.status,
                    is_project: estate.is_project,
                    negotiation: estate.negotiation
                };
            });
        },
    },
    watch: {
        params: {
            handler(newValue, oldValue) {
                if (!this.canLoadMore) {
                    return;
                }

                this.loading = true;
                let callback = undefined;
                let per_page = newValue.per_page ?? this.perPage ?? 3;
                let page = newValue.page ?? this.page ?? 0;
                // Always start with clean array when page is 0
                if (Number(newValue.page) === 0 || !this.pageLoaded) {
                    this.estateInfo.data = [];
                }

                // if page is not loaded and page number is not 0, fetch all previous
                if (!this.pageLoaded) {
                    per_page = (Number(newValue.page) + 1) * Number(this.perPage)
                    callback = () => {
                        this.pageData.current = Number(newValue.page)
                        this.pageData.next = Number(newValue.page) + 1;
                    }

                    this.fetchEstates(0, per_page, callback);
                    return
                }

                this.fetchEstates();
            },
            deep: true,
            immediate: true
        },
    },
};
