<style lang="scss" scoped>
.slider-el {
  width: 100%;
  ::v-deep .range-slider-fill {
    background: #92be64;
  }
  ::v-deep .range-slider-rail {
    background: #f0f0f0;
  }
  ::v-deep .range-slider-knob {
    background-color: #92be64;
    border: 1px solid #92be64;
    box-shadow: none;
  }
}
.c-label {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-danger {
  color: #ff3b30 !important;
  letter-spacing: 0;
  line-height: 18px;
}
.text-lg {
  font-size: 32px;
  letter-spacing: 0;
  line-height: 39px;
}
.toggle {
  display: inline-flex;
  flex-flow: row;
  height: 54px;
  border: 1px solid #d9d9d9;
  border-radius: 38px;
  background-color: #ffffff;
  gap: 8px;
  padding: 4px;
  .toggle-btn {
    width: 144px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
    }
    &.active {
      background: #83c056;
      color: #fff;
      font-weight: 600; // Old bold
      &.gold{
        background: #bba876;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="mb-40">
      <label class="c-label" for="">
        <div>
          Waarde van
          <span class="text-primary">je eigendom</span>
          ?
        </div>
        <div>{{ `€${propertyValue}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</div>
      </label>
      <range-slider class="slider-el" min="0" max="1000000" step="500" v-model="propertyValue"> </range-slider>
    </div>

    <div class="c-label mb-40">
      <div>
        Bij een
        <span class="text-primary">traditionele makelaar</span>
        betaal je
      </div>
      <span class="numberamount text-danger">{{ `${otherPrice}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span>
    </div>

    <div class="form-group mb-40 d-lg-flex align-items-center justify-content-between w-100">
      <label class="c-label" for="worth">
        <div>Bij <span class="text-primary">Copandi</span> betaal je</div>
      </label>

      <div class="toggle">
        <div class="toggle-btn" :class="{ active: price === 5445 }" @click="price = 5445">Classic</div>
        <div class="toggle-btn" :class="{ active: price === 7260 }" @click="price = 7260">Copandi</div>
      </div>
    </div>

    <div class="c-label mb-40">
      <div>
        Bij
        <span class="text-primary">Copandi</span>
        bespaar je
      </div>
      <span class="numberamount text-lg " :class="{ 'text-primary': price !== 10000, 'text-gold': price === 10000 }">{{ `€${save}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</span>
    </div>
    <a class="btn btn-primary border-50" href="/maak-een-gratis-schatting"> Gratis schatting </a>
  </div>
</template>

<script>
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
  components: { RangeSlider },
  data() {
    return { price: 7260, propertyValue: 0, commision: 3.63 };
  },
  computed: {
    others() {
      const val = (parseInt(this.propertyValue) / 100) * this.commision;
      if (!val || val < 0) return 0;
      return val;
    },
    save() {
      let val = parseInt(this.others) - parseInt(this.price);
      if (!val || val < 0) return 0;
      return val;
    },
    otherPrice() {
      let val = parseInt(this.others);
      return `€${val}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>
