<style lang="scss" scoped>
.estate-card {
    cursor: default;
    text-decoration: none;
    display: block;
    border: 1px solid #E8E8E7;
    background-color: #131313;
    &:not(.nc) {
        cursor: pointer;
    }
    &.text-black{
        color: #131313;
    }
    &.text-gold{
        color: #BBA876;
    }
    &.border-gold{
        border: 1px solid #BBA876;
    }
}
</style>
<template>
    <a class="estate-card h-100 d-flex align-items-center justify-content-center d-flex flex-column py-4" :class="background == 'bg-primary' ? '' : 'border-gold'" >
        <svg v-if="background == 'bg-primary'" width="64" height="72" viewBox="0 0 19 22"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.44043 20.7402V10.7402H9.44043V20.7402" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 15C13 13.4379 13 11 13 11H16V15" stroke="white" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-if="background == 'bg-gray-light'" width="64" height="72" viewBox="0 0 19 22"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z" stroke="#BBA876" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.44043 20.7402V10.7402H9.44043V20.7402" stroke="#BBA876" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 15C13 13.4379 13 11 13 11H16V15" stroke="#BBA876" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h4 :class="background == 'bg-primary' ? 'text-white' : 'text-gold'" >{{ data.title }}</h4>
        <p :class="background == 'bg-primary' ? 'text-white' : 'text-gold'" >{{ data.description }}</p>
        <a href="/contact" class="btn"  :class="background == 'bg-primary' ? 'btn-gold' : 'btn-white'" >
            {{ data.cta_button }}
        </a>
    </a>
</template>
<script>
export default {
    props: {
        data: {
            default: null,
        },
        background: {
            default: 'bg-primary',
        },
    },

    computed: {
    },
};
</script>
