var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.showModal
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrapper",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.$root.showModal = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "text-secondary font-weight-bold d-flex w-100 justify-content-between align-items-center",
                          },
                          [
                            _vm._v(
                              "\n                Download plan\n                "
                            ),
                            _c("i", {
                              staticClass: "fas fa-times fa-xs",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.$root.showModal = false
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-27" }, [
                          _vm._v(
                            "Download de belangrijkste plannen in verband met dit pand."
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    !_vm.success
                      ? _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.download()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.name,
                                        expression: "data.name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      required: "",
                                      type: "text",
                                      placeholder: "Naam",
                                    },
                                    domProps: { value: _vm.data.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.phone,
                                        expression: "data.phone",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      required: "",
                                      type: "text",
                                      placeholder: "Telefoonnummer",
                                    },
                                    domProps: { value: _vm.data.phone },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "phone",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 col-md-4" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.data.email,
                                        expression: "data.email",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      required: "",
                                      type: "email",
                                      placeholder: "E-mail adres",
                                    },
                                    domProps: { value: _vm.data.email },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.data,
                                          "email",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              !_vm.success && !_vm.loading && _vm.error !== ""
                                ? _c(
                                    "div",
                                    { staticClass: "error-toast mt-3" },
                                    [_vm._v(_vm._s(_vm.error))]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-3 row justify-content-center align-items-center",
                              },
                              [
                                _c("div", { staticClass: "col-auto" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-green mb-2",
                                      attrs: { type: "submit" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Download plan\n                  "
                                      ),
                                      _vm.loading
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-circle-notch fa-spin",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "form-row" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-grey mx-0 mx-lg-9 small mb-18",
                              attrs: {
                                href: _vm.$root.plan,
                                download: "",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$root.planName) + " "),
                              _c("i", { staticClass: "fas fa-download ml-9" }),
                            ]
                          ),
                        ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }