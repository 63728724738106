<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.form-select {
  width: 100%;
  font-family: 'Lato', sans-serif;
  ::v-deep .vs__dropdown-toggle {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 100%;
    padding: 0 16px;
    margin: 0;
    caret-color: #83c056;
    height: 54px;
    input {
      color: #000000;
      font-family: inherit;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0;
      padding-left: 0;
      &::placeholder {
        font-weight: 500;
        color: #757575;
      }
    }
    .vs__clear {
      display: none;
    }
  }
  ::v-deep .vs--single.vs--open .vs__selected {
    position: relative !important;
  }
  ::v-deep .vs__dropdown-menu {
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    border: none;
    //top: 36px;
    .vs__dropdown-option--highlight {
      background: #fff;
      color: #83c056;
    }
    .vs__dropdown-option {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.8px;
      line-height: 15px;
      padding: 8px 12px;
    }
  }
}

.toggle {
  display: inline-flex;
  flex-flow: row;
  height: 54px;
  border: 1px solid #d9d9d9;
  border-radius: 38px;
  background-color: #ffffff;
  gap: 8px;
  padding: 4px;
  .toggle-btn {
    padding: 0px 45px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    transform: background 0.15s ease-in-out;
    cursor: pointer;
    &.sm {
      width: 50px;
      padding: 0px 15px;
    }
    &:hover {
      background-color: #f7f7f7;
    }
    &.active {
      background: #83c056;
      color: #fff;
      font-weight: 600; // Old bold
      ::v-deep {
        path {
          stroke: #fff;
        }
      }
    }
    &.light {
      &.active {
        background: #83c056;
        color: #fff;
      }
    }
    &.gold {
      &.active {
        background: #bba876;
        color: #fff;
      }
    }
  }
}

.page {
  background: #fafafa;
  min-height: 60vh;
  &.light {
    background: #fafafa;
  }
  &.gold {
    background: rgb(18, 18, 18);
  }
}

.form-select.sort {
  min-width: 215px;
  ::v-deep .vs__dropdown-toggle {
    background: transparent;
    border: none;
  }
}
.form-select.status {
  min-width: 170px;
  ::v-deep .vs__dropdown-toggle {
    background: transparent;
    border: none;
  }
}
.w-price {
  max-width: 160px;
}
.w-type {
  max-width: 181px;
}
.w-region {
  max-width: 210px;
}
.w-city {
  max-width: 270px;
}
.mobile-filters-active {
  max-height: 100vh !important;
}

.mobile-filters {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.form-control {
  padding: 0.875rem 1rem;
  height: calc(1.5em + 1.75rem + 2px);
  border: 1px solid #d9d9d9;
}

.multi-select ::v-deep .vs__selected-options {
  align-items: center;
}

.multi-select ::v-deep .vs__selected {
  background-color: #83c056;
  color: #fff;
  border-radius: 50px;
  padding: 0 0.4em;
  border: none;
}

.multi-select ::v-deep .vs__dropdown-toggle {
  overflow: auto;
}

.multi-select ::v-deep .vs__deselect {
  fill: #fff;
}
</style>

<template>
  <div class="col-12">
    <section class="row mb-lg-40">
      <div v-if="showFilters" class="container d-none d-md-block">
        <div class="form-row">
          <div v-if="!projectId" class="col-12 col-lg mb-16">
            <label class="mb-8 small font-weight-bold">Straat</label>
            <input type="text" v-model="data.address" label="Straat" name="straat" class="form-control" placeholder="Straat" />
          </div>
          <div v-if="!projectId" class="col-12 col-lg mb-16 w-city">
            <label class="mb-8 small font-weight-bold">Gemeente</label>
            <v-select v-model="selectedCities" @input="(val) => handleChangeFilter('city', val.map((v) => v.key).join())" class="form-select multi-select" :options="cities" :multiple="true" autocomplete="on" placeholder="Gemeente"></v-select>
          </div>
          <div v-if="!projectId" class="col-12 col-lg mb-16 w-region">
            <label class="mb-8 small font-weight-bold">Regio</label>
            <v-select v-model="selectedRegion" @input="(val) => handleChangeFilter('region', val.key)" class="form-select" :options="regions" placeholder="Regio"></v-select>
          </div>

          <div v-if="!projectId" class="col-12 col-lg mb-16 w-type">
            <label class="mb-8 small font-weight-bold">Type</label>
            <v-select v-model="selectedType" @input="(val) => handleChangeFilter('type', val.key)" class="form-select" :options="types" placeholder="Type"></v-select>
          </div>

          <div class="col-12 col-lg mb-16 w-price">
            <label class="mb-8 small font-weight-bold">Min prijs</label>
            <v-select v-model="selectedMin" class="form-select" @input="(val) => handleChangeFilter('minprice', val.key)" :options="min" placeholder="Min prijs"></v-select>
          </div>

          <div class="col-12 col-lg mb-16 w-price">
            <label class="mb-8 small font-weight-bold">Max prijs</label>
            <v-select v-model="selectedMax" class="form-select" @input="(val) => handleChangeFilter('maxprice', val.key)" :options="max" placeholder="Max prijs"></v-select>
          </div>
        </div>
      </div>
      <div v-if="showFilters" class="container d-block d-md-none">
        <div class="form-row">
          <div v-if="!projectId" class="col-12 col-lg mb-16">
            <label class="mb-8 small font-weight-bold">Straat</label>
            <input type="text" v-model="data.address" label="Straat" name="straat" class="form-control" placeholder="Straat" />
          </div>
          <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
            <label class="mb-8 small font-weight-bold">Stad</label>
            <v-select v-model="selectedCities" @input="(val) => handleChangeFilter('city', val.map((v) => v.key).join())" autocomplete="on" :multiple="true" class="form-select multi-select" :options="cities" placeholder="Stad"></v-select>
          </div>
          <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
            <label class="mb-8 small font-weight-bold">Regio</label>
            <v-select v-model="selectedRegion" @input="(val) => handleChangeFilter('region', val.key)" class="form-select" :options="regions" placeholder="Regio"></v-select>
          </div>
          <div class="col-12 d-flex align-items-center justify-content-center mb-16">
            <div type="button" class="link" @click="openDrawer">{{ showMobileFilters ? 'Minder' : 'Meer' }} filters</div>
          </div>
          <div class="mobile-filters col-12">
            <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
              <label class="mb-8 small font-weight-bold">Type</label>
              <v-select v-model="selectedType" @input="(val) => handleChangeFilter('type', val.key)" class="form-select" :options="types" placeholder="Type"></v-select>
            </div>
            <div class="col-12 col-lg-3 mb-16">
              <label class="mb-8 small font-weight-bold">Status</label>
              <v-select v-model="selectedSaleOrOption" class="form-select" :options="saleOrOption" placeholder="Status" @input="(val) => handleChangeFilter('verkochtOptie', val.key)"></v-select>
            </div>
            <div class="col-12 col-lg-2 mb-16">
              <label class="mb-8 small font-weight-bold">Minimum prijs</label>
              <v-select v-model="selectedMin" class="form-select" :options="min" placeholder="Minimum prijs" @input="(val) => handleChangeFilter('minprice', val.key)"></v-select>
            </div>
            <div class="col-12 col-lg-2 mb-16">
              <label class="mb-8 small font-weight-bold">Maximum prijs</label>
              <v-select v-model="selectedMax" class="form-select" :options="max" placeholder="Maximum prijs" @input="(val) => handleChangeFilter('maxprice', val.key)"></v-select>
            </div>
            <div class="col-12 col-lg-2 mb-16">
              <label class="mb-8 small font-weight-bold">Sorteer op</label>
              <v-select v-model="order" class="form-select sort" @input="(val) => handleChangeFilter('order', val.key)" :options="orders" placeholder="sorteer"></v-select>
            </div>
            <div class="col-12 col-lg-2 mb-16">
              <label class="mb-8 small font-weight-bold">Sorteer op</label>
              <v-select v-model="selectedSaleOrOption" @input="(val) => handleChangeFilter('verkochtOptie', val.key)" class="form-select" :options="saleOrOption" placeholder="Status"></v-select>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="row page pt-56" :class="theme">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-grid">
            <div class="d-flex flex-wrap justify-content-lg-between mb-40 align-items-center">
              <div class="d-flex flex-wrap align-items-center justify-content-center w-100 w-lg-auto justify-content-lg-start">
                <div class="toggle mr-lg-32" v-if="!projectId">
                  <div class="toggle-btn" @click="handleChangeFilter('saleOrLet', 'sale')" :class="[{ active: data.saleOrLet === 'sale' }, theme]">Te koop</div>
                  <div class="toggle-btn" @click="handleChangeFilter('saleOrLet', 'let')" :class="[{ active: data.saleOrLet === 'let' }, theme]">Te huur</div>
                </div>
                <h6 class="mb-0 d-none d-lg-block">{{ totalEstates > 0 ? totalEstates : 'Geen' }} panden gevonden</h6>
              </div>

              <div class="d-none d-lg-flex flex-row align-self-end">
                <v-select :searchable="false" v-model="order" class="form-select sort" @input="(val) => handleChangeFilter('order', val.key)" :options="orders" placeholder="sorteer"></v-select>
                <v-select :searchable="false" v-model="selectedSaleOrOption" @input="(val) => handleChangeFilter('verkochtOptie', val.key)" class="form-select ml-2 status" :options="saleOrOption" placeholder="Status"></v-select>
              </div>
            </div>
            <estate-spotlight
              v-if="loaded"
              :theme="theme"
              ref="estateSpotlight"
              :params="data"
              :mode="mode"
              :cta-cards="ctaCards"
              :is-project="projectId !== null ? this.projectId : false"
              :extra-project-ids="extraProjectIds"
              :show-total="true"
              :show-sort="true"
              :per-page="ctaCards ? 8 : 9"
              per-row="3"
              can-load-more="true"
              @total-updated="handleTotalUpdated"
            ></estate-spotlight>
            <div v-else="loading" class="row w-100">
              <div class="w-50 mx-auto text-center">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    theme: {
      default: 'light',
    },
    projectId: {
      required: false,
      default: null,
    },
    extraProjectIds: {
      required: false,
      default: () => [],
    },
    showFilters: {
      required: false,
      default: true,
    },
    hideUnits: {
      required: false,
      default: false,
    },
    ctaCards: {
      required: false,
      default: false,
    },
    boxes: {
      required: false,
      default: () => [],
    },
    floors: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      totalEstates: 0,
      mode: 'cards', // list, map

      autoscroll: false,

      selectedCities: [],
      selectedRegion: null,
      selectedType: null,
      selectedMin: null,
      selectedMax: null,
      selectedSaleOrOption: { key: true, label: 'Alle' },
      loaded: false,

      data: {
        city: null,
        Address: '',
        region: null,
        type: null,
        verkochtOptie: true,
        minprice: null,
        maxprice: null,
        saleOrLet: 'sale',
        order: 'putOnlineDateTime,desc',
        hideUnits: this.hideUnits,
        projectId: this.projectId,
        extraProjectIds: this.extraProjectIds,
        page: 0,
      },
      cities: [],
      regions: [],
      types: [
        { key: 'apartment', label: 'Appartement' },
        { key: 'house', label: 'Huis' },
        { key: 'land', label: 'Bouwgrond' },
        { key: 'commercial', label: 'Commercieel' },
        { key: 'parking', label: 'Garage' },
      ],
      saleOrOption: [
        { key: false, label: 'Enkel te koop' },
        { key: true, label: 'Alle panden' },
      ],
      order: { key: 'putOnlineDateTime,desc', label: 'Nieuwste eerst' },
      orders: [
        { key: 'putOnlineDateTime, desc', label: 'Nieuwste eerst' },
        { key: 'price,asc', label: 'Goedkoopste eerst' },
      ],
      success: false,
      loading: false,
      queryParams: '?',
      showMobileFilters: false,
    };
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const cities = urlParams.get('cities');
    const region = urlParams.get('region');
    const negotiation = urlParams.get('negotiation');
    const type = urlParams.get('type');
    const min = urlParams.get('min');
    const max = urlParams.get('max');
    const page = urlParams.get('page');

    await this.fetchCities();
    await this.fetchRegions();

    if (cities && cities != '') {
      this.data.city = cities;
      const isSubset = (array1, array2) => array2.every((element) => array1.includes(element));
      this.selectedCities = this.cities.filter((city) => isSubset(cities.split(','), city.key.split(',')));
    }

    if (region && region != '') {
      this.data.region = region;
      this.selectedRegion = this.regions.find((r) => r.key === Number(region));
    }

    if (negotiation && negotiation != '') {
      this.data.saleOrLet = negotiation;
    }

    if (min && min != '') {
      this.data.minPrice = min;
      this.selectedMin = this.min.filter((price) => price.key === min);
    }

    if (max && max != '') {
      this.data.maxPrice = max;
      this.selectedMax = this.max.filter((price) => price.key === max);
    }

    if (type && type != '') {
      if (type === 'apartment') {
        this.data.type = type;
        this.selectedType = { key: 'apartment', label: 'Appartement' };
      }
      if (type === 'house') {
        this.data.type = type;
        this.selectedType = { key: 'house', label: 'Huis' };
      }
      if (type === 'land') {
        this.data.type = type;
        this.selectedType = { key: 'land', label: 'Bouwgrond' };
      }
      if (type === 'commercial') {
        this.data.type = type;
        this.selectedType = { key: 'commercial', label: 'Commercieel' };
      }
      if (type === 'parking') {
        this.data.type = type;
        this.selectedType = { key: 'parking', label: 'Garage' };
      }
    }

    if (page && page != '') {
      this.data.page = page;
    }

    this.loaded = true;
  },

  methods: {
    handleChangeFilter(key, value) {
      this.data[key] = value;
      this.data.page = 0;
      this.updateQueryParams();
    },
    updateQueryParams() {
      this.queryParams = '?';
      if (this.data.city) {
        this.queryParams += 'cities=' + this.data.city + '&';
      }
      if (this.data.saleOrLet) {
        this.queryParams += 'negotiation=' + this.data.saleOrLet + '&';
      }
      if (this.data.type) {
        this.queryParams += 'type=' + this.data.type + '&';
      }
      if (this.data.minprice) {
        this.queryParams += 'min=' + this.data.minprice + '&';
      }
      if (this.data.maxprice) {
        this.queryParams += 'max=' + this.data.maxprice + '&';
      }

      this.queryParams += 'page=' + this.$refs.estateSpotlight.pageData.current;

      this.setUrlParams();
    },
    setUrlParams() {
      if (history.pushState) {
        let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + this.queryParams;
        window.history.pushState({ path: newurl }, '', newurl);
      }
    },
    fetchCities() {
      return axios.get('/api/v2/cities').then((response) => {
        const cities = {};
        response.data.forEach((city) => {
          if (cities[city.name]) {
            cities[city.name].key = cities[city.name].key + `,${city.zip}`;
          } else {
            cities[city.name] = { label: city.name, key: city.zip };
          }
        });

        this.cities = Object.values(cities);
      });
    },
    fetchRegions() {
      return axios.get('/api/v2/regions').then((response) => {
        const regions = {};
        response.data.forEach((region) => {
          regions[region.name] = { label: region.name, key: region.id };
        });

        this.regions = Object.values(regions);
      });
    },
    openDrawer() {
      let coll = document.getElementsByClassName('mobile-filters');

      coll[0].classList.toggle('mobile-filters-active');

      this.showMobileFilters === false ? (this.showMobileFilters = true) : (this.showMobileFilters = false);
    },
    handleTotalUpdated(total) {
      this.totalEstates = total;
    },
  },

  computed: {
    min() {
      if (this.data.saleOrLet === 'let') {
        return [
          { key: '0', label: '€0' },
          { key: '300', label: '€300' },
          { key: '500', label: '€500' },
          { key: '750', label: '€750' },
          { key: '1000', label: '€1000' },
          { key: '1250', label: '€1250' },
          { key: '1500', label: '€1500' },
          { key: '2000', label: '€2000' },
        ];
      }

      return [
        { key: '0', label: '€0' },
        { key: '50000', label: '€50.000' },
        { key: '75000', label: '€75.000' },
        { key: '100000', label: '€100.000' },
        { key: '125000', label: '€125.000' },
        { key: '150000', label: '€150.000' },
        { key: '175000', label: '€175.000' },
        { key: '200000', label: '€200.000' },
        { key: '300000', label: '€300.000' },
        { key: '400000', label: '€400.000' },
        { key: '500000', label: '€500.000' },
        { key: '600000', label: '€600.000' },
      ];
    },
    max() {
      if (this.data.saleOrLet === 'let') {
        return [
          { key: '0', label: '€0' },
          { key: '300', label: '€300' },
          { key: '500', label: '€500' },
          { key: '750', label: '€750' },
          { key: '1000', label: '€1000' },
          { key: '1250', label: '€1250' },
          { key: '1500', label: '€1500' },
          { key: '2000', label: '€2000' },
        ];
      }

      return [
        { key: '75000', label: '€75.000' },
        { key: '100000', label: '€100.000' },
        { key: '125000', label: '€125.000' },
        { key: '150000', label: '€150.000' },
        { key: '175000', label: '€175.000' },
        { key: '200000', label: '€200.000' },
        { key: '300000', label: '€300.000' },
        { key: '400000', label: '€400.000' },
        { key: '500000', label: '€500.000' },
        { key: '600000', label: '€600.000' },
        { key: '9000000', label: '> €600.000' },
      ];
    },
  },
};
</script>
