var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gold-estate-search" }, [
    _c("span", {
      staticClass: "mr-16",
      domProps: { innerHTML: _vm._s(_vm.svg("search")) },
      on: { click: _vm.submitSearch },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchTerm,
          expression: "searchTerm",
        },
      ],
      attrs: { type: "text", placeholder: "Zoek pand" },
      domProps: { value: _vm.searchTerm },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.submitSearch($event)
        },
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.searchTerm = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }