var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return [3, 17].includes(_vm.estate.purposeStatus.id)
    ? _c("div", { staticClass: "tag sold" }, [_c("span", [_vm._v("Verkocht")])])
    : [4].includes(_vm.estate.purposeStatus.id)
    ? _c("div", { staticClass: "tag rented" }, [
        _c("span", [_vm._v("Verhuurd")]),
      ])
    : [12, 13, 21].includes(_vm.estate.purposeStatus.id)
    ? _c("div", { staticClass: "tag option" }, [
        _c("span", [_vm._v("In optie")]),
      ])
    : [5, 6, 16].includes(_vm.estate.purposeStatus.id)
    ? _c("div", { staticClass: "tag option" }, [
        _c("span", [_vm._v("Bod aanvaard")]),
      ])
    : _vm.estate.displayStatusId === 3
    ? _c("div", [
        _vm.propertySpecialType === "opbrengsteigendom"
          ? _c("div", { staticClass: "tag-special dark" }, [
              _vm._v("opbrengsteigendom"),
            ])
          : _vm.propertySpecialType === "exclusief-vastgoed"
          ? _c("div", { staticClass: "tag-special brand" }, [
              _vm._v("exclusief vastgoed"),
            ])
          : _vm.propertySpecialType === "handelspand"
          ? _c("div", { staticClass: "tag-special gold" }, [
              _vm._v("handelspand"),
            ])
          : _vm.propertySpecialType === "bouwgrond"
          ? _c("div", { staticClass: "tag-special blue" }, [
              _vm._v("bouwgrond"),
            ])
          : _vm.propertySpecialType === "renovatie-eigendom"
          ? _c("div", { staticClass: "tag-special copper" }, [
              _vm._v("renovatie-eigendom"),
            ])
          : _vm.propertySpecialType === "gold"
          ? _c("div", { staticClass: "tag-gold" }, [_vm._m(0)])
          : _vm._e(),
      ])
    : _vm.propertySpecialType === "gold"
    ? _c("div", [_vm._m(1)])
    : _vm.isNew
    ? _c("div", { staticClass: "tag option" }, [_c("span", [_vm._v("Nieuw")])])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        attrs: {
          src: "/images/copandi-gold-no-text.png",
          alt: "Copandi Gold logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tag-gold" }, [
      _c("span", [
        _c("img", {
          attrs: {
            src: "/images/copandi-gold-no-text.png",
            alt: "Copandi Gold logo",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }