<style lang="scss" scoped>
.project {
  .large-img {
    width: 100%;
    object-fit: cover;
    height: 250px;
    background: whitesmoke;
    border-radius: 4px;
    @media (min-width: 992px) {
      height: 480px;
    }
  }

  .tag {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    opacity: 0.8;
    color: #ffffff;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 18px;
    padding: 24px 0;
  }

  ::v-deep .icon {
    height: 20px;
    width: 20px;
  }
}
</style>

<template>
  <div class="row project">
    <div class="col-12 col-lg-5">
      <div class="position-relative">
        <img v-if="project.pictures.length > 0" class="w-100 mb-24 mb-lg-80 large-img" :src="project.pictures[0].urlXXL" alt="" />
        <div v-else class="w-100 mb-24 mb-lg-80 large-img"></div>
        <div class="tag bg-primary" v-if="project.purposeStatus.id === 3">UITVERKOCHT</div>
        <div class="tag bg-secondary" v-if="project.purposeStatus.id === 5">Bod aanvaard</div>
      </div>
    </div>
    <div class="col-12 offset-lg-1 col-lg-6">
      <div class="">
        <p class="text-primary text-uppercase mb-8 font-weight-bold" v-if="project.purposeStatus.id === 1">Beschikbaar</p>
        <p class="text-primary text-uppercase mb-8 font-weight-bold" v-if="project.purposeStatus.id === 12">In optie</p>
        <p class="text-primary text-uppercase mb-8 font-weight-bold" v-if="project.purposeStatus.id === 3">Verkocht</p>

        <h3 class="mb-24 d-none d-lg-block" v-html="project.name"></h3>
        <p class="mb-56 d-flex flex-row">
          <span v-html="svg('marker', 'icon icon-primary mr-8')"></span>
          {{ project.address }} {{ project.number }}, {{ project.zip }} {{ project.city }}
        </p>
      </div>
      <div class="mb-24 mb-lg-80">
        <h3 class="h4 mb-24 d-lg-none" v-html="project.name"></h3>
        <h5 v-if="project.displayPrice && project.price" class="font-weight-bold mb-24">Startprijs: {{ formatPrice(project.price, 'EUR') }}</h5>
      </div>
      <div class="">
        <a class="btn btn-primary mb-80" :href="route('nieuwbouw.show', { slug: project.id + '-' + project.city + '-' + project.name })"> Bekijk het project </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      required: true,
    },
  },
};
</script>
