<style scoped lang="scss">
.video-wrapper {
  height: 600px;
}
</style>

<template>
  <iframe class="video-wrapper" v-if="videoType === 'youtube-embed'" :src="compiledVideoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <video class="video-wrapper" v-else-if="videoType === 'default'" controls>
    <source :src="compiledVideoUrl" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>

<script>
export default {
  props: {
    video: {
      default: '',
      required: false,
    },
  },

  data: vm => ({
    videoType: null,
    compiledVideoUrl: vm.video,
  }),

  mounted() {
    this.determineVideoType();
  },
  methods: {
    determineVideoType() {
      if (this.video.includes('youtu')) {
        this.videoType = 'youtube-embed';
        const videoId = this.getYoutubeVideoId();
        this.compiledVideoUrl = `https://www.youtube.com/embed/${videoId}`;
        return;
      }

      this.videoType = 'default';
      return;
    },

    getYoutubeVideoId() {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = this.video.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
  },
};
</script>
