var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member-card" }, [
    _c(
      "a",
      {
        staticClass: "member-image-holder",
        attrs: { href: _vm.url },
        on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
      },
      [
        _vm.member.video
          ? _c(
              "video",
              {
                ref: "video",
                staticClass: "lazyload w-100 member-video",
                attrs: {
                  title: _vm.member.name,
                  loop: "",
                  muted: "",
                  "webkit-playsinline": "true",
                  playsinline: "true",
                },
                domProps: { muted: true },
              },
              [
                _c("source", {
                  attrs: { src: _vm.member.video, type: "video/mp4" },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "member-image",
          class: { "can-hover": _vm.member.video },
          style: {
            backgroundImage: "url(" + _vm.member.image.url + ")",
            paddingTop: "66%",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "member-text" }, [
      _c(
        "a",
        {
          staticClass: "member-tag",
          attrs: { href: "tel:" + _vm.member.telephone },
        },
        [_vm._v(_vm._s(_vm.member.telephone))]
      ),
      _vm._v(" "),
      _c("h5", { staticClass: "mb-16 member-title" }, [
        _vm._v(_vm._s(_vm.member.name)),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "text-gray member-address soft-underline text-primary mb-18",
          attrs: { herf: "mailto:" + _vm.member.email },
        },
        [_vm._v(_vm._s(_vm.member.email))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-gray member-address text-primary mb-18" },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.member.position) } })]
      ),
      _vm._v(" "),
      _vm.member.calendly_link
        ? _c(
            "a",
            {
              staticClass: "btn btn-block btn-sm btn-primary-v2",
              attrs: { href: _vm.member.calendly_link },
            },
            [_vm._v("Plan een afspraak")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }