<style lang="scss" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #272B32;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.form-select {
  height: 60px;

  &.disabled {
    ::v-deep .vs__dropdown-toggle {
      background: #f8f8f8;
    }
  }

  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 2px;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    padding: 15px 1rem;

    input {
      &::placeholder {
        color: #9a9a9a;
        font-weight: 400;
        opacity: 1;
      }
    }
  }
}
</style>

<template>
  <div class="row">
    <template v-if="!loading">
    
<!--       <section class="container mb-56">
        <div class="form-row">
          <div class="col-12 col-lg-6 mb-16">
            <label class="mb-8 small font-weight-bold">Locatie</label>
            <input v-model="data.search" @input="filterChange(data.search)" name="search" type="text" label="Locatie" class="form-control mb-0" placeholder="Gemeente, Straat" />
          </div>
          <div class="col-12 col-lg-6 mb-16">
            <label class="mb-8 small font-weight-bold">Status</label>
            <v-select v-model="data.verkochtOptie" class="form-select" :options="['Verkocht', 'Alle']" placeholder="Status" @input="statusChange(data.verkochtOptie)"></v-select>
          </div>
        </div>
      </section> -->

      <div class="col-12">
        <project-card v-for="project in projecten.slice(0, 2)" :key="project.id" :project="project"></project-card>
      </div>

    <div class="col-12 mb-80">
        <project-cta></project-cta>
    </div>

      <div class="col-12">
        <project-card v-for="project in projecten.slice(2, projecten.length)" :key="project.id" :project="project"></project-card>
      </div>
    </template>
    <div v-else class="col-12">
      <div class="w-50 mx-auto text-center">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from '../parts/project-card.vue';
import ProjectCta from '../parts/project-cta.vue';

export default {
  components: {
    ProjectCard,
    ProjectCta,
  },

  data() {
    return {
      projecten: [],
      projectsPreFilter: [],
      loading: false,
      data: {
        search: '',
        verkochtOptie: false,
      },
    };
  },

  mounted() {
    this.fetchProjects();
  },

  methods: {
    fetchProjects() {
      this.loading = true;
      axios
        .get('/api/v2/projects', {
          params: {
            page: 1,
            per_page: 99,
            sort: 'putOnlineDateTime,asc',
          },
        })
        .then((projecten) => {
          this.loading = false;
          this.projecten = projecten.data.data.sort((a, b) => {
            return a.putOnlineDateTime.date < b.putOnlineDateTime.date ? 1 : -1;
          })
          this.projectsPreFilter = projecten.data.data;
        });
    },
    filterChange(data) {
      if (data == '') {
        this.projecten = this.projectsPreFilter;
      } else {
        this.projecten = this.projectsPreFilter.filter(function (project) {
          return project.street.toLowerCase().includes(data.toLowerCase()) || project.city.toLowerCase().includes(data.toLowerCase());
        });
      }
    },
    statusChange(data) {
      if (data == 'Alle') {
        this.projecten = this.projectsPreFilter;
      } else {
        this.projecten = this.projecten.filter(function (project) {
          return project.status.includes('sold');
        });
      }
    },
  },
};
</script>
