var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass:
        "estate-card h-100 d-flex align-items-center justify-content-center d-flex flex-column py-4",
      class: _vm.background == "bg-primary" ? "" : "border-gold",
    },
    [
      _vm.background == "bg-primary"
        ? _c(
            "svg",
            {
              attrs: {
                width: "64",
                height: "72",
                viewBox: "0 0 19 22",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z",
                  stroke: "white",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M3.44043 20.7402V10.7402H9.44043V20.7402",
                  stroke: "white",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M13 15C13 13.4379 13 11 13 11H16V15",
                  stroke: "white",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.background == "bg-gray-light"
        ? _c(
            "svg",
            {
              attrs: {
                width: "64",
                height: "72",
                viewBox: "0 0 19 22",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z",
                  stroke: "#BBA876",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M3.44043 20.7402V10.7402H9.44043V20.7402",
                  stroke: "#BBA876",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M13 15C13 13.4379 13 11 13 11H16V15",
                  stroke: "#BBA876",
                  "stroke-width": "0.7",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h4",
        { class: _vm.background == "bg-primary" ? "text-white" : "text-gold" },
        [_vm._v(_vm._s(_vm.data.title))]
      ),
      _vm._v(" "),
      _c(
        "p",
        { class: _vm.background == "bg-primary" ? "text-white" : "text-gold" },
        [_vm._v(_vm._s(_vm.data.description))]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn",
          class: _vm.background == "bg-primary" ? "btn-gold" : "btn-white",
          attrs: { href: "/contact" },
        },
        [_vm._v("\n        " + _vm._s(_vm.data.cta_button) + "\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }