import Vue from 'vue';

Vue.prototype.formatPrice = function (price, currency) {
    const formattedPrice = parseInt(price).toLocaleString('nl');

    switch (currency) {
        case 'EUR':
            return '€' + formattedPrice;
            break;
        case 'USD':
            return '$' + formattedPrice;
            break;
        default:
            return '' + formattedPrice;
    }
};

Vue.prototype.formatRoomSize = function (size, unit) {
    switch (unit) {
        case 'are':
            return `${size} are`;
            break;
        case 'sq_m':
            return `${size}m²`;
            break;
        case 'sq_ft':
            return `${size}ft²`;
            break;
        case 'acre':
            return `${size} hectare`;
            break;
    }
};

Vue.prototype.formatType = function (type) {
    switch (type) {
        case 1:
            return 'Huis';
        case 2:
            return 'Appartement';
        case 3:
            return 'Bouwgrond';
        case 4:
            return 'Kantoor';
        case 5:
            return 'Commercieel';
        case 6:
            return 'Industrie';
        case 7:
            return 'Parking';
    }
};

Vue.prototype.formatSubType = function (type) {
    switch (type) {
        case 1:
            return 'Huis';
        case 2:
            return 'Buitengewoon huis';
        case 3:
            return 'Appartementsgebouw';
        case 4:
            return 'Boerderij';
        case 5:
            return 'Bungalow';
        case 6:
            return 'Garagebox';
        case 7:
            return 'Bel-etage';
        case 8:
            return 'Gebouw voor gemengd gebruik';
        case 9:
            return 'Hoeve';
        case 10:
            return 'Chalet';
        case 12:
            return 'Herenhuis';
        case 13:
            return 'Villa';
        case 14:
            return 'Kasteel';
        case 16:
            return 'Ander pand';
        case 17:
            return 'Appartement';
        case 18:
            return 'Duplex';
        case 19:
            return 'Penthouse';
        case 20:
            return 'Flat';
        case 21:
            return 'Studio';
        case 22:
            return 'Triplex';
        case 23:
            return 'Loft';
        case 25:
            return 'Gelijkvloerse verdieping';
        case 26:
            return 'Bouwgrond';
        case 27:
            return 'Velden';
        case 28:
            return 'Onbebouwbare grond';
        case 29:
            return 'Recreatief terrein';
        case 30:
            return 'Weide';
        case 31:
            return 'Landbouw grond';
        case 32:
            return 'Bos';
        case 33:
            return 'Boomgaard';
        case 34:
            return 'Grond';
        case 35:
            return 'Kantoorgebouw';
        case 36:
            return 'Villa';
        case 37:
            return 'Gebouw voor gemengd gebruik';
        case 38:
            return 'Herenhuis';
        case 40:
            return 'Kantoor';
        case 41:
            return 'Gebouw';
        case 42:
            return 'Individuele handelszaak';
        case 43:
            return 'Taverne';
        case 44:
            return 'Commercieel centrum';
        case 45:
            return 'Gebouw voor gemengd gebruik';
        case 46:
            return 'Industrieel gebouw';
        case 47:
            return 'Gebouw voor gemengd gebruik';
        case 48:
            return 'Buitenstaanplaats';
        case 49:
            return 'Binnenstaanplaats';
        case 50:
            return 'Open garagebox';
        case 51:
            return 'Gesloten garagebox';
        case 52:
            return 'Carport';
        case 53:
            return 'Opslagplaats';
        case 54:
            return 'Horeca';
        case 55:
            return 'Voeding';
        case 56:
            return 'Boekhandel/tabak/bloem';
        case 57:
            return 'Modezaak/textiel';
        case 58:
            return 'Wagens/fietsen';
        case 59:
            return 'Kapsalon / esthetiek';
        case 60:
            return 'Andere';
        case 61:
            return 'Andere';
        case 62:
            return 'Andere';
        case 63:
            return 'Andere';
        case 64:
            return 'Andere';
        case 65:
            return 'Baanwinkel';
        case 66:
            return 'Andere';
        case 69:
            return 'App.complex gemengd gebruik';
        case 70:
            return 'Landhuis';
        case 71:
            return 'Charmant huis';
        case 72:
            return 'App. in charmant huis';
        case 73:
            return 'Gelijkvloerse verd. + tuin';
        case 74:
            return 'Appartement met tuin';
        case 76:
            return 'Gemengd gebruik';
        case 77:
            return 'Archieven';
        case 78:
            return 'Industrieel terrein';
        case 79:
            return 'Productiegebouw';
        case 80:
            return 'Atelier';
        case 81:
            return 'Koelplaats';
        case 82:
            return 'Kantoren & magazijn';
        case 83:
            return 'Kantoor';
        case 84:
            return 'Berging - archieven';
        case 85:
            return 'Garage + berging';
        case 86:
            return 'Dubbele garage';
        case 87:
            return 'Vakantiewoning';
        case 88:
            return 'Kmo-terrein';
        case 89:
            return 'Eengezinswoning';
        case 90:
            return 'Koppelvilla';
        case 93:
            return 'Studentenkamer';
        case 94:
            return 'Dakappartement';
        case 95:
            return 'Service flats';
        case 96:
            return 'Nieuwbouw woning';
        case 97:
            return 'Nieuwbouw appartement';
        case 98:
            return 'Afhaalcentrum';
        case 99:
            return 'Bed & breakfast';
        case 101:
            return 'Brood / banketbakkerij';
        case 102:
            return 'Broodjeszaak';
        case 103:
            return 'Café / bar';
        case 104:
            return 'Café / bar / room';
        case 105:
            return 'Café / restaurant';
        case 106:
            return 'Café / restaurant / zalen';
        case 107:
            return 'Cafetaria / snackbar';
        case 108:
            return 'Camping / recreatiebedrijf';
        case 109:
            return 'Chinees restaurant';
        case 110:
            return 'Casco horecaruimte';
        case 111:
            return 'Catering';
        case 112:
            return 'Coffeeshop';
        case 113:
            return 'Club';
        case 114:
            return 'Dagexploitatie';
        case 115:
            return 'Danscentrum';
        case 116:
            return 'Delicatessenwinkel';
        case 117:
            return 'Discotheek / dancing';
        case 118:
            return 'Discotheek / nachtcafé';
        case 119:
            return 'Eetcafé';
        case 120:
            return 'Gamecentre';
        case 121:
            return 'Gebakwinkel';
        case 122:
            return 'Grand-café';
        case 123:
            return 'Koffieshop / koffiecorner';
        case 124:
            return 'Loungeclub';
        case 125:
            return 'Partyboot';
        case 126:
            return 'Partycentrum';
        case 127:
            return 'Pension';
        case 128:
            return 'Hotel';
        case 129:
            return 'Hotel / restaurant';
        case 130:
            return 'Hotel / restaurant / zalen';
        case 131:
            return 'Hostel';
        case 133:
            return 'IJssalon';
        case 134:
            return 'Lunchroom';
        case 135:
            return 'Overige horeca ';
        case 136:
            return 'Pizzeria';
        case 137:
            return 'Pizzeria + bezorgservice';
        case 138:
            return 'Restaurant';
        case 139:
            return 'Restaurant / zalen';
        case 140:
            return 'Shoarma / grillroom';
        case 141:
            return 'Shoarma/grillr. + bezorgserv.';
        case 142:
            return 'Snack- / viswagen';
        case 143:
            return 'Strandpaviljoen';
        case 144:
            return 'Sauna';
        case 145:
            return 'Tearoom';
        case 146:
            return 'Traiteur ';
        case 147:
            return 'Viswinkel';
        case 148:
            return 'Wellness';
        case 149:
            return 'Wegrestaurant';
        case 150:
            return 'Wokrestaurant';
        case 151:
            return 'Winkelpand';
        case 152:
            return 'Zalen / partycentrum';
        case 154:
            return 'Rusthuizen';
        case 155:
            return 'Uitzonderlijke villa';
        case 156:
            return 'Uitzonderlijk appartement';
        case 157:
            return 'Kelder';
        case 158:
            return 'Cabin';
        case 159:
            return 'Terrein voor ondernemingen';
        case 160:
            return 'Kamer';
        case 161:
            return 'Bouwgrond (projecten)';
        case 162:
            return 'Commercieel gelijkvloers';
        case 163:
            return 'Snack';
        case 164:
            return 'Commerciële building';
        case 165:
            return 'Assistentiewoning';
        case 167:
            return 'Fietsenberging';
        case 168:
            return 'Bedrijfsgebouw';
        case 169:
            return 'KMO-unit';
        case 170:
            return 'Parking';
        case 171:
            return 'Kantoor + res.';
        case 172:
            return 'Res. + kantoor';
        case 173:
            return 'Grachtenpand';
        case 174:
            return 'Stacaravan';
        case 175:
            return 'Woonwagen';
        case 176:
            return 'Bovenwoning';
        case 177:
            return 'Benedenwoning';
        case 178:
            return 'Maisonnette';
        case 179:
            return 'Galerijflat';
        case 180:
            return 'Portiekflat';
        case 181:
            return 'Portiekwoning';
        case 182:
            return 'Dubbel benedenhuis';
        case 183:
            return 'Tussenverdieping';
    }
};
