var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-40" },
      [
        _c("label", { staticClass: "c-label", attrs: { for: "" } }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(
                ("€" + _vm.propertyValue).replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1."
                )
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("range-slider", {
          staticClass: "slider-el",
          attrs: { min: "0", max: "1000000", step: "500" },
          model: {
            value: _vm.propertyValue,
            callback: function ($$v) {
              _vm.propertyValue = $$v
            },
            expression: "propertyValue",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-label mb-40" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("span", { staticClass: "numberamount text-danger" }, [
        _vm._v(
          _vm._s(
            ("" + _vm.otherPrice).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "form-group mb-40 d-lg-flex align-items-center justify-content-between w-100",
      },
      [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "toggle" }, [
          _c(
            "div",
            {
              staticClass: "toggle-btn",
              class: { active: _vm.price === 5445 },
              on: {
                click: function ($event) {
                  _vm.price = 5445
                },
              },
            },
            [_vm._v("Classic")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "toggle-btn",
              class: { active: _vm.price === 7260 },
              on: {
                click: function ($event) {
                  _vm.price = 7260
                },
              },
            },
            [_vm._v("Copandi")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-label mb-40" }, [
      _vm._m(3),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "numberamount text-lg ",
          class: {
            "text-primary": _vm.price !== 10000,
            "text-gold": _vm.price === 10000,
          },
        },
        [
          _vm._v(
            _vm._s(("€" + _vm.save).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "btn btn-primary border-50",
        attrs: { href: "/maak-een-gratis-schatting" },
      },
      [_vm._v(" Gratis schatting ")]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n        Waarde van\n        "),
      _c("span", { staticClass: "text-primary" }, [_vm._v("je eigendom")]),
      _vm._v("\n        ?\n      "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n      Bij een\n      "),
      _c("span", { staticClass: "text-primary" }, [
        _vm._v("traditionele makelaar"),
      ]),
      _vm._v("\n      betaal je\n    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "c-label", attrs: { for: "worth" } }, [
      _c("div", [
        _vm._v("Bij "),
        _c("span", { staticClass: "text-primary" }, [_vm._v("Copandi")]),
        _vm._v(" betaal je"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n      Bij\n      "),
      _c("span", { staticClass: "text-primary" }, [_vm._v("Copandi")]),
      _vm._v("\n      bespaar je\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }