<style lang="scss" scoped>
.member-card {
  cursor: pointer;
  text-decoration: none;
  display: block;
  border: 1px solid #f7f7f7;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
  .member-image-holder {
    position: relative;
    display: block;
  }
  .member-image {
    width: 100%;
    padding-top: 4/6 * 100%;
    height: 0;
    overflow: hidden;
    background-size: cover;
    background-position: 0px -5px;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: block;
    &.can-hover {
      transition: opacity 0.5 ease-in;
      z-index: 2;
      @media (min-width: 992px) {
      }
      &:hover {
        opacity: 0;
      }
    }
  }
  .member-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 1;
  }
  .member-text {
    position: relative;
    padding: 32px 24px 24px 24px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .member-tag {
    border-radius: 50px;
    background: var(--primary);
    border: 4px solid #fff;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.25px;
    letter-spacing: 0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 8px 16px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: table;
    z-index: 3;
  }
  .member-title {
    color: #272b32;
    font-size: 20px;
    line-height: 1.2;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    .text-normal {
      text-align: left;
      font-weight: 400;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}
</style>

<template>
  <div class="member-card">
    <a @mouseenter="mouseEnter" @mouseleave="mouseLeave" :href="url" class="member-image-holder">
      <video v-if="member.video" ref="video" :title="member.name" class="lazyload w-100 member-video" loop muted webkit-playsinline="true" playsinline="true">
        <source :src="member.video" type="video/mp4" />
      </video>
      <div class="member-image" :class="{ 'can-hover': member.video }" :style="{ backgroundImage: `url(${member.image.url})`, paddingTop: '66%' }"></div>
    </a>
    <div class="member-text">
      <a class="member-tag" :href="`tel:${member.telephone}`">{{ member.telephone }}</a>
      <a class="mb-16 member-title h5 soft-underline d-inline-block" :href="url">{{ member.name }}</a>

      <a class="text-gray member-address soft-underline text-primary mb-18 d-inline-block" :herf="`mailto:${member.email}`">{{ member.email }}</a>
      <div class="text-gray member-address text-primary mb-18">
        <span v-html="member.position"></span>
      </div>

      <a v-if="member.calendly_link" :href="member.calendly_link" class="btn btn-block btn-sm btn-primary-v2">Plan een afspraak</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      required: true,
    },
    url: {
      required: true,
    },
  },

  data: {
    showVideo: false,
  },

  methods: {
    mouseEnter() {
      this.showVideo = true;
      if (this.$refs && this.$refs.video) {
        this.$refs.video.play();
      }
    },
    mouseLeave() {
      this.showVideo = false;
      if (this.$refs && this.$refs.video) {
        this.$refs.video.pause();
      }
    },
  },
};
</script>
