var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(!_vm.success)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verifyAndSubmit)}}},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Type")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"placeholder":"Type","options":_vm.typeOptions},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Kopen of Huren")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"clearable":false,"options":_vm.saleOrLetOptions},model:{value:(_vm.data.sale_or_let),callback:function ($$v) {_vm.$set(_vm.data, "sale_or_let", $$v)},expression:"data.sale_or_let"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Postcode(s)")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zipcodes),expression:"zipcodes"}],attrs:{"type":"hidden","autocomplete":"off","name":"zipcodes"},domProps:{"value":(_vm.zipcodes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.zipcodes=$event.target.value}}}),_vm._v(" "),_c('vue-tags-input',_vm._b({ref:"tagput",attrs:{"validation":_vm.zipcodeValidation,"autocomplete":"off","add-on-key":[13, ','],"tags":_vm.zipcodes,"placeholder":"Vul één of meerdere postcodes in gescheiden door een komma."},on:{"tags-changed":function (newRegion) { return (_vm.zipcodes = newRegion); }},model:{value:(_vm.zipcode),callback:function ($$v) {_vm.zipcode=$$v},expression:"zipcode"}},'vue-tags-input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Minimum budget")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"placeholder":"min budget","options":_vm.budgetMinOptions},model:{value:(_vm.data.price_min),callback:function ($$v) {_vm.$set(_vm.data, "price_min", $$v)},expression:"data.price_min"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Maximum budget")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"placeholder":"max budget","options":_vm.budgetMaxOptions},model:{value:(_vm.data.price_max),callback:function ($$v) {_vm.$set(_vm.data, "price_max", $$v)},expression:"data.price_max"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Minimum staat")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"placeholder":"Staat²","options":_vm.stateOptions},model:{value:(_vm.data.state),callback:function ($$v) {_vm.$set(_vm.data, "state", $$v)},expression:"data.state"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Minimum aantal slaapkamers")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"options":_vm.bedroomOptions,"placeholder":"Slaapkamers"},model:{value:(_vm.data.bedrooms),callback:function ($$v) {_vm.$set(_vm.data, "bedrooms", $$v)},expression:"data.bedrooms"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Minimum bewoonbare oppervlakte")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"options":_vm.areaminOtions,"placeholder":"min m²"},model:{value:(_vm.data.area_min),callback:function ($$v) {_vm.$set(_vm.data, "area_min", $$v)},expression:"data.area_min"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Maximum bewoonbare oppervlakte")]),_vm._v(" "),_c('v-select',_vm._b({staticClass:"form-select mb-0",attrs:{"options":_vm.areamaxOtions,"placeholder":"max m²"},model:{value:(_vm.data.area_max),callback:function ($$v) {_vm.$set(_vm.data, "area_max", $$v)},expression:"data.area_max"}},'v-select',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"col-12 my-gridh"},[_c('strong',[_vm._v("Contactgegevens")])]),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Voornaam")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.first_name),expression:"data.first_name"}],staticClass:"form-control mb-0",attrs:{"name":"Voornaam","type":"text","label":"Voornaam","placeholder":"Voornaam"},domProps:{"value":(_vm.data.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "first_name", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Achternaam")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.last_name),expression:"data.last_name"}],staticClass:"form-control mb-0",attrs:{"name":"Achternaam","type":"text","label":"Achternaam","placeholder":"Achternaam"},domProps:{"value":(_vm.data.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "last_name", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required|email","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Emailadres")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control mb-0",attrs:{"name":"Emailadres","type":"email","label":"Emailadres","placeholder":"Emailadres"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Telefoonnummer")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],staticClass:"form-control mb-0",attrs:{"name":"Telefoonnummer","type":"text","label":"Telefoonnummer","placeholder":"Telefoonnummer"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"col-12 mb-gridh"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"required":"required","type":"checkbox","value":"","id":"checkakkoord"}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"checkakkoord"}},[_vm._v("Ik ga akkoord met het "),_c('a',{attrs:{"href":"/privacy-policy"}},[_vm._v("privacybeleid")]),_vm._v(" van Copandi ")])])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn d-flex align-items-center justify-content-center btn-primary",attrs:{"type":"submit"}},[_vm._v("\n          Verstuur\n          "),_c('el-loader',{staticClass:"ml-16",attrs:{"loading":_vm.loading}})],1)])],1)]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-10"},[_c('p',{staticClass:"alert alert-success"},[_vm._v("Bedankt, je bent opgenomen in onze mailinglijst!")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }