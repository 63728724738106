var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "slider" },
    [
      _c("div", { ref: "fakeTainer", staticClass: "container" }),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "slider" },
            _vm._l(_vm.renderedImages, function (image, key) {
              return _c(
                "div",
                { key: key, style: { height: image.preffered_height + "px" } },
                [
                  _c("img", {
                    staticClass: "lazyload rounded-lg",
                    class: "image-" + key,
                    style: {
                      width: image.preffered_width + "px",
                      height: image.preffered_height + "px",
                    },
                    attrs: { "data-src": image.src },
                    on: {
                      click: function ($event) {
                        _vm.index = key
                      },
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("vue-gallery-slideshow", {
            attrs: { images: _vm.renderedImages, index: _vm.index },
            on: {
              close: function ($event) {
                _vm.index = null
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }