var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("section", { staticClass: "row mb-lg-40" }, [
      _vm.showFilters
        ? _c("div", { staticClass: "container d-none d-md-block" }, [
            _c("div", { staticClass: "form-row" }, [
              !_vm.projectId
                ? _c("div", { staticClass: "col-12 col-lg mb-16" }, [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Straat")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.address,
                          expression: "data.address",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        label: "Straat",
                        name: "straat",
                        placeholder: "Straat",
                      },
                      domProps: { value: _vm.data.address },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "address", $event.target.value)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.projectId
                ? _c(
                    "div",
                    { staticClass: "col-12 col-lg mb-16 w-city" },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-8 small font-weight-bold" },
                        [_vm._v("Gemeente")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select multi-select",
                        attrs: {
                          options: _vm.cities,
                          multiple: true,
                          autocomplete: "on",
                          placeholder: "Gemeente",
                        },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter(
                              "city",
                              val
                                .map(function (v) {
                                  return v.key
                                })
                                .join()
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedCities,
                          callback: function ($$v) {
                            _vm.selectedCities = $$v
                          },
                          expression: "selectedCities",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.projectId
                ? _c(
                    "div",
                    { staticClass: "col-12 col-lg mb-16 w-region" },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-8 small font-weight-bold" },
                        [_vm._v("Regio")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: { options: _vm.regions, placeholder: "Regio" },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter("region", val.key)
                          },
                        },
                        model: {
                          value: _vm.selectedRegion,
                          callback: function ($$v) {
                            _vm.selectedRegion = $$v
                          },
                          expression: "selectedRegion",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.projectId
                ? _c(
                    "div",
                    { staticClass: "col-12 col-lg mb-16 w-type" },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-8 small font-weight-bold" },
                        [_vm._v("Type")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: { options: _vm.types, placeholder: "Type" },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter("type", val.key)
                          },
                        },
                        model: {
                          value: _vm.selectedType,
                          callback: function ($$v) {
                            _vm.selectedType = $$v
                          },
                          expression: "selectedType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg mb-16 w-price" },
                [
                  _c("label", { staticClass: "mb-8 small font-weight-bold" }, [
                    _vm._v("Min prijs"),
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "form-select",
                    attrs: { options: _vm.min, placeholder: "Min prijs" },
                    on: {
                      input: function (val) {
                        return _vm.handleChangeFilter("minprice", val.key)
                      },
                    },
                    model: {
                      value: _vm.selectedMin,
                      callback: function ($$v) {
                        _vm.selectedMin = $$v
                      },
                      expression: "selectedMin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg mb-16 w-price" },
                [
                  _c("label", { staticClass: "mb-8 small font-weight-bold" }, [
                    _vm._v("Max prijs"),
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    staticClass: "form-select",
                    attrs: { options: _vm.max, placeholder: "Max prijs" },
                    on: {
                      input: function (val) {
                        return _vm.handleChangeFilter("maxprice", val.key)
                      },
                    },
                    model: {
                      value: _vm.selectedMax,
                      callback: function ($$v) {
                        _vm.selectedMax = $$v
                      },
                      expression: "selectedMax",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showFilters
        ? _c("div", { staticClass: "container d-block d-md-none" }, [
            _c("div", { staticClass: "form-row" }, [
              !_vm.projectId
                ? _c("div", { staticClass: "col-12 col-lg mb-16" }, [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Straat")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.address,
                          expression: "data.address",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        label: "Straat",
                        name: "straat",
                        placeholder: "Straat",
                      },
                      domProps: { value: _vm.data.address },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "address", $event.target.value)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.projectId
                ? _c(
                    "div",
                    { staticClass: "col-12 col-lg-3 mb-16" },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-8 small font-weight-bold" },
                        [_vm._v("Stad")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select multi-select",
                        attrs: {
                          autocomplete: "on",
                          multiple: true,
                          options: _vm.cities,
                          placeholder: "Stad",
                        },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter(
                              "city",
                              val
                                .map(function (v) {
                                  return v.key
                                })
                                .join()
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedCities,
                          callback: function ($$v) {
                            _vm.selectedCities = $$v
                          },
                          expression: "selectedCities",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.projectId
                ? _c(
                    "div",
                    { staticClass: "col-12 col-lg-3 mb-16" },
                    [
                      _c(
                        "label",
                        { staticClass: "mb-8 small font-weight-bold" },
                        [_vm._v("Regio")]
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: { options: _vm.regions, placeholder: "Regio" },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter("region", val.key)
                          },
                        },
                        model: {
                          value: _vm.selectedRegion,
                          callback: function ($$v) {
                            _vm.selectedRegion = $$v
                          },
                          expression: "selectedRegion",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 d-flex align-items-center justify-content-center mb-16",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "link",
                      attrs: { type: "button" },
                      on: { click: _vm.openDrawer },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.showMobileFilters ? "Minder" : "Meer") +
                          " filters"
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mobile-filters col-12" }, [
                !_vm.projectId
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-lg-3 mb-16" },
                      [
                        _c(
                          "label",
                          { staticClass: "mb-8 small font-weight-bold" },
                          [_vm._v("Type")]
                        ),
                        _vm._v(" "),
                        _c("v-select", {
                          staticClass: "form-select",
                          attrs: { options: _vm.types, placeholder: "Type" },
                          on: {
                            input: function (val) {
                              return _vm.handleChangeFilter("type", val.key)
                            },
                          },
                          model: {
                            value: _vm.selectedType,
                            callback: function ($$v) {
                              _vm.selectedType = $$v
                            },
                            expression: "selectedType",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-3 mb-16" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Status")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "form-select",
                      attrs: {
                        options: _vm.saleOrOption,
                        placeholder: "Status",
                      },
                      on: {
                        input: function (val) {
                          return _vm.handleChangeFilter(
                            "verkochtOptie",
                            val.key
                          )
                        },
                      },
                      model: {
                        value: _vm.selectedSaleOrOption,
                        callback: function ($$v) {
                          _vm.selectedSaleOrOption = $$v
                        },
                        expression: "selectedSaleOrOption",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-2 mb-16" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Minimum prijs")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "form-select",
                      attrs: { options: _vm.min, placeholder: "Minimum prijs" },
                      on: {
                        input: function (val) {
                          return _vm.handleChangeFilter("minprice", val.key)
                        },
                      },
                      model: {
                        value: _vm.selectedMin,
                        callback: function ($$v) {
                          _vm.selectedMin = $$v
                        },
                        expression: "selectedMin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-2 mb-16" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Maximum prijs")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "form-select",
                      attrs: { options: _vm.max, placeholder: "Maximum prijs" },
                      on: {
                        input: function (val) {
                          return _vm.handleChangeFilter("maxprice", val.key)
                        },
                      },
                      model: {
                        value: _vm.selectedMax,
                        callback: function ($$v) {
                          _vm.selectedMax = $$v
                        },
                        expression: "selectedMax",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-2 mb-16" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Sorteer op")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "form-select sort",
                      attrs: { options: _vm.orders, placeholder: "sorteer" },
                      on: {
                        input: function (val) {
                          return _vm.handleChangeFilter("order", val.key)
                        },
                      },
                      model: {
                        value: _vm.order,
                        callback: function ($$v) {
                          _vm.order = $$v
                        },
                        expression: "order",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-2 mb-16" },
                  [
                    _c(
                      "label",
                      { staticClass: "mb-8 small font-weight-bold" },
                      [_vm._v("Sorteer op")]
                    ),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "form-select",
                      attrs: {
                        options: _vm.saleOrOption,
                        placeholder: "Status",
                      },
                      on: {
                        input: function (val) {
                          return _vm.handleChangeFilter(
                            "verkochtOptie",
                            val.key
                          )
                        },
                      },
                      model: {
                        value: _vm.selectedSaleOrOption,
                        callback: function ($$v) {
                          _vm.selectedSaleOrOption = $$v
                        },
                        expression: "selectedSaleOrOption",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "row page pt-56", class: _vm.theme }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 mb-grid" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-lg-between mb-40 align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-wrap align-items-center justify-content-center w-100 w-lg-auto justify-content-lg-start",
                    },
                    [
                      !_vm.projectId
                        ? _c("div", { staticClass: "toggle mr-lg-32" }, [
                            _c(
                              "div",
                              {
                                staticClass: "toggle-btn",
                                class: [
                                  { active: _vm.data.saleOrLet === "sale" },
                                  _vm.theme,
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeFilter(
                                      "saleOrLet",
                                      "sale"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Te koop")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "toggle-btn",
                                class: [
                                  { active: _vm.data.saleOrLet === "let" },
                                  _vm.theme,
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeFilter(
                                      "saleOrLet",
                                      "let"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Te huur")]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h6", { staticClass: "mb-0 d-none d-lg-block" }, [
                        _vm._v(
                          _vm._s(
                            _vm.totalEstates > 0 ? _vm.totalEstates : "Geen"
                          ) + " panden gevonden"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-none d-lg-flex flex-row align-self-end" },
                    [
                      _c("v-select", {
                        staticClass: "form-select sort",
                        attrs: {
                          searchable: false,
                          options: _vm.orders,
                          placeholder: "sorteer",
                        },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter("order", val.key)
                          },
                        },
                        model: {
                          value: _vm.order,
                          callback: function ($$v) {
                            _vm.order = $$v
                          },
                          expression: "order",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        staticClass: "form-select ml-2 status",
                        attrs: {
                          searchable: false,
                          options: _vm.saleOrOption,
                          placeholder: "Status",
                        },
                        on: {
                          input: function (val) {
                            return _vm.handleChangeFilter(
                              "verkochtOptie",
                              val.key
                            )
                          },
                        },
                        model: {
                          value: _vm.selectedSaleOrOption,
                          callback: function ($$v) {
                            _vm.selectedSaleOrOption = $$v
                          },
                          expression: "selectedSaleOrOption",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.loaded
                ? _c("estate-spotlight", {
                    ref: "estateSpotlight",
                    attrs: {
                      theme: _vm.theme,
                      params: _vm.data,
                      mode: _vm.mode,
                      "cta-cards": _vm.ctaCards,
                      "is-project":
                        _vm.projectId !== null ? this.projectId : false,
                      "extra-project-ids": _vm.extraProjectIds,
                      "show-total": true,
                      "show-sort": true,
                      "per-page": _vm.ctaCards ? 8 : 9,
                      "per-row": "3",
                      "can-load-more": "true",
                    },
                    on: { "total-updated": _vm.handleTotalUpdated },
                  })
                : _c("div", { staticClass: "row w-100" }, [
                    _c("div", { staticClass: "w-50 mx-auto text-center" }, [
                      _c("div", { staticClass: "lds-ellipsis" }, [
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                        _vm._v(" "),
                        _c("div"),
                      ]),
                    ]),
                  ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }