var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"514px"},attrs:{"center":{
    lat: parseFloat(_vm.center.lat),
    lng: parseFloat(_vm.center.lng) - _vm.localOffsetX,
  },"zoom":_vm.zoom,"map-type-id":_vm.mapTypeId,"options":{
    streetViewControl: false,
    draggingCursor: false,
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false,
    keyboardShortcuts: false,
    styles: _vm.styles,
  }}},[_c('gmap-cluster',{attrs:{"styles":_vm.clusterStyles}},_vm._l((_vm.estates),function(m,index){return _c('GmapMarker',{key:("marker_" + index),attrs:{"position":m.coordinate,"icon":m.icon},on:{"click":function($event){_vm.window.location.href = m.route}}})}),1),_vm._v(" "),_vm._l((_vm.offices),function(m,index){return _c('GmapCircle',{key:("circle_" + index),attrs:{"center":m.coordinate,"radius":m.radius,"visible":true,"options":{ fillColor: '#92BE64', fillOpacity: 0.5, strokeOpacity: 0 }}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }