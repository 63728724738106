<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
::v-deep .icon{
    width: 24px;
}
</style>

<template>
  <div class="container pt-24">
    <div class="row" id="test">
      <div class="col-12 d-flex justify-content-end align-items-center px-0">
        <span class="d-flex flex-row text-dark pointer align-items-center" @click="downloadPdf">
          <p class="mr-2 mb-0">Print pagina</p>
          <span v-html="svg('print', 'icon')"></span>
        </span>
      </div>
    </div>
    <print-layout :property="property" v-show="false" id="element-to-print"></print-layout>
  </div>
</template>

<script>
import PrintLayout from './print-layout';
export default {
  components: {
    PrintLayout,
  },
  props: {
    property: {
      type: Object,
    },
  },
  data: (vm) => ({}),
  methods: {
    async downloadPdf() {
      var element = document.getElementById('element-to-print').innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = element;
      window.print();
      window.location.reload();
    },
  },
};
</script>
