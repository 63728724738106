<style lang="scss" scoped>
.nav {
  color: #272b32;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  font-weight: 600; // Old 900
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  flex-flow: row;
  margin-bottom: 26px;
  cursor: pointer;
  .nav-item {
    margin-right: 20px;
    margin-left: 20px;
    transition: box-shadow 0.15s ease-in-out;
    padding-bottom: 3px;
    &.dark {
      color: #ffffff;
    }
    &.active,
    &:hover {
      box-shadow: 0px 3px 0 0px #83bf56;
    }
  }
}

.hero-form {
  border-radius: 38px;
  transition: width 0.15s ease-in-out;
  width: 100%;
  @media (min-width: 992px) {
    background-color: #ffffff;
    padding: 6.5px;
    height: 76px;
    border: 1px solid #d9d9d9;
    width: 600px;
    &.w-800 {
      width: 800px;
    }
  }

  form {
    display: flex;
    flex-flow: column;
    @media (min-width: 992px) {
      justify-content: space-between;
      align-items: center;
      flex-flow: row;
    }
    .input-wrapper {
      padding: 6.5px;
      height: 76px;
      border: 1px solid #d9d9d9;
      border-radius: 38px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      background-color: #ffffff;
      @media (min-width: 992px) {
        padding: 0;
        height: auto;
        border: none;
        border-radius: 0px;
        justify-content: space-between;
        flex-flow: row;
        width: 100%;
        margin-bottom: 0px;
      }
    }
  }

  .divider {
    width: 53px;
    height: 100%;
    display: block;
    flex: none;
    background: #d9d9d9;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 76px;
      width: 1px;
      background: #d9d9d9;
      top: -39px;
      left: 26px;
    }
  }

  input {
    border: none;
    margin-right: 26px;
    caret-color: #83c056;
    &:focus {
      outline: none;
    }
  }
  .form-select {
    width: 100%;
    font-family: CoreSansC; // Old Lato
    ::v-deep .vs__dropdown-toggle {
      border: none;
      height: 100%;
      padding: 0;
      margin: 0;
      caret-color: #83c056;
      input {
        color: #272b32;
        font-family: inherit;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0;
        padding-left: 0;
        &::placeholder {
          font-weight: 500;
          color: #757575;
        }
      }
      .vs__clear {
        display: none;
      }
      .vs__selected {
        text-transform: capitalize;
      }
    }
    ::v-deep .vs__dropdown-menu {
      background-color: #ffffff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
      border: none;
      top: 36px;
      .vs__dropdown-option--highlight {
        background: #fff;
        color: #83c056;
      }
      .vs__dropdown-option {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 15px;
        padding: 8px 12px;
      }
    }
    @media (min-width: 992px) {
      &.last {
        margin-right: 26px;
      }
    }
  }
  ::v-deep .icon {
    height: 24px;
    width: 24px;
    margin-left: 19.5px;
    margin-right: 26px;
    path {
      stroke: #83c056;
    }
  }
  .btn {
    flex: none;
    border: 2px solid #83c056;
    border-radius: 30px;
    background-color: #83c056;
    color: white;
    &:hover {
      background: transparent;
      color: #83c056;
    }
  }
}
</style>

<template>
  <div class="w-100">
    <div class="nav">
      <div @click="isActive = 'schatting'" class="nav-item" :class="[{ active: isActive === 'schatting' }, bgTheme]">schatting</div>
      <div @click="isActive = 'kopen'" class="nav-item" :class="[{ active: isActive === 'kopen' }, bgTheme]">kopen</div>
      <div @click="isActive = 'huren'" class="nav-item" :class="[{ active: isActive === 'huren' }, bgTheme]">huren</div>
    </div>

    <div class="hero-form" :class="{ 'w-800': ['kopen', 'huren'].includes(isActive) }">
      <form v-if="isActive === 'schatting'" @submit.prevent="handleSubmit">
        <div class="input-wrapper">
          <span v-html="svg('marker-hollow', 'icon')"></span>
          <vue-google-autocomplete id="adres" classname="w-100" placeholder="Wat is het adres van de woning?" v-on:placechanged="getAddressData" country="be"></vue-google-autocomplete>
        </div>
        <button type="submit" class="btn">Gratis schatting</button>
      </form>

      <form v-if="isActive === 'kopen'" @submit.prevent="handleSubmit">
        <div class="input-wrapper">
          <span v-html="svg('marker-hollow', 'icon')"></span>
          <v-select v-if="advanced" v-model="data.region" class="form-select" :options="regionOptions" placeholder="Gemeente of Provincie…"></v-select>
        </div>
        <span class="divider d-none d-lg-block"></span>
        <div class="input-wrapper">
          <span v-html="svg('house-outline', 'icon ml-lg-0')"></span>
          <v-select v-if="advanced" v-model="data.type" class="form-select last" :options="typeOptions" placeholder="Pandtype"></v-select>
        </div>
        <button type="submit" class="btn">zoeken</button>
      </form>

      <form v-if="isActive === 'huren'" @submit.prevent="handleSubmit">
        <div class="input-wrapper">
          <span v-html="svg('marker-hollow', 'icon')"></span>
          <v-select v-if="advanced" v-model="data.region" class="form-select" :options="regionOptions" placeholder="Gemeente of Provincie…"></v-select>
        </div>
        <span class="divider d-none d-lg-block"></span>
        <div class="input-wrapper">
          <span v-html="svg('house-outline', 'icon ml-lg-0')"></span>
          <v-select v-if="advanced" v-model="data.type" class="form-select last" :options="typeOptions" placeholder="Pandtype"></v-select>
        </div>
        <button type="submit" class="btn">Zoeken</button>
      </form>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    advanced: {
      default: false,
    },
    bgTheme: {
      default: 'light',
    },
  },
  data() {
    return {
      isActive: 'schatting',
      data: {
        address: false,
        type: false,
        region: false,
      },
      typeOptions: [
        { key: 'apartment', label: 'Appartement' },
        { key: 'house', label: 'Huis' },
        { key: 'land', label: 'Bouwgrond' },
        { key: 'commercial', label: 'Commercieel' },
        { key: 'parking', label: 'Garage' },
      ],
      regionOptions: [],
    };
  },

  mounted() {
    this.fetchCities();
    const urlParams = new URLSearchParams(window.location.search);

    const search = urlParams.get('search');
    const negotiation = urlParams.get('negotiation');
    const type = urlParams.get('type');

    if (search && search != '') {
      this.data.search = search;
    }

    if (negotiation && negotiation != '') {
      if (negotiation === 'let') {
        this.data.negotiation = { key: 'let', label: 'Te huur' };
      }
      if (negotiation === 'sale') {
        this.data.negotiation = { key: 'sale', label: 'Te koop' };
      }
    }

    if (type && type != '') {
      if (type === 'apartment') {
        this.data.type = { key: 'apartment', label: 'Appartement' };
      }
      if (type === 'house') {
        this.data.type = { key: 'house', label: 'Huis' };
      }
      if (type === 'land') {
        this.data.type = { key: 'land', label: 'Bouwgrond' };
      }
      if (type === 'commercial') {
        this.data.type = { key: 'commercial', label: 'Commercieel' };
      }
      if (type === 'parking') {
        this.data.type = { key: 'parking', label: 'Garage' };
      }
    }
  },

  methods: {
    handleSubmit() {
      let queryParam = '?';

      if (this.isActive === 'schatting') {
        if (this.data.address.route) {
          queryParam += `street=${this.data.address.route}&`;
        }
        if (this.data.address.street_number) {
          queryParam += `street_number=${this.data.address.street_number}&`;
        }
        if (this.data.address.postal_code) {
          queryParam += `postal_code=${this.data.address.postal_code}&`;
        }
        if (this.data.address.locality) {
          queryParam += `locality=${this.data.address.locality}&`;
        }
        if (this.data.address.country) {
          queryParam += `country=${this.data.address.country}&`;
        }
        if (this.data.address.latitude) {
          queryParam += `lat=${this.data.address.latitude}&`;
        }
        if (this.data.address.longitude) {
          queryParam += `long=${this.data.address.longitude}&`;
        }

        window.location = `/maak-een-gratis-schatting${queryParam}`;
        return;
      }

      if (this.isActive === 'kopen') {
        queryParam += `negotiation=sale&`;
      }

      if (this.isActive === 'huren') {
        queryParam += `negotiation=let&`;
      }

      if (this.data.type) {
        queryParam += `type=${this.data.type.key}&`;
      }

      if (this.data.region) {
        queryParam += `city=${this.data.region.key}&`;
      }

      queryParam = queryParam.slice(0, -1);
      window.location = `/resultaten${queryParam}`;
    },

    fetchCities() {
      axios.get('/api/v2/cities').then((response) => {
        const cities = {};
        response.data.forEach((city) => {
          if (cities[city.name]) {
            cities[city.name].key = cities[city.name].key + `,${city.zip}`;
          } else {
            cities[city.name] = { label: city.name, key: city.zip };
          }
        });

        this.regionOptions = Object.values(cities);
      });
    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      this.data.address = addressData;
    },
  },
};
</script>
