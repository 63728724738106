var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verifyAndSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',{staticClass:"mb-40"},[_vm._v("Meer info aanvragen")])]),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Voornaam")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.first_name),expression:"data.first_name"}],staticClass:"form-control mb-0",attrs:{"name":"first_name","type":"text","label":"Voornaam","placeholder":"Voornaam"},domProps:{"value":(_vm.data.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "first_name", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Achternaam")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.last_name),expression:"data.last_name"}],staticClass:"form-control mb-0",attrs:{"name":"last_name","type":"text","label":"Achternaam","placeholder":"Achternaam"},domProps:{"value":(_vm.data.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "last_name", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required|email","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Emailadres")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control mb-0",attrs:{"name":"email","type":"email","label":"Emailadres","placeholder":"Emailadres"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 col-lg-6 mb-gridh",attrs:{"rules":"required","bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("* Telefoonnummer")]),_vm._v(" "),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],staticClass:"form-control mb-0",attrs:{"name":"phone","type":"text","label":"Telefoonnummer","placeholder":"Telefoonnummer"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}},'input',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"col-12 mb-24",attrs:{"bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var ariaInput = ref.ariaInput;
return [_c('label',{staticClass:"mb-16",attrs:{"for":""}},[_vm._v("Bericht")]),_vm._v(" "),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data.message),expression:"data.message"}],staticClass:"form-control mb-0",attrs:{"name":"message","type":"text","label":"Bericht","placeholder":"Typ je bericht"},domProps:{"value":(_vm.data.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "message", $event.target.value)}}},'textarea',ariaInput,false)),_vm._v(" "),_c('span',_vm._b({staticClass:"text-danger"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn d-flex align-items-center justify-content-center btn-primary",attrs:{"type":"submit"}},[_vm._v("\n          Verstuur\n          "),_c('el-loader',{staticClass:"ml-16",attrs:{"loading":_vm.loading}})],1)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }