<style lang="scss">
.usp-slider-new .tns-item:not(.tns-slide-active) {
  opacity: 30%;
}
.usp-slider-new {
  position: relative;
  .tns-controls {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0px;
    height: 46px;
    margin: auto;
    bottom: -70px;
    @media (min-width: 992px) {
      width: calc(100% + 92px + 40px);
      left: -86px;
      top: 0;
      bottom: 0;
    }
    button {
      height: 46px;
      width: 46px;
      background-color: #f7f7f7;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      img {
        transition: all 0.15s ease-in-out;
        height: 16px;
      }
      &:hover {
        img {
          height: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="usp-slider-new">
    <div :class="wrapperClass" ref="slider">
      <div v-for="(column, key) in items" :key="key">
        <img class="mb-18 icon lazyload" :class="iconSize" :data-src="column.icon.url" alt="" />
        <h4 class="mb-24" v-html="column.title"></h4>
        <p v-if="column.description" class="mb-0" v-html="column.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    iconSize: {
      default: 'small',
    },
    items: {
      default: () => [],
    },
  },

  data() {
    return {
      tns: null,
    };
  },

  mounted() {
    this.initSlider();
  },

  methods: {
    initSlider() {
      const self = this;
      let width = self.$refs.slider.clientWidth;

      let desktopSize = 2.9;
      let controls = true;
      if (this.items.length <= 3) {
        desktopSize = 3;
        controls = false;
      }

      this.$nextTick(() => {
        self.tns = tns({
          container: self.$refs.slider,
          gutter: 32,
          controls: true,
          controlsText: ["<img src='/images/chevron-left.svg'/>", "<img src='/images/chevron-right.svg'/>"],
          touch: true,
          nav: false,
          loop: false,
          rewind: true,
          preventScrollOnTouch: 'auto',
          items: 1.2,
          fixedWidth: false,
          responsive: {
            960: {
              gutter: 70,
              autoWidth: true,
              items: desktopSize,
              controls: controls,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
  },
};
</script>
