<style scoped lang="scss">
input {
  height: 76px;
  border: 1px solid #d9d9d9;
  border-radius: 38px;
  background-color: #ffffff;
  min-width: 300px;
  padding-right: 222px;
  padding-left: 70px;
}
button {
  position: absolute;
  height: 63px;
  top: 6.5px;
  right: 6.5px;
  border: 2px solid #83c056;
  border-radius: 30px;
  background-color: #83c056;
  padding: 0 57px;
  color: #f7f7f7;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  font-weight: 600; // Old 900
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 204px;
  transition: width 0.15s ease-in-out;
  &.has-success {
    width: 320px;
    pointer-events: none;
  }
  &:hover {
    background: transparent;
    color: #83c056;
    ::v-deep .lds-ring div {
      border-color: #83c056;
    }
  }
}
::v-deep .icon {
  height: 26px;
  width: 26px;
  position: absolute;
  top: 27px;
  left: 27px;
}
.wrapper {
  display: block;
  margin-left: auto;
  width: 100%;
  transition: width 0.15s ease-in-out;
  &.saved {
    width: 333px !important;
  }
}
</style>

<template>
  <ValidationObserver v-slot="{ handleSubmit, valid }" class="w-100">
    <form class="position-relative w-100" @submit.prevent="handleSubmit(onSubmit)">
      <validation-provider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" class="wrapper" :class="{ saved: success }">
        <span v-html="svg('phone', 'icon mr-16')"></span>
        <input v-model="phone" v-bind="ariaInput" name="gsm" type="text" class="form-control" placeholder="Jouw GSM nummer ..." />
      </validation-provider>

      <button type="submit" class="btn" :class="{ 'is-disabled': !valid, 'has-success': success }" :disabled="!valid && !success">
        <span v-if="!success">Verstuur</span>
        <span v-else>Aanvraag verzonden</span>
        <el-loader class="ml-16" :loading="loading"></el-loader>
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },

  data: (vm) => ({
    phone: '',
    success: false,
    loading: false,
  }),

  methods: {
    onSubmit() {
      this.loading = true;
      this.success = false;
      setTimeout(() => {
        this.success = true;
        this.phone = '';
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
