<style lang="scss" scoped>
.cover-image {
  object-fit: cover;
  width: 600px;
}
</style>

<template>
  <div class="container-fluid print-layout estate-detail">
    <div class="row">
      <div class="container d-flex flex-column justify-content-between align-items-center py-80">
        <div class="row py-40">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="/images/logo_white.svg" alt="logo-cover-image" />
          </div>
        </div>
        <div class="row py-40">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <img :src="property.pictures[0].urlXXL" alt="cover-image" class="cover-image" />
          </div>
        </div>
        <div class="row py-40">
          <div class="col-auto border-right-lg border-dark">
            <h1 class="h4">{{ property.name }}</h1>
          </div>
          <div v-if="[1, 2].includes(property.purposeStatus.id) && property.price" class="col-auto text-center">
            <h4 class="price mb-8 text-primary" v-html="formatPrice(property.price, 'EUR')"></h4>
          </div>
        </div>
        <div class="row py-40 d-flex justify-content-center align-items-center flex-row">
          <div v-if="property.type" class="col-auto border-right border-dark d-flex justify-content-center align-items-center">
            <p class="icon-with-text">
              {{ __('plots.' + property.type) }}
            </p>
          </div>
          <div v-if="property.sizes_plot_area_size" class="col-auto border-right border-dark d-flex justify-content-center align-items-center">
            <p class="icon-with-text">
              <svg class="mr-8" fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="30" height="30">
                <path fill="none" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M14,76V52l20-30l28,14 l24-22v62" />
                <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M86,36v50H14V66l20-16l28,4L86,36z" />
                <g>
                  <line x1="81.5" x2="82.5" y1="50.228" y2="49.511" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" />
                  <line x1="76.5" x2="78.5" y1="53.815" y2="52.38" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" />
                  <line x1="67.5" x2="73.5" y1="60.272" y2="55.967" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" />
                  <polyline fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" points="22.435,69 35,59.5 63,63.5 64.5,62.424" />
                </g>
              </svg>
              {{ property.sizes_plot_area_size }} m2
            </p>
          </div>
          <div v-if="property.sizes_liveable_area_size" class="col-auto border-right border-dark d-flex justify-content-center align-items-center">
            <p class="icon-with-text">
              <svg class="mr-8" width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.44043 7.74023L9.44043 0.740234L18.4404 7.74023V18.7402C18.4404 19.2707 18.2297 19.7794 17.8546 20.1544C17.4796 20.5295 16.9709 20.7402 16.4404 20.7402H2.44043C1.91 20.7402 1.40129 20.5295 1.02622 20.1544C0.651143 19.7794 0.44043 19.2707 0.44043 18.7402V7.74023Z"
                  stroke="black"
                  stroke-width="0.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M3.44043 20.7402V10.7402H9.44043V20.7402" stroke="black" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13 15C13 13.4379 13 11 13 11H16V15" stroke="black" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              {{ property.sizes_liveable_area_size }} m2
            </p>
          </div>
          <div v-if="property.bedrooms != NULL" class="col-auto border-right border-dark">
            <p class="icon-with-text">
              <svg
                class="mr-8"
                width="19"
                height="22"
                viewBox="0 0 30 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.41421"
              >
                <g transform="matrix(1,0,0,1,-250,-584.999)">
                  <path
                    id="Forma-1"
                    serif:id="Forma 1"
                    d="M276.177,592.364L276.177,592.334L276.177,587.795C276.177,585.751 274.689,584.999 273.293,584.999L255.614,584.999C254.218,584.999 252.729,585.72 252.729,587.795L252.729,592.364C251.334,592.515 250,593.417 250,595.491L250,599.039C250,599.64 250.496,600.121 251.116,600.121L251.489,600.121L251.489,602.916C251.489,603.518 251.985,603.999 252.605,603.999L253.66,603.999C254.28,603.999 254.776,603.518 254.776,602.916L254.776,600.09L274.223,600.09L274.223,602.916C274.223,603.518 274.72,603.999 275.34,603.999L276.395,603.999C277.015,603.999 277.511,603.518 277.511,602.916L277.511,600.09L277.883,600.09C278.504,600.09 279,599.61 279,599.008L279,595.491C278.907,593.417 277.573,592.515 276.177,592.364ZM253.784,587.795L253.846,587.795C253.846,586.382 254.807,586.081 255.614,586.081L273.293,586.081C274.099,586.081 275.061,586.382 275.061,587.795L275.061,592.334L274.223,592.334L274.223,589.749C274.223,589.148 273.727,588.667 273.107,588.667L266.283,588.667C265.663,588.667 265.167,589.148 265.167,589.749L265.167,592.334L263.864,592.334L263.864,589.749C263.864,589.148 263.368,588.667 262.747,588.667L255.924,588.667C255.304,588.667 254.807,589.148 254.807,589.749L254.807,592.334L253.784,592.334L253.784,587.795ZM273.107,589.749L273.107,592.334L266.283,592.334L266.283,589.749L273.107,589.749ZM262.809,589.749L262.809,592.334L255.986,592.334L255.986,589.749L262.809,589.749ZM253.629,602.916L252.574,602.916L252.574,600.09L253.629,600.09L253.629,602.916ZM276.332,602.916L275.278,602.916L275.278,600.09L276.332,600.09L276.332,602.916ZM277.79,599.039L276.332,599.039L275.278,599.039L253.629,599.039L252.574,599.039L251.085,599.039L251.085,597.445L277.79,597.445L277.79,599.039ZM277.79,596.363L251.085,596.363L251.085,595.491C251.085,593.687 252.45,593.417 253.225,593.417L275.65,593.417C276.457,593.417 277.79,593.687 277.79,595.491L277.79,596.363Z"
                    style="fill: white; fill-rule: nonzero"
                  ></path>
                </g>
              </svg>
              {{ property.bedrooms }}
            </p>
          </div>
          <div class="col-auto mt-2 mt-lg-0">
            <p class="icon-with-text text-primary">
              {{ property.street }}
              {{ property.number }},
              {{ property.postal_code }}
              {{ property.city }}
            </p>
          </div>
        </div>
      </div>
      <div class="container d-flex flex-column justify-content-between align-items-center py-12">
        <div class="row mb-40 mb-lg-80 estate-detail">
          <div class="col-12">
            <h4 class="info-title">Details van deze woning</h4>
          </div>
          <div v-if="property.description" class="col-12">
            <div class="rich-text" v-html="property.description"></div>
          </div>
        </div>
      </div>
      <div class="container py-24">
        <div class="row">
          <div class="col-12">
            <h4 class="detail-title mb-24 mb-lg-40 text-start">Prijs & Staat</h4>
          </div>
        </div>
        <div class="row" v-if="property.status !== 'sold' && property.status !== 'rented' && property.price_amount && property.price_hidden === '0'">
          <div class="col-6 col-lg-3 info-block mb-24 mb-lg-0">
            <p class="info-title">Vraagprijs</p>
            <h5 class="info-description" v-html="formatPrice(property.price_amount, property.price_currency)"></h5>
          </div>

          <div v-if="property.status === 'sold'" class="col-6 col-lg-3 info-block">
            <p class="info-title">Status</p>
            <h5 class="info-description">Verkocht</h5>
          </div>

          <div v-if="property.status === 'rented'" class="col-6 col-lg-3 info-block">
            <p class="info-title">Status</p>
            <h5 class="info-description">Verhuurd</h5>
          </div>

          <div v-if="property.legal_property_and_land_cadastral_income !== null" class="col-6 col-lg-3 info-block">
            <p class="info-title">kadastraal inkomen</p>
            <h5 class="info-description" v-html="formatPrice(property.legal_property_and_land_cadastral_income, property.price_currency)"></h5>
          </div>
        </div>
        <div class="row">
          <div v-if="property.general_condition !== null" class="col-6 col-lg-3 info-block">
            <p class="info-title">Staat</p>

            <h5 class="info-description" v-if="property.general_condition === 'poor'">Renoveren</h5>
            <h5 class="info-description" v-if="property.general_condition === 'fair'">Matig</h5>
            <h5 class="info-description" v-if="property.general_condition === 'good'">Goed</h5>
            <h5 class="info-description" v-if="property.general_condition === 'mint'">Uitstekend</h5>
            <h5 class="info-description" v-if="property.general_condition === 'new'">Nieuw</h5>
          </div>

          <div class="col-6 col-lg-3 info-block" v-if="property.building_construction_year">
            <p class="info-title">Bouwjaar</p>
            <h5 class="info-description">{{ property.building_construction_year }}</h5>
          </div>

          <div class="col-6 col-lg-3 info-block" v-if="property.building_renovation_year">
            <p class="info-title">Renovatiejaar</p>
            <h5 class="info-description">{{ property.building_renovation_year }}</h5>
          </div>
        </div>
      </div>
      <div class="container-fluid py-24">
        <div class="row">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h4 class="detail-title mb-40">Kenmerken</h4>
              </div>
            </div>
            <div class="row mb-n16">
              <div v-if="property.amenities" v-for="amenity in property.amenities" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">{{ __('amenities.' + amenity.title) }}</p>
              </div>

              <div v-if="property.features_comfort_home_automation" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Domotica</p>
              </div>

              <div v-if="property.features_comfort_water_softener" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Waterverzachter</p>
              </div>

              <div v-if="property.features_comfort_fireplace" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Open haard</p>
              </div>

              <div v-if="property.features_comfort_walk_in_closet" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Inloopkast</p>
              </div>

              <div v-if="property.features_comfort_home_cinema" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Thuisbioscoop</p>
              </div>

              <div v-if="property.features_comfort_wine_cellar" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Wijnkelder</p>
              </div>

              <div v-if="property.features_comfort_sauna" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Sauna</p>
              </div>

              <div v-if="property.features_comfort_fitness_room" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Fitness Kamer</p>
              </div>

              <div v-if="property.features_comfort_furnished" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Gemeubileerd</p>
              </div>

              <div v-if="property.features_ecology_solar_panels" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Zonnepanelen</p>
              </div>

              <div v-if="property.features_ecology_solar_boiler" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Zonneboiler</p>
              </div>

              <div v-if="property.features_ecology_rainwater_harvesting" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Regenwateropvang</p>
              </div>

              <div v-if="property.features_ecology_insulated_roof" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Geïsoleerd dak</p>
              </div>

              <div v-if="property.features_heating_cooling_central_heating" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Centrale verwarming</p>
              </div>

              <div v-if="property.features_heating_cooling_floor_heating" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Vloerverwarming</p>
              </div>

              <div v-if="property.features_heating_cooling_air_conditioning" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Airconditioning</p>
              </div>

              <div v-if="property.features_security_alarm" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Beveiligings alarm</p>
              </div>

              <div v-if="property.features_security_concierge" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Conciërge</p>
              </div>

              <div v-if="property.features_security_video_surveillance" class="col-6 col-lg-3 d-flex align-items-center mb-16">
                <span class="text-primary mr-8 font-weight-bold">+</span>
                <p class="mb-0 specification-item">Camera bewaking</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-24">
        <div class="row">
          <div class="col-12">
            <h4 class="detail-title mb-40">Oppervlaktes</h4>
          </div>
        </div>
        <div class="row rooms">
          <div v-if="property.sizes_liveable_area_size" class="col-12 col-lg-4">
            <div class="room d-flex flex-row justify-content-between">
              <p class="mb-0 room-title">Bewoonbare oppervlakte</p>
              <p class="room-size mb-0">{{ Math.round(property.sizes_liveable_area_size, 2) }} {{ __('plots.' + property.sizes_liveable_area_unit) }}</p>
            </div>
          </div>

          <div if="property.sizes_plot_area_size" class="col-12 col-lg-4">
            <div class="room d-flex flex-row justify-content-between">
              <p class="mb-0 room-title">Perceeloppervlakte</p>

              <p v-if="property.sizes_plot_area_unit == 'are'" class="room-size mb-0">{{ Math.round(property.sizes_plot_area_size * 100, 2) }} <span v-html="__('plots.sq_m')"></span></p>

              <p v-else class="room-size mb-0">{{ Math.round(property.sizes_plot_area_size, 2) }} {{ <span v-html="__('plots.' + property.sizes_plot_area_unit)"></span></p>
            </div>
          </div>
          <div v-for="room in property.property_rooms" class="col-12 col-lg-4">
            <div class="room d-flex flex-row justify-content-between">
              <p class="mb-0 room-title" v-if="room['type'] === 'custom'">
                {{ room['size_description'] }}
              </p>
              <p class="mb-0 room-title" v-else>{{ __('rooms.' + room['type']) }}</p>
              <p class="room-size mb-0">
                {{ room['size'] }}
                {{ __('plots.' + room['unit']) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid py-24 energy bg-white">
        <div class="row">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-8 mb-40">
                <h4 class="detail-title mb-40">Energie</h4>
                <div class="row mb-n24">
                  <div v-if="property.legal_energy_epc_value != NULL" class="col-6 info-block mb-24">
                    <p class="info-title">EPC waarde</p>
                    <h5 class="info-description">{{ property.legal_energy_epc_value }} kWh/m<sup>2</sup></h5>
                  </div>

                  <div v-if="property.legal_energy_total_epc_value != NULL" class="col-6 info-block mb-24">
                    <p class="info-title">Totaal EPC waarde</p>
                    <h5 class="info-description">{{ property.legal_energy_total_epc_value }} kWh</h5>
                  </div>

                  <div v-if="property.legal_energy_e_level != NULL" class="col-6 info-block mb-24">
                    <p class="info-title">E-level</p>
                    <h5 class="info-description">{{ property.legal_energy_e_level }}</h5>
                  </div>

                  <div v-if="property.legal_energy_epc_reference !== null" class="col-6 info-block mb-24">
                    <p class="info-title">Referentienummer EPC-rapport</p>
                    <h5 class="info-description">{{ property.legal_energy_epc_reference }}</h5>
                  </div>

                  <div v-if="property.legal_energy_epc_category !== null" class="col-6 info-block mb-24">
                    <p class="info-title">EPC-niveau</p>
                    <h5 class="info-description">{{ property.legal_energy_epc_category }}</h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <h4 class="detail-title mb-40">Kenmerken</h4>
                <div class="row energy-specifications">
                  <div v-if="property.features_energy_gas == true" class="col-12">
                    <div class="specification d-flex flex-row justify-content-between">
                      <p class="mb-0 specification-title">Aardgas</p>
                      <p class="specification-value mb-0">Ja</p>
                    </div>
                  </div>

                  <div v-if="property.features_energy_heat_pump == true" class="col-12">
                    <div class="specification d-flex flex-row justify-content-between">
                      <p class="mb-0 specification-title">Centrale verwarming</p>
                      <p class="specification-value mb-0">Ja</p>
                    </div>
                  </div>

                  <div v-if="property.features_energy_electricity == true" class="col-12">
                    <div class="specification d-flex flex-row justify-content-between">
                      <p class="mb-0 specification-title">Elektriciteit</p>
                      <p class="specification-value mb-0">Ja</p>
                    </div>
                  </div>

                  <div v-if="property.features_ecology_double_glazing == true" class="col-12">
                    <div class="specification d-flex flex-row justify-content-between">
                      <p class="mb-0 specification-title">Dubbel Glas</p>
                      <p class="specification-value mb-0">Ja</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-24">
        <div class="row">
          <div class="col-12 col-lg-7 mb-80 mb-lg-0">
            <div class="row mb-40" v-if="property.price_costs !== null">
              <div class="col-12">
                <h4 class="detail-title mb-40">Kosten & Taxen</h4>
                <p class="mb-0">{{ property.price_costs }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <h4 class="detail-title mb-40">Stedenbouwkundige & juridische vermeldingen</h4>
              </div>
            </div>
            <div class="row legal-specifications">
              <div class="col-12">
                <div v-if="property.legal_regulations_building_permit" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.building_permit') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.building_permit') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_subdivision_authorisation" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.subdivision_authorisation') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.subdivision_authorisation') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_priority_purchase_right" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.priority_purchase_right') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.priority_purchase_right') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_urban_planning_breach" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.urban_planning_breach') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.urban_planning_breach') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_as_built_report" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.as_built_report') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.as_built_report') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_expropriation_plan" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.expropriation_plan') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.expropriation_plan') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_heritage_list" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.heritage_list') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.heritage_list') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_pending_legal_proceedings" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.pending_legal_proceedings') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.pending_legal_proceedings') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_registered_building" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.registered_building') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.registered_building') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_site_untapped_activity" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.site_untapped_activity') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.site_untapped_activity') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_regulations_urban_planning_certificate" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.urban_planning_certificate') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.urban_planning_certificate') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div class="col-12">
                <div v-if="property.legal_property_and_land_flood_risk" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.legal_property_and_land_flood_risk') }}?</p>
                  <p class="legal-value mb-0">Ja</p>
                </div>
                <div v-else class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ __('regulations.legal_property_and_land_flood_risk') }}?</p>
                  <p class="legal-value mb-0">Neen</p>
                </div>
              </div>

              <div v-for="mention in legalMentions()" class="col-12" v-if="property.legal_legal_mentions != null">
                <div v-if="mention != ''" class="legal d-flex flex-row justify-content-between">
                  <p class="mb-0 legal-title">{{ legalMentionQuestion(mention)[0] }}?</p>
                  <p class="legal-value mb-0">{{ legalMentionQuestion(mention)[1] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    property: {
      type: Object,
    },
  },
  methods: {
    legalMentions() {
      return this.property.legal_legal_mentions.split('*');
    },
    legalMentionQuestion(mention) {
      return mention.split('?');
    },
  },
};
</script>
