<style lang="scss" scoped>
.form-select {
  height: 60px;

  ::v-deep .vs__dropdown-toggle {
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    letter-spacing: 0;
    padding: 15px 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #272b32;

    input {
      &::placeholder {
        color: #9a9a9a;
        opacity: 1;
      }
    }
  }
}

::v-deep .vue-tags-input {
  max-width: unset;

  .ti-input {
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    letter-spacing: 0;
    padding: 15px 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #272b32;

    input &::placeholder {
      color: #9a9a9a;
      opacity: 1;
    }
  }

  .ti-tag {
    background: #83c056;
  }
}
</style>

<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form v-if="!success" @submit.prevent="handleSubmit(verifyAndSubmit)">
      <div class="row">

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Type</label>
          <v-select v-model="data.type" v-bind="ariaInput" class="form-select mb-0" placeholder="Type" :options="typeOptions"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Kopen of Huren</label>
          <v-select v-model="data.sale_or_let" v-bind="ariaInput" class="form-select mb-0" :clearable="false" :options="saleOrLetOptions"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-gridh">
          <label for="" class="mb-16">* Postcode(s)</label>
          <input type="hidden" autocomplete="off" name="zipcodes" v-model="zipcodes" />
          <vue-tags-input ref="tagput" v-model="zipcode" v-bind="ariaInput" :validation="zipcodeValidation" autocomplete="off" :add-on-key="[13, ',']" :tags="zipcodes" @tags-changed="(newRegion) => (zipcodes = newRegion)" placeholder="Vul één of meerdere postcodes in gescheiden door een komma." />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Minimum budget</label>
          <v-select v-model="data.price_min" v-bind="ariaInput" class="form-select mb-0" placeholder="min budget" :options="budgetMinOptions"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Maximum budget</label>
          <v-select v-model="data.price_max" v-bind="ariaInput" class="form-select mb-0" placeholder="max budget" :options="budgetMaxOptions"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Minimum staat</label>
          <v-select v-model="data.state" v-bind="ariaInput" class="form-select mb-0" placeholder="Staat²" :options="stateOptions"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Minimum aantal slaapkamers</label>
          <v-select v-model="data.bedrooms" v-bind="ariaInput" class="form-select mb-0" :options="bedroomOptions" placeholder="Slaapkamers"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Minimum bewoonbare oppervlakte</label>
          <v-select v-model="data.area_min" v-bind="ariaInput" class="form-select mb-0" :options="areaminOtions" placeholder="min m²"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Maximum bewoonbare oppervlakte</label>
          <v-select v-model="data.area_max" v-bind="ariaInput" class="form-select mb-0" :options="areamaxOtions" placeholder="max m²"></v-select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12 my-gridh">
          <strong>Contactgegevens</strong>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Voornaam</label>
          <input v-model="data.first_name" v-bind="ariaInput" name="Voornaam" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Achternaam</label>
          <input v-model="data.last_name" v-bind="ariaInput" name="Achternaam" type="text" label="Achternaam" class="form-control mb-0" placeholder="Achternaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Emailadres</label>
          <input v-model="data.email" v-bind="ariaInput" name="Emailadres" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Telefoonnummer</label>
          <input v-model="data.phone" v-bind="ariaInput" name="Telefoonnummer" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12 mb-gridh">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" required="required" type="checkbox" value="" id="checkakkoord" />
            <label class="custom-control-label" for="checkakkoord">Ik ga akkoord met het <a href="/privacy-policy">privacybeleid</a> van Copandi </label>
          </div>
        </div>

        <div class="col-12">
          <button type="submit" class="btn d-flex align-items-center justify-content-center btn-primary">
            Verstuur
            <el-loader class="ml-16" :loading="loading"></el-loader>
          </button>
        </div>
      </div>
    </form>
    <div v-else class="row">
      <div class="col-12 col-lg-10">
        <p class="alert alert-success">Bedankt, je bent opgenomen in onze mailinglijst!</p>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

import VueTagsInput from '@johmun/vue-tags-input';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
    VueTagsInput,
  },

  props: {
    env: {
      required: true,
    },
  },
  data() {
    return {
      zipcode: '',
      zipcodes: [],
      zipcodeValidation: [
        {
          classes: 'error',
          rule: /^[0-9]*$/,
          disableAdd: true,
        },
      ],
      saleOrLetOptions: [
        { label: 'Te koop', value: 1 },
        { label: 'Te huur', value: 2 },
      ],
      typeOptions: [
        { label: 'Huis', value: 1 },
        { label: 'Appartement', value: 2 },
        { label: 'Bouwgrond', value: 3 },
        { label: 'Kantoor', value: 4 },
        { label: 'Commercieel', value: 5 },
        { label: 'Parking', value: 7 },
      ],
      stateOptions: [
        { label: 'Nieuw', value: 1 },
        { label: 'Uitstekend', value: 2 },
        { label: 'Gerenoveerd', value: 3 },
        { label: 'Goed', value: 4 },
        { label: 'Matig', value: 5 },
        { label: 'Renoveren', value: 6 },
      ],
      bedroomOptions: [
        { label: '1 slaapkamer', value: 1 },
        { label: '2 slaapkamers', value: 2 },
        { label: '3 slaapkamers', value: 3 },
        { label: '4 slaapkamers', value: 4 },
        { label: '5 slaapkamers', value: 5 },
      ],
      areaminOtions: [
        { label: '0 m²', value: 0 },
        { label: '100 m²', value: 100 },
        { label: '200 m²', value: 200 },
        { label: '400 m²', value: 300 },
        { label: '500 m²', value: 500 },
      ],
      areamaxOtions: [
        { label: '200 m²', value: 200 },
        { label: '300 m²', value: 300 },
        { label: '400 m²', value: 400 },
        { label: '500 m²', value: 500 },
        { label: '>500 m²', value: 9999 },
      ],
      data: {
        sale_or_let: { label: 'Te koop', value: 1 },
        state: '',
        ziplist: [],
        price_min: '',
        price_max: '',
        bedrooms: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        type: '',
        area_min: '',
        area_max: '',
      },
      success: false,
      loading: false,
    };
  },

  computed: {
    budgetMinOptions() {
      if (this.data.sale_or_let.value === 1) {
        return [
          { label: '50 000 EUR', value: 50000 },
          { label: '100 000 EUR', value: 100000 },
          { label: '200 000 EUR', value: 200000 },
          { label: '300 000 EUR', value: 300000 },
          { label: '400 000 EUR', value: 400000 },
          { label: '500 000 EUR', value: 500000 },
        ];
      }

      return [
        { label: '0 EUR', value: 1 },
        { label: '250 EUR', value: 250 },
        { label: '500 EUR', value: 500 },
        { label: '750 EUR', value: 750 },
        { label: '1000 EUR', value: 1000 },
        { label: '1500 EUR', value: 1500 },
        { label: '1750 EUR', value: 1750 },
        { label: '2000 EUR', value: 2000 },
        { label: '2250 EUR', value: 2250 },
        { label: '2500 EUR', value: 2500 },
        { label: '3000 EUR', value: 3000 },
      ];
    },
    
    budgetMaxOptions() {
      if (this.data.sale_or_let.value === 1) {
        return [
          { label: '50 000 EUR', value: 50000 },
          { label: '100 000 EUR', value: 100000 },
          { label: '200 000 EUR', value: 200000 },
          { label: '300 000 EUR', value: 300000 },
          { label: '400 000 EUR', value: 400000 },
          { label: '500 000 EUR', value: 500000 },
          { label: '>500 000 EUR', value: 9999999 },
        ];
      }
      return [
        { label: '250 EUR', value: 250 },
        { label: '500 EUR', value: 500 },
        { label: '750 EUR', value: 750 },
        { label: '1000 EUR', value: 1000 },
        { label: '1500 EUR', value: 1500 },
        { label: '1750 EUR', value: 1750 },
        { label: '2000 EUR', value: 2000 },
        { label: '2250 EUR', value: 2250 },
        { label: '2500 EUR', value: 2500 },
        { label: '>3000 EUR', value: 9999 },
      ];
    },
  },

  methods: {
    onSubmit() {
      let data = { ...this.data };
      this.loading = true;
      this.zipcodes.forEach((zipcode) => {
        if (!data.ziplist.includes(zipcode.text)) {
          data.ziplist.push(`${zipcode.text}`);
        }
      });
      data.sale_or_let = this.data.sale_or_let.value;
      data.bedrooms = this.data.bedrooms.value;
      data.price_min = this.data.price_min.value;
      data.price_max = this.data.price_max.value;
      data.area_min = this.data.area_min.value;
      data.area_max = this.data.area_max.value;
      data.state = this.data.state.value;
      data.type = this.data.type.value;

      axios
        .post('/api/v2/keep-me-posted', data)
        .then((res) => {
          this.success = true;
          this.loading = false;
          this.data = {
            sale_or_let: { label: 'Te koop', value: 1 },
            state: '',
            ziplist: [],
            price_min: '',
            price_max: '',
            bedrooms: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            type: '',
            area_min: '',
            area_max: '',
          };
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    async verifyAndSubmit() {
      if (this.env === 'local') {
        this.onSubmit();
        return;
      }
      const recaptchaLoaded = await this.$recaptchaLoaded();

      if (!recaptchaLoaded || recaptchaLoaded.success === false) {
        return;
      }

      const token = await this.$recaptcha('login');

      if (token) {
        this.loading = true;
        axios
          .post('/api/verify/token', { token: token })
          .then((res) => {
            if (res.data && res.data.success && res.data.score >= 0.5) {
              this.onSubmit();
            } else {
              this.success = false;
              this.loading = false;
            }
          })
          .catch(() => {
            this.success = false;
            this.loading = false;
          });
      } else {
        console.error(token);
      }
    },
  },
};
</script>
