export default {
    props: {
        data: {
            default: null,
        },
        wrapperClass: {
            default: 'h-100',
        },
    },

    computed: {
        imageStyle() {
            console.log(this.data.pictures[0]);
            if (this.data && this.data.pictures && this.data.pictures[0]) {
                return `${this.data.pictures[0].urlLarge} 1x`
            };

            return `/images/default-project-image.png 1x`;
        },
        computedRoute() {
            let slug = this.slugify(`${this.data.id}-${this.data.city}-${this.data.name}`);

            if (this.data.status.id === 3 || this.data.status.id === 4 || this.data.status.id === 17) return 'javascript:void(0)';
            if (this.propertySpecialType === 'gold') return this.route(`gold.resultaten.show`, { slug });
            if (this.data.is_project) return this.route(`${window.location.host.indexOf('gold') >= 0 ? 'gold.' : ''}nieuwbouw.show`, { slug });
            if (this.data.status.id === 1) return this.route(`${window.location.host.indexOf('gold') >= 0 ? 'gold.' : ''}resultaten.show`, { slug });
            if (this.data.negotiation === 2) return this.route(`${window.location.host.indexOf('gold') >= 0 ? 'gold.' : ''}resultaten.show`, { slug });
        },
        isViewDay() {
            if (this.data.open_homes_start_date) {
                let home_start = this.$moment(this.data.open_homes_start_date).format('dddd DD MMMM');
                return `Kijkdag ${home_start}`;
            }

            return false;
        },
        propertySpecialType() {
            return this.getPropertySpecialType(this.data);
        },
        epcClass() {
            //details id: 2056

            if (this.data.details && this.data.details.length) {
                let epc = this.data.details.find(detail => detail.id === 2056);
                if (epc) {
                    return epc.value;
                }
            }

            return false;
        }
    },

    methods: {
        slugify(text) {
            if (!text) {
                return '';
            }
            return text
                .normalize('NFKD')
                .toLowerCase()
                .replace(/[^\w\s-]/g, '')
                .trim()
                .replace(/[-\s]+/g, '-');
        },
        getPropertySpecialType(estate) {
            if ([4975913, 5608066, 5216356, 4972321, 5442352, 5454921, 5572777, 5313349, 5304076, 5561224, 5327820, 5584417, 5584224, 5329150, 5380814, 5359481, 5384759, 5568145, 5395717, 5553144, 5537359, 5501578, 5568431, 5568128, 5587690, 5512518, 5407686, 5548264, 5568998, 5599613, 5627762, 5702769, 5640976, 5631584, 5706030, 5707034].includes(estate.id)) {
                return 'gold';
            }

            if (estate.displayStatusId !== 3) {
                return false;
            }

            if (estate.investmentEstate) {
                return 'opbrengsteigendom';
            }
            if (estate.subCategory.id === 2) {
                return 'exclusief-vastgoed';
            }
            if (estate.subCategory.id === 42) {
                return 'handelspand';
            }
            if (estate.subCategory.id === 26) {
                return 'bouwgrond';
            }
            if (estate.state && (estate.state.id === 5 || estate.state.id === 6)) {
                return 'renovatie-eigendom';
            }

            return '';
        },
    }
};
