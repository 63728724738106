var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container pt-24" },
    [
      _c("div", { staticClass: "row", attrs: { id: "test" } }, [
        _c(
          "div",
          {
            staticClass:
              "col-12 d-flex justify-content-end align-items-center px-0",
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "d-flex flex-row text-dark pointer align-items-center",
                on: { click: _vm.downloadPdf },
              },
              [
                _c("p", { staticClass: "mr-2 mb-0" }, [_vm._v("Print pagina")]),
                _vm._v(" "),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.svg("print", "icon")) },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("print-layout", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { property: _vm.property, id: "element-to-print" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }