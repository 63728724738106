var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(_vm.projecten.slice(0, 2), function (project, index) {
                return _c("project-card", {
                  key: project.id,
                  attrs: {
                    project: project,
                    direction: index % 2 === 0 ? "left" : "right",
                  },
                })
              }),
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-fluid px-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 mb-80" }, [_c("project-cta")], 1),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(
                _vm.projecten.slice(2, _vm.projecten.length),
                function (project, index) {
                  return _c("project-card", {
                    key: project.id,
                    attrs: {
                      project: project,
                      direction: index % 2 === 0 ? "left" : "right",
                    },
                  })
                }
              ),
              1
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "col-12" }, [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-50 mx-auto text-center" }, [
      _c("div", { staticClass: "lds-ellipsis" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }