var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", [
      _c("a", {
        attrs: { href: "/" },
        domProps: { innerHTML: _vm._s(_vm.svg("logo", "logo")) },
      }),
      _vm._v(" "),
      _c("a", { staticClass: "close-link", attrs: { href: "/" } }, [
        _vm._v("\n      Sluiten\n      "),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.svg("cross", "close")) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("main", [
      _c("aside", { staticClass: "sidebar" }, [
        _c("h1", { staticClass: "side-title" }, [
          _vm._v("Een kennismaking inplannen met uw lokale Copandi makelaar?"),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "side-group-title" }, [_vm._v("overzicht")]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.location.address.street !== "",
                expression: "data.location.address.street !== ''",
              },
            ],
            staticClass: "side-block",
          },
          [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.svg("marker-hollow", "side-icon")),
              },
            }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.data.location.address.street) +
                " " +
                _vm._s(_vm.data.location.address.houseNumber) +
                ", " +
                _vm._s(_vm.data.location.address.postCode) +
                " " +
                _vm._s(_vm.data.location.address.city) +
                "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.data.propertyType.code !== "",
                expression: "data.propertyType.code !== ''",
              },
            ],
            staticClass: "side-block",
          },
          [
            _vm.data.propertyType.code === "house"
              ? _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("house-outline", "side-icon stroke")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.data.propertyType.code === "apartment"
              ? _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("appartment-outline", "side-icon fill")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.data.propertyType.code === "ground"
              ? _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("ground-outline", "side-icon fill")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.data.propertyType.code === "commercial"
              ? _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("commercial-outline", "side-icon fill")
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(
              "\n        " +
                _vm._s(_vm.humanized.propertyType[_vm.data.propertyType.code]) +
                " "
            ),
            _vm.data.propertyType.subcode !== ""
              ? [
                  _vm._v(
                    " - " +
                      _vm._s(
                        _vm.humanized.propertyType[
                          _vm.data.propertyType.subcode
                        ]
                      )
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "side-block align-items-start" }, [
          _vm.data.numberOfBedrooms !== undefined ||
          _vm.data.numberOfBathrooms !== undefined
            ? _c("span", {
                domProps: { innerHTML: _vm._s(_vm.svg("list", "side-icon")) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _vm.data.numberOfBedrooms !== undefined
              ? _c("span", [
                  _vm._v(_vm._s(_vm.data.numberOfBedrooms) + " slaapkamer(s)"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.numberOfBathrooms !== undefined
              ? _c("span", [
                  _vm._v(_vm._s(_vm.data.numberOfBathrooms) + " badkamer(s)"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.propertyType.code === "Appartement" &&
            _vm.data.numberOfFloorsInBuilding !== undefined
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.data.numberOfFloorsInBuilding) + " verdiepingen"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.propertyType.code === "Appartement" &&
            _vm.data.floorNumber !== undefined
              ? _c("span", [
                  _vm._v("Verdieping " + _vm._s(_vm.data.floorNumber)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.livingArea
              ? _c("span", [
                  _vm._v(_vm._s(_vm.data.livingArea) + "m² woonoppervlakte"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.landArea
              ? _c("span", [
                  _vm._v(_vm._s(_vm.data.landArea) + "m² grondoppervlakte"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.buildingYear
              ? _c("span", [
                  _vm._v("bouwjaar " + _vm._s(_vm.data.buildingYear)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.has_garden && _vm.data.gardenArea
              ? _c("span", [_vm._v(_vm._s(_vm.data.gardenArea) + "m² tuin")])
              : _vm._e(),
            _vm._v(" "),
            _vm.has_balcony && _vm.data.balconyArea
              ? _c("span", [
                  _vm._v(_vm._s(_vm.data.balconyArea) + "m² balkon/terras"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.hasLift ? _c("span", [_vm._v("Lift aanwezig")]) : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.data.quality && _vm.data.condition
          ? _c("div", { staticClass: "side-block align-items-start" }, [
              (_vm.data.quality &&
                _vm.data.quality.kitchen &&
                _vm.data.condition.kitchen) ||
              (_vm.data.quality &&
                _vm.data.quality.bathrooms &&
                _vm.data.condition.bathrooms) ||
              (_vm.data.quality &&
                _vm.data.quality.flooring &&
                _vm.data.condition.flooring) ||
              (_vm.data.quality &&
                _vm.data.quality.windows &&
                _vm.data.condition.windows)
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.svg("badge", "side-icon")),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _vm.data.quality.kitchen && _vm.data.condition.kitchen
                  ? _c("span", [
                      _vm._v(
                        "Keuken — " +
                          _vm._s(
                            _vm.humanized.quality[_vm.data.quality.kitchen]
                          ) +
                          " en " +
                          _vm._s(
                            _vm.humanized.condition[_vm.data.condition.kitchen]
                          )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.quality.bathrooms && _vm.data.condition.bathrooms
                  ? _c("span", [
                      _vm._v(
                        "Badkamer — " +
                          _vm._s(
                            _vm.humanized.quality[_vm.data.quality.bathrooms]
                          ) +
                          " en " +
                          _vm._s(
                            _vm.humanized.condition[
                              _vm.data.condition.bathrooms
                            ]
                          )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.quality.flooring && _vm.data.condition.flooring
                  ? _c("span", [
                      _vm._v(
                        "Vloeren — " +
                          _vm._s(
                            _vm.humanized.quality[_vm.data.quality.flooring]
                          ) +
                          " en " +
                          _vm._s(
                            _vm.humanized.condition[_vm.data.condition.flooring]
                          )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.quality.windows && _vm.data.condition.windows
                  ? _c("span", [
                      _vm._v(
                        "Ramen — " +
                          _vm._s(
                            _vm.humanized.quality[_vm.data.quality.windows]
                          ) +
                          " en " +
                          _vm._s(
                            _vm.humanized.condition[_vm.data.condition.windows]
                          )
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c("div", { staticClass: "progress-wrapper" }, [
            _c("div", { staticClass: "progress-holder" }),
            _vm._v(" "),
            _c("div", {
              staticClass: "progress-bar",
              style: {
                width: (_vm.step / _vm.totalSteps) * 100 + "%",
                maxWidth: "100%",
              },
            }),
          ]),
          _vm._v(" "),
          !_vm.success && _vm.step !== 8
            ? _c("ValidationObserver", {
                ref: "form",
                staticClass: "content-wrapper",
                class: { "calendly-step": _vm.step === 8 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var passes = ref.passes
                        return [
                          !_vm.isFirstStep
                            ? _c(
                                "button",
                                {
                                  staticClass: "prev-button",
                                  attrs: { type: "button" },
                                  on: { click: _vm.prevStep },
                                },
                                [_vm._v("Vorige")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _vm.step === 1
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step",
                                      attrs: { id: "step-1" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v("Welke schatting verkies je?"),
                                      ]),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "mb-80 d-block",
                                        attrs: {
                                          persist: "",
                                          rules: "required",
                                          bails: false,
                                          name: "Type schatting",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "el-estimation-type-select",
                                                    _vm._b(
                                                      {
                                                        class: {
                                                          "has-errors":
                                                            errors.length,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.estimation_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.estimation_type =
                                                              $$v
                                                          },
                                                          expression:
                                                            "estimation_type",
                                                        },
                                                      },
                                                      "el-estimation-type-select",
                                                      ariaInput,
                                                      false
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  errors.length
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error-message",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.step === 2
                                ? _c(
                                    "section",
                                    {
                                      staticClass:
                                        "price-hubble-step d-flex flex-column flex-lg-row gap-40 h-100",
                                      attrs: { id: "step-2" },
                                    },
                                    [
                                      _c("div", { staticClass: "w-lg-50" }, [
                                        _c(
                                          "h2",
                                          { staticClass: "step-title" },
                                          [
                                            _vm._v(
                                              "Wat is het adres van de te schatten woning?"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "step-description" },
                                          [
                                            _vm._v(
                                              "Om een locatie te selecteren, kunt u ook de pin op de kaart verplaatsen."
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "step-label",
                                            attrs: { for: "adres" },
                                          },
                                          [_vm._v("Adres")]
                                        ),
                                        _vm._v(" "),
                                        !_vm.data.location.address.street
                                          ? _c(
                                              "div",
                                              [
                                                _c("vue-google-autocomplete", {
                                                  attrs: {
                                                    id: "adres",
                                                    classname:
                                                      "step-form-control",
                                                    placeholder:
                                                      "Wat is het adres van de woning?",
                                                    type: "search",
                                                    autocomplete: "off",
                                                    country: "be",
                                                  },
                                                  on: {
                                                    placechanged:
                                                      _vm.getAddressData,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row mx-n8",
                                            class: {
                                              faded:
                                                !_vm.data.location.address
                                                  .street,
                                            },
                                          },
                                          [
                                            _c("ValidationProvider", {
                                              staticClass: "col-7 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .street,
                                                                  expression:
                                                                    "data.location.address.street",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Straat",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .street,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "street",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "col-3 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .houseNumber,
                                                                  expression:
                                                                    "data.location.address.houseNumber",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Nummer",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .houseNumber,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "houseNumber",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "col-2 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .bus,
                                                                  expression:
                                                                    "data.location.address.bus",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Bus",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .bus,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "bus",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "col-4 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .floor,
                                                                  expression:
                                                                    "data.location.address.floor",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Verdiep",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .floor,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "floor",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "col-4 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .postCode,
                                                                  expression:
                                                                    "data.location.address.postCode",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Postcode",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .postCode,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "postCode",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "col-4 mb-16 px-8",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .location
                                                                      .address
                                                                      .city,
                                                                  expression:
                                                                    "data.location.address.city",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Stad",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data
                                                                    .location
                                                                    .address
                                                                    .city,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .location
                                                                        .address,
                                                                      "city",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "w-lg-50" },
                                        [
                                          _vm.coordinates
                                            ? _c("google-map", {
                                                ref: "googleMap",
                                                staticClass: "h-100",
                                                attrs: {
                                                  markers: [_vm.coordinates],
                                                  zoom: 18,
                                                  coordinates: _vm.coordinates,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm.step === 3
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step h-100",
                                      attrs: { id: "step-3" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v("Wat is het type van het pand?"),
                                      ]),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "mb-80 d-block",
                                        attrs: {
                                          persist: "",
                                          rules: "required",
                                          bails: false,
                                          name: "Type pand",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "el-estate-type-select",
                                                    _vm._b(
                                                      {
                                                        class: {
                                                          "has-errors":
                                                            errors.length,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.data.propertyType.subcode =
                                                              ""
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.data
                                                              .propertyType
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.data
                                                                .propertyType,
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.propertyType.code",
                                                        },
                                                      },
                                                      "el-estate-type-select",
                                                      ariaInput,
                                                      false
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  errors.length
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error-message",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.subTypes.length
                                        ? _c("ValidationProvider", {
                                            key:
                                              "subtypes_" +
                                              _vm.data.propertyType.subcode,
                                            attrs: {
                                              persist: "",
                                              rules: "required",
                                              bails: false,
                                              name: "Type " + _vm.data.type,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "mb-40",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Over welk subtype pand gaat het?"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-estate-sub-type-select",
                                                        _vm._b(
                                                          {
                                                            class: {
                                                              "has-errors":
                                                                errors.length,
                                                            },
                                                            attrs: {
                                                              items:
                                                                _vm.subTypes,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.data
                                                                  .propertyType
                                                                  .subcode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.data
                                                                      .propertyType,
                                                                    "subcode",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.propertyType.subcode",
                                                            },
                                                          },
                                                          "el-estate-sub-type-select",
                                                          ariaInput,
                                                          false
                                                        )
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm.step === 4
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step h-100",
                                      attrs: { id: "step-4" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v(
                                          "Kan je er iets meer over vertellen over het pand?"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "row mb-16",
                                        attrs: {
                                          persist: "",
                                          rules:
                                            "required|min_value:1|max_value:20",
                                          bails: false,
                                          name: "Aantal slaapkamers",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-3 mt-10 step-label",
                                                      attrs: {
                                                        for: "bedrooms",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Aantal slaapkamers"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-4",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "suffix",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.data.numberOfBedrooms =
                                                                      _vm.subtractOrDefault(
                                                                        _vm.data
                                                                          .numberOfBedrooms
                                                                      )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("-")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.data.numberOfBedrooms =
                                                                      _vm.addOrDefault(
                                                                        _vm.data
                                                                          .numberOfBedrooms
                                                                      )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "input",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.data
                                                                    .numberOfBedrooms,
                                                                expression:
                                                                  "data.numberOfBedrooms",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "step-form-control",
                                                            class: {
                                                              "has-errors":
                                                                errors.length,
                                                            },
                                                            attrs: {
                                                              id: "bedrooms",
                                                              min: "1",
                                                              max: "20",
                                                              type: "number",
                                                              placeholder:
                                                                "Aantal slaapkamers",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.data
                                                                  .numberOfBedrooms,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.data,
                                                                  "numberOfBedrooms",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "input",
                                                          ariaInput,
                                                          false
                                                        )
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "row mb-16",
                                        attrs: {
                                          persist: "",
                                          rules:
                                            "required|min_value:1|max_value:5",
                                          bails: false,
                                          name: "Aantal badkamers",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-3 mt-10 step-label",
                                                      attrs: {
                                                        for: "bathrooms",
                                                      },
                                                    },
                                                    [_vm._v("Aantal badkamers")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-4",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "suffix",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.data.numberOfBathrooms =
                                                                      _vm.subtractOrDefault(
                                                                        _vm.data
                                                                          .numberOfBathrooms
                                                                      )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("-")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.data.numberOfBathrooms =
                                                                      _vm.addOrDefault(
                                                                        _vm.data
                                                                          .numberOfBathrooms
                                                                      )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("+")]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "input",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.data
                                                                    .numberOfBathrooms,
                                                                expression:
                                                                  "data.numberOfBathrooms",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "step-form-control",
                                                            class: {
                                                              "has-errors":
                                                                errors.length,
                                                            },
                                                            attrs: {
                                                              id: "bathrooms",
                                                              min: "1",
                                                              max: "5",
                                                              type: "number",
                                                              placeholder:
                                                                "Aantal badkamers",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.data
                                                                  .numberOfBathrooms,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.data,
                                                                  "numberOfBathrooms",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "input",
                                                          ariaInput,
                                                          false
                                                        )
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "apartment"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              rules: "required",
                                              bails: false,
                                              name: "Aantal verdiepingen",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "numberOfFloorsInBuilding",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Aantal verdiepingen"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "suffix",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "circle",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.data.floors =
                                                                          _vm.subtractOrDefault(
                                                                            _vm
                                                                              .data
                                                                              .numberOfFloorsInBuilding
                                                                          )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("-")]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "circle",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.data.numberOfFloorsInBuilding =
                                                                          _vm.addOrDefault(
                                                                            _vm
                                                                              .data
                                                                              .numberOfFloorsInBuilding
                                                                          )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("+")]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "input",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .numberOfFloorsInBuilding,
                                                                    expression:
                                                                      "data.numberOfFloorsInBuilding",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "step-form-control",
                                                                class: {
                                                                  "has-errors":
                                                                    errors.length,
                                                                },
                                                                attrs: {
                                                                  id: "numberOfFloorsInBuilding",
                                                                  min: "0",
                                                                  type: "number",
                                                                  placeholder:
                                                                    "Aantal verdiepingen",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.data
                                                                      .numberOfFloorsInBuilding,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "numberOfFloorsInBuilding",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "input",
                                                              ariaInput,
                                                              false
                                                            )
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "apartment"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              rules: "required",
                                              bails: false,
                                              name: "Verdiep",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "floorNumber",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Verdiep van de woning"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "input",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .floorNumber,
                                                                    expression:
                                                                      "data.floorNumber",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "step-form-control",
                                                                class: {
                                                                  "has-errors":
                                                                    errors.length,
                                                                },
                                                                attrs: {
                                                                  id: "floorNumber",
                                                                  min: "0",
                                                                  type: "number",
                                                                  placeholder:
                                                                    "Verdiep",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.data
                                                                      .floorNumber,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "floorNumber",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "input",
                                                              ariaInput,
                                                              false
                                                            )
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "house"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              rules:
                                                "required|min_value:50|max_value:5000",
                                              bails: false,
                                              name: "Grondoppervlakte",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "livable_surface",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Grondoppervlakte"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "suffix",
                                                            },
                                                            [_vm._v("m²")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "input",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .landArea,
                                                                    expression:
                                                                      "data.landArea",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "step-form-control",
                                                                class: {
                                                                  "has-errors":
                                                                    errors.length,
                                                                },
                                                                attrs: {
                                                                  id: "livable_surface",
                                                                  type: "text",
                                                                  placeholder:
                                                                    "Grondoppervlakte",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.data
                                                                      .landArea,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "landArea",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "input",
                                                              ariaInput,
                                                              false
                                                            )
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "row mb-16",
                                        attrs: {
                                          persist: "",
                                          rules:
                                            "required|min_value:10|max_value:800",
                                          bails: false,
                                          name: "Bewoonbare oppervlakte",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-3 mt-10 step-label",
                                                      attrs: {
                                                        for: "livable_surface",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Bewoonbare oppervlakte"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-4",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "suffix",
                                                        },
                                                        [_vm._v("m²")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "input",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.data
                                                                    .livingArea,
                                                                expression:
                                                                  "data.livingArea",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "step-form-control",
                                                            class: {
                                                              "has-errors":
                                                                errors.length,
                                                            },
                                                            attrs: {
                                                              id: "livable_surface",
                                                              type: "text",
                                                              placeholder:
                                                                "Bewoonbare oppervlakte",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.data
                                                                  .livingArea,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.data,
                                                                  "livingArea",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "input",
                                                          ariaInput,
                                                          false
                                                        )
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "row mb-16",
                                        attrs: {
                                          persist: "",
                                          rules: "required|min_value:1850",
                                          bails: false,
                                          name: "Bouwjaar",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-3 mt-10 step-label",
                                                      attrs: {
                                                        for: "construction_year",
                                                      },
                                                    },
                                                    [_vm._v("Bouwjaar")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-4",
                                                    },
                                                    [
                                                      _c(
                                                        "input",
                                                        _vm._b(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.data
                                                                    .buildingYear,
                                                                expression:
                                                                  "data.buildingYear",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "step-form-control",
                                                            class: {
                                                              "has-errors":
                                                                errors.length,
                                                            },
                                                            attrs: {
                                                              id: "construction_year",
                                                              type: "text",
                                                              placeholder:
                                                                "Bouwjaar",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.data
                                                                  .buildingYear,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.data,
                                                                  "buildingYear",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "input",
                                                          ariaInput,
                                                          false
                                                        )
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "apartment"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              bails: false,
                                              name: "Tuin",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "has_garden",
                                                          },
                                                        },
                                                        [_vm._v("Tuin")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "custom-control custom-checkbox",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.has_garden,
                                                                    expression:
                                                                      "has_garden",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  id: "has_garden",
                                                                  name: "has_garden",
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      _vm.has_garden
                                                                    )
                                                                      ? _vm._i(
                                                                          _vm.has_garden,
                                                                          null
                                                                        ) > -1
                                                                      : _vm.has_garden,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          _vm.has_garden,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            (_vm.has_garden =
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              ))
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            (_vm.has_garden =
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                ))
                                                                        }
                                                                      } else {
                                                                        _vm.has_garden =
                                                                          $$c
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-control-label",
                                                                  attrs: {
                                                                    for: "has_garden",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Tuin aanwezig "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.has_garden
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              rules: "required",
                                              bails: false,
                                              name: "Tuin oppervlakte",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "garden_surface",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Tuin oppervlakte"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "suffix",
                                                            },
                                                            [_vm._v("m²")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "input",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .gardenArea,
                                                                    expression:
                                                                      "data.gardenArea",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "step-form-control",
                                                                class: {
                                                                  "has-errors":
                                                                    errors.length,
                                                                },
                                                                attrs: {
                                                                  id: "garden_surface",
                                                                  type: "text",
                                                                  placeholder:
                                                                    "Tuin oppervlakte",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.data
                                                                      .gardenArea,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "gardenArea",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "input",
                                                              ariaInput,
                                                              false
                                                            )
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        staticClass: "row mb-16",
                                        attrs: {
                                          persist: "",
                                          bails: false,
                                          name: "Balkon of terras",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-3 mt-10 step-label",
                                                      attrs: {
                                                        for: "has_balcony",
                                                      },
                                                    },
                                                    [_vm._v("Balkon of terras")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-lg-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "custom-control custom-checkbox",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.has_balcony,
                                                                expression:
                                                                  "has_balcony",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "custom-control-input",
                                                            attrs: {
                                                              id: "has_balcony",
                                                              name: "has_balcony",
                                                              type: "checkbox",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.has_balcony
                                                                )
                                                                  ? _vm._i(
                                                                      _vm.has_balcony,
                                                                      null
                                                                    ) > -1
                                                                  : _vm.has_balcony,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm.has_balcony,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      (_vm.has_balcony =
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        ))
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      (_vm.has_balcony =
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          ))
                                                                  }
                                                                } else {
                                                                  _vm.has_balcony =
                                                                    $$c
                                                                }
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "custom-control-label",
                                                              attrs: {
                                                                for: "has_balcony",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Balkon of terras aanwezig "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      errors.length
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "error-message",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _vm.has_balcony
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              rules: "required",
                                              bails: false,
                                              name: "Balkon / Terras oppervlakte",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "balcony_surface",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Balkon / Terras oppervlakte"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "suffix",
                                                            },
                                                            [_vm._v("m²")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "input",
                                                            _vm._b(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .balconyArea,
                                                                    expression:
                                                                      "data.balconyArea",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "step-form-control",
                                                                class: {
                                                                  "has-errors":
                                                                    errors.length,
                                                                },
                                                                attrs: {
                                                                  id: "balcony_surface",
                                                                  type: "text",
                                                                  placeholder:
                                                                    "Balkon / Terras oppervlakte",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm.data
                                                                      .balconyArea,
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "balconyArea",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              "input",
                                                              ariaInput,
                                                              false
                                                            )
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "apartment"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              bails: false,
                                              name: "Lift",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "hasLift",
                                                          },
                                                        },
                                                        [_vm._v("Lift")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "custom-control custom-checkbox",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .hasLift,
                                                                    expression:
                                                                      "data.hasLift",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  id: "hasLift",
                                                                  name: "hasLift",
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      _vm.data
                                                                        .hasLift
                                                                    )
                                                                      ? _vm._i(
                                                                          _vm
                                                                            .data
                                                                            .hasLift,
                                                                          null
                                                                        ) > -1
                                                                      : _vm.data
                                                                          .hasLift,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          _vm
                                                                            .data
                                                                            .hasLift,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasLift",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasLift",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          _vm.data,
                                                                          "hasLift",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-control-label",
                                                                  attrs: {
                                                                    for: "hasLift",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Lift aanwezig "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "house"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              bails: false,
                                              name: "Sauna",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "hasLift",
                                                          },
                                                        },
                                                        [_vm._v("Sauna")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "custom-control custom-checkbox",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .hasSauna,
                                                                    expression:
                                                                      "data.hasSauna",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  id: "hasSauna",
                                                                  name: "hasSauna",
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      _vm.data
                                                                        .hasSauna
                                                                    )
                                                                      ? _vm._i(
                                                                          _vm
                                                                            .data
                                                                            .hasSauna,
                                                                          null
                                                                        ) > -1
                                                                      : _vm.data
                                                                          .hasSauna,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          _vm
                                                                            .data
                                                                            .hasSauna,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasSauna",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasSauna",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          _vm.data,
                                                                          "hasSauna",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-control-label",
                                                                  attrs: {
                                                                    for: "hasSauna",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Sauna aanwezig "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.propertyType.code === "house"
                                        ? _c("ValidationProvider", {
                                            staticClass: "row mb-16",
                                            attrs: {
                                              persist: "",
                                              bails: false,
                                              name: "Zwembad",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    var ariaMsg = ref.ariaMsg
                                                    var ariaInput =
                                                      ref.ariaInput
                                                    return [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-3 mt-10 step-label",
                                                          attrs: {
                                                            for: "hasLift",
                                                          },
                                                        },
                                                        [_vm._v("Zwembad")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-12 col-lg-4",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "custom-control custom-checkbox",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.data
                                                                        .hasPool,
                                                                    expression:
                                                                      "data.hasPool",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  id: "hasPool",
                                                                  name: "hasPool",
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      _vm.data
                                                                        .hasPool
                                                                    )
                                                                      ? _vm._i(
                                                                          _vm
                                                                            .data
                                                                            .hasPool,
                                                                          null
                                                                        ) > -1
                                                                      : _vm.data
                                                                          .hasPool,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          _vm
                                                                            .data
                                                                            .hasPool,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasPool",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              _vm.data,
                                                                              "hasPool",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          _vm.data,
                                                                          "hasPool",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "custom-control-label",
                                                                  attrs: {
                                                                    for: "hasPool",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Zwembad aanwezig "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          errors.length
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "error-message",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm.step === 5
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step h-100",
                                      attrs: { id: "step-5" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v("Wat is de staat van het pand?"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "step-sub-title" },
                                        [_vm._v("Keuken")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-4" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "q_kitchen",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Keuken kwaliteit",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-quality",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .quality
                                                                        .kitchen,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .quality,
                                                                          "kitchen",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.quality.kitchen",
                                                                  },
                                                                },
                                                                "el-estate-quality",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-8" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "c_kitchen",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Keuken staat",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-state",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .condition
                                                                        .kitchen,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .condition,
                                                                          "kitchen",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.condition.kitchen",
                                                                  },
                                                                },
                                                                "el-estate-state",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "step-sub-title" },
                                        [_vm._v("Badkamers")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-4" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "q_bathrooms",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Badkamer kwaliteit",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-quality",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .quality
                                                                        .bathrooms,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .quality,
                                                                          "bathrooms",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.quality.bathrooms",
                                                                  },
                                                                },
                                                                "el-estate-quality",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-8" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "c_bathrooms",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Badkamer staat",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-state",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .condition
                                                                        .bathrooms,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .condition,
                                                                          "bathrooms",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.condition.bathrooms",
                                                                  },
                                                                },
                                                                "el-estate-state",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "step-sub-title" },
                                        [_vm._v("Vloeren")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-4" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "q_flooring",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Vloer kwaliteit",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-quality",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .quality
                                                                        .flooring,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .quality,
                                                                          "flooring",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.quality.flooring",
                                                                  },
                                                                },
                                                                "el-estate-quality",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-8" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "c_flooring",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Vloer staat",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-state",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .condition
                                                                        .flooring,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .condition,
                                                                          "flooring",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.condition.flooring",
                                                                  },
                                                                },
                                                                "el-estate-state",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "step-sub-title" },
                                        [_vm._v("Ramen")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-4" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "q_windows",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Raam kwaliteit",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-quality",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .quality
                                                                        .windows,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .quality,
                                                                          "windows",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.quality.windows",
                                                                  },
                                                                },
                                                                "el-estate-quality",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-8" },
                                          [
                                            _vm.subTypes.length
                                              ? _c("ValidationProvider", {
                                                  key: "c_windows",
                                                  staticClass: "mb-20 d-block",
                                                  attrs: {
                                                    persist: "",
                                                    rules: "required",
                                                    bails: false,
                                                    name: "Raam staat",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (ref) {
                                                          var errors =
                                                            ref.errors
                                                          var ariaMsg =
                                                            ref.ariaMsg
                                                          var ariaInput =
                                                            ref.ariaInput
                                                          return [
                                                            _c(
                                                              "el-estate-state",
                                                              _vm._b(
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .condition
                                                                        .windows,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .condition,
                                                                          "windows",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.condition.windows",
                                                                  },
                                                                },
                                                                "el-estate-state",
                                                                ariaInput,
                                                                false
                                                              )
                                                            ),
                                                            _vm._v(" "),
                                                            errors.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "error-message",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm.step === 6
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step h-100",
                                      attrs: { id: "step-6" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v("Wanneer wil je verkopen?"),
                                      ]),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          persist: "",
                                          rules: "required",
                                          bails: false,
                                          name: "Duurtijd",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                var ariaMsg = ref.ariaMsg
                                                var ariaInput = ref.ariaInput
                                                return [
                                                  _c(
                                                    "el-estate-sub-type-select",
                                                    _vm._b(
                                                      {
                                                        class: {
                                                          "has-errors":
                                                            errors.length,
                                                        },
                                                        attrs: {
                                                          items: [
                                                            "asap",
                                                            "three_to_six",
                                                            "six_or_more",
                                                            "not_for_sale",
                                                          ],
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.data.duration,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.data,
                                                              "duration",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.duration",
                                                        },
                                                      },
                                                      "el-estate-sub-type-select",
                                                      ariaInput,
                                                      false
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  errors.length
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error-message",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.step === 7
                                ? _c(
                                    "section",
                                    {
                                      staticClass: "price-hubble-step h-100",
                                      attrs: { id: "step-7" },
                                    },
                                    [
                                      _c("h2", { staticClass: "step-title" }, [
                                        _vm._v("Wat zijn je contactgegevens?"),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-lg-6" },
                                          [
                                            _c("ValidationProvider", {
                                              staticClass: "mb-16 d-block",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                                name: "Voornaam",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "step-label",
                                                          },
                                                          [_vm._v("Voornaam")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .user
                                                                      .first_name,
                                                                  expression:
                                                                    "data.user.first_name",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Voornaam",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data.user
                                                                    .first_name,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .user,
                                                                      "first_name",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "mb-16 d-block",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                                name: "Achternaam",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "step-label",
                                                          },
                                                          [_vm._v("Achternaam")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .user
                                                                      .last_name,
                                                                  expression:
                                                                    "data.user.last_name",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Achternaam",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data.user
                                                                    .last_name,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .user,
                                                                      "last_name",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "mb-16 d-block",
                                              attrs: {
                                                persist: "",
                                                rules: "required",
                                                bails: false,
                                                name: "Email",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "step-label",
                                                          },
                                                          [_vm._v("Email")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .user
                                                                      .email,
                                                                  expression:
                                                                    "data.user.email",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "email",
                                                                placeholder:
                                                                  "Email",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data.user
                                                                    .email,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .user,
                                                                      "email",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "mb-16 d-block",
                                              attrs: {
                                                mode: "passive",
                                                persist: "",
                                                rules: "required|phone",
                                                bails: false,
                                                name: "Telefoonnummer",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "step-label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Telefoonnummer"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "input",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .user
                                                                      .phone,
                                                                  expression:
                                                                    "data.user.phone",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Telefoonnummer",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data.user
                                                                    .phone,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .user,
                                                                      "phone",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "input",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              staticClass: "mb-16 d-block",
                                              attrs: {
                                                persist: "",
                                                bails: false,
                                                name: "Opmerking",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "step-label",
                                                          },
                                                          [_vm._v("Opmerking")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "textarea",
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .user
                                                                      .remark,
                                                                  expression:
                                                                    "data.user.remark",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "step-form-control",
                                                              class: {
                                                                "has-errors":
                                                                  errors.length,
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  "Opmerking",
                                                                cols: "8",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.data.user
                                                                    .remark,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm.data
                                                                        .user,
                                                                      "remark",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "textarea",
                                                            ariaInput,
                                                            false
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("ValidationProvider", {
                                              attrs: {
                                                persist: "",
                                                bails: false,
                                                name: "Nieuwsbrief",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var errors = ref.errors
                                                      var ariaMsg = ref.ariaMsg
                                                      var ariaInput =
                                                        ref.ariaInput
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "custom-control custom-checkbox",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.data
                                                                      .accept_newsletter,
                                                                  expression:
                                                                    "data.accept_newsletter",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "custom-control-input",
                                                              attrs: {
                                                                id: "accept_newsletters",
                                                                name: "accept_newsletters",
                                                                type: "checkbox",
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.data
                                                                      .accept_newsletter
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.data
                                                                          .accept_newsletter,
                                                                        null
                                                                      ) > -1
                                                                    : _vm.data
                                                                        .accept_newsletter,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.data
                                                                          .accept_newsletter,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? true
                                                                          : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm.data,
                                                                            "accept_newsletter",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm.data,
                                                                            "accept_newsletter",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm.data,
                                                                        "accept_newsletter",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "custom-control-label",
                                                                attrs: {
                                                                  for: "accept_newsletters",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Ja, ik wil de nieuwsbrieven ontvangen."
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        errors.length
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error-message",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              Object.values(_vm.errors).length > 0
                                ? _c(
                                    "div",
                                    { class: { "mt-32": _vm.step !== 8 } },
                                    _vm._l(
                                      _vm.errors,
                                      function (errorMessage, errorType) {
                                        return _c(
                                          "div",
                                          { staticClass: "error-toast" },
                                          [
                                            errorMessage.length > 0
                                              ? [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(errorMessage[0]) +
                                                      "\n              "
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm.errors.pricehubble &&
                                  _vm.errors.pricehubble.length > 0
                                ? _c(
                                    "div",
                                    { class: { "mt-32": _vm.step !== 8 } },
                                    _vm._l(
                                      _vm.errors.pricehubble,
                                      function (errorMessage, errorType) {
                                        return _c(
                                          "div",
                                          { staticClass: "error-toast" },
                                          [
                                            errorMessage.length > 0
                                              ? [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(errorMessage) +
                                                      "\n              "
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.step !== 8
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "next-button",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return passes(_vm.nextStep)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.isLastStep &&
                                              _vm.estimation_type !==
                                                "appointment"
                                              ? "Schatting aanvragen"
                                              : "Volgende"
                                          ) +
                                          "\n            "
                                      ),
                                      _c("el-loader", {
                                        staticClass: "ml-16 loading",
                                        attrs: { loading: _vm.loading },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  578402606
                ),
              })
            : !_vm.loading && _vm.step !== 8 && _vm.valuation
            ? _c(
                "div",
                { staticClass: "content-wrapper success-step text-center" },
                [
                  _c("h2", { staticClass: "success-title mb-32" }, [
                    _vm._v("Ons "),
                    _c("span", { staticClass: "text-primary" }, [
                      _vm._v("Schattingsbereik"),
                    ]),
                  ]),
                  _vm._v(" "),
                  Object.values(_vm.errors).length > 0
                    ? _c(
                        "div",
                        { class: { "mt-32": _vm.step !== 8 } },
                        _vm._l(_vm.errors, function (errorMessage, errorType) {
                          return _c(
                            "div",
                            { staticClass: "error-toast" },
                            [
                              errorMessage.length > 0
                                ? [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(errorMessage[0]) +
                                        "\n            "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "estimation-range mb-16" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatPrice(
                          _vm.valuation.salePriceRange.lower,
                          _vm.valuation.currency
                        )
                      ) +
                        " — " +
                        _vm._s(
                          _vm.formatPrice(
                            _vm.valuation.salePriceRange.upper,
                            _vm.valuation.currency
                          )
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "estimation-avg mb-32" }, [
                    _vm._v(
                      "Gemiddelde prijs " +
                        _vm._s(
                          _vm.formatPrice(
                            _vm.valuation.salePrice,
                            _vm.valuation.currency
                          )
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "estimation-quality mb-8 text-left" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Nauwkeurigheid"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "select" },
                        [
                          _c("span", { staticClass: "description" }, [
                            _vm._v(
                              _vm._s(_vm.confidence[_vm.valuation.confidence])
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            ["good", "medium", "poor", "bad", "none"],
                            function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.name,
                                  staticClass: "item",
                                  class: {
                                    active: _vm.valuation.confidence === item,
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.svg("star", "star")
                                      ),
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "success-description mb-64" }, [
                    _vm._v(
                      "\n          De schatting van je woning is vrij nauwkeurig. Al vervangt een online schatting nooit het "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n          werk van een specialist in je regio. Contacteer je makelaar om je online schatting te bevestigen.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "success-title-sm" }, [
                    _vm._v("Wil je een gedetailleerde schatting?"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "success-description" }, [
                    _vm._v(
                      "Op basis van jouw gegevens heeft Copandi alvast een makelaar voor jou gekozen."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.goToCalendlyStep()
                        },
                      },
                    },
                    [_vm._v("Plan een afspraak in")]
                  ),
                ]
              )
            : _vm.step === 8
            ? _c(
                "div",
                { staticClass: "content-wrapper success-step calendly-step" },
                [
                  _c(
                    "section",
                    {
                      staticClass: "price-hubble-step h-100 w-100",
                      attrs: { id: "step-8" },
                    },
                    [
                      Object.values(_vm.errors).length > 0
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.errors,
                              function (errorMessage, errorType) {
                                return _c(
                                  "div",
                                  { staticClass: "error-toast" },
                                  [
                                    errorMessage.length > 0
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(errorMessage[0]) +
                                              "\n              "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "w-100 h-100",
                        attrs: { id: "calendly-inline-widget" },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "side-subtext" }, [
      _vm._v(
        "\n        U kan hiernaast onmiddellijk een vrijblijvende kennismaking inplannen in de agenda van onze lokale Copandi makelaar via "
      ),
      _c("span", { staticClass: "text-primary" }, [
        _vm._v("Kennismaking afspraak"),
      ]),
      _vm._v(
        ". De kennismaking is een vrijblijvende afspraak waarbij onze makelaar kennis maakt met u als eigenaar en met uw\n        woning. Indien gewenst leveren we na het bezoek een gratis waardebepaling van uw woning af en doen we u een concreet voorstel tot samenwerking. "
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n        Je kan ook kiezen voor een "),
      _c("span", { staticClass: "text-primary" }, [
        _vm._v("Digitale schatting"),
      ]),
      _vm._v(
        " wanneer je graag reeds een indicatie hebt van de prijs van de woning. Houd er rekening mee dat dit slechts een prijsindicatie is op basis van AI en dit de expertise van een makelaar nooit kan vervangen. Het is\n        aangeraden om nadien onze makelaar uit te nodigen om de waardebepaling persoonlijk af te werken."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n        Indien u hierbij ondersteuning nodig hebt, kan u ons steeds bereiken op "
      ),
      _c("a", { attrs: { href: "tel:080054311" } }, [_vm._v("0800 54 311")]),
      _vm._v(" of via "),
      _c("a", { attrs: { href: "mailto:info@copandi.be" } }, [
        _vm._v("info@copandi.be"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }