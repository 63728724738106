<style lang="scss" scoped>
.form-select {
  height: 60px;
  &.disabled {
    ::v-deep .vs__dropdown-toggle {
      background: #f8f8f8;
    }
  }
  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 2px;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    padding: 15px 1rem;

    input {
      &::placeholder {
        color: #9a9a9a;
        font-weight: 400;
        opacity: 1;
      }
    }
  }
}
.toggl {
  height: 60px;
  background-color: #ffffff;
  display: flex;
  flex-flow: row;
  .off {
    border: 1px solid #eaeaea;
    border-radius: 4px 0 0 4px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    color: #afafaf;
    letter-spacing: 0;
    line-height: 19px;
    &:hover {
      color: #BBA876;
    }
    &.active {
      border-color: #BBA876;
      color: #BBA876;
      ::v-deep .icon {
        path {
          stroke: #BBA876;
        }
      }
    }
  }
  .on {
    border: 1px solid #eaeaea;
    border-radius: 0 4px 4px 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    color: #afafaf;
    letter-spacing: 0;
    line-height: 19px;
    &:hover {
      color: #BBA876;
    }
    &.active {
      border-color: #BBA876;
      color: #BBA876;
      ::v-deep .icon {
        path {
          stroke: #BBA876;
        }
      }
    }
  }
  ::v-deep .icon {
    height: 20px;
    width: 20px;
    path {
      stroke: #e1e1e1;
    }
  }
}
.page {
  background: #fafafa;
  min-height: 60vh;
}
.form-select.sort {
  height: 60px;
  min-width: 220px;
  ::v-deep .vs__dropdown-toggle {
    border-radius: 0;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 2px;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    padding: 15px 1rem;

    input {
      &::placeholder {
        color: #9a9a9a;
        font-weight: 400;
        opacity: 1;
      }
    }
  }
}
</style>

<template>
  <div class="col-12">
    <section class="row mb-56">
      <div class="container">
        <div class="form-row">

          <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
            <label class="mb-8 small font-weight-bold">Regio</label>
            <v-select
              v-model="selectedCity"
              @input="
                val => {
                  data.city = val.key;
                }
              "
              class="form-select"
              :options="cities"
              placeholder="Regio"
            ></v-select>
          </div>

          <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
            <label class="mb-8 small font-weight-bold">Type</label>
            <v-select
              v-model="selectedType"
              @input="
                val => {
                  data.type = val.key;
                }
              "
              class="form-select"
              :options="types"
              placeholder="Type"
            ></v-select>
          </div>

          <div v-if="!projectId" class="col-12 col-lg-3 mb-16">
            <label class="mb-8 small font-weight-bold">Te koop / Te huur</label>
            <div class="toggl">
              <div class="off" @click="data.saleOrLet = 'sale'" :class="{ active: data.saleOrLet === 'sale' }">Te koop</div>
              <div class="on" @click="data.saleOrLet = 'let'" :class="{ active: data.saleOrLet === 'let' }">Te huur</div>
            </div>
          </div>

            <div class="col-12 col-lg-3 mb-16">
                <label class="mb-8 small font-weight-bold">Status</label>
                <v-select
                    v-model="selectedSaleOrOption"
                    class="form-select"
                    :options="saleOrOption"
                    placeholder="Status"
                    @input="
                val => {
                  data.verkochtOptie = val.key;
                }
              "
                ></v-select>
            </div>

          <div class="col-12 col-lg-2 mb-16">
            <label class="mb-8 small font-weight-bold">Minimum prijs</label>
            <v-select
              v-model="selectedMin"
              class="form-select"
              :options="min"
              placeholder="Minimum prijs"
              @input="
                val => {
                  data.minprice = val.key;
                }
              "
            ></v-select>
          </div>

          <div class="col-12 col-lg-2 mb-16">
            <label class="mb-8 small font-weight-bold">Maximum prijs</label>
            <v-select
              v-model="selectedMax"
              class="form-select"
              :options="max"
              placeholder="Maximum prijs"
              @input="
                val => {
                  data.maxprice = val.key;
                }
              "
            ></v-select>
          </div>
        </div>
      </div>
    </section>
    <section class="row page pt-56" style="background-color: #121212;">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-grid">
            <div class="d-flex justify-content-between mb-40 align-items-center">
              <h5 class="mb-0 d-none d-lg-block">{{ totalEstates > 0 ? totalEstates : 'Geen' }} panden gevonden</h5>
              <div class="d-flex flex-row align-self-end">
                <v-select
                  v-model="order"
                  class="form-select sort
                "
                  @input="
                    val => {
                      data.order = val.key;
                    }
                  "
                  :options="orders"
                  placeholder="sorteer"
                ></v-select>
                <div>
                  <div class="toggl ml-16">
                    <div class="off px-24" @click="mode = 'cards'" :class="{ active: mode === 'cards' }">
                      <span v-html="svg('grid', 'icon')"></span>
                    </div>
                    <div v-if="projectId === null" class="on px-24" @click="mode = 'map'" :class="{ active: mode === 'map' }">
                      <span v-html="svg('marker-hollow', 'icon')"></span>
                    </div>
                    <div v-else class="on px-24" @click="mode = 'list'" :class="{ active: mode === 'list' }">
                      <span v-html="svg('list', 'icon')"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <gold-estate-spotlight :params="data" :mode="mode" :cta-cards="ctaCards" :is-project="projectId !== null" :show-total="true" :show-sort="true" :per-page="ctaCards ? 8 : 9" per-row="3" can-load-more="true" @total-updated="total => (totalEstates = total)"></gold-estate-spotlight>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver, extend, localize} from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        projectId: {
            required: false,
            default: null,
        },
        hideUnits: {
            required: false,
            default: false,
        },
        ctaCards: {
            required: false,
            default: false,
        },
        boxes: {
            required: false,
            default: () => [],
        },
        floors: {
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            totalEstates: 0,
            mode: 'cards', // list, map

            selectedRange: null,
            selectedCity: null,
            selectedType: null,
            selectedSpec: null,
            selectedMin: null,
            selectedMax: null,
            selectedBox: null,
            selectedFloor: null,
            selectedSaleOrOption: {key: true, label: 'Alle'},

            data: {
                search: null,
                minprice: null,
                maxprice: null,

                straal: null,
                city: null,
                type: null,

                filtertuin: null,
                filterterras: null,
                filterparkeerplaats: null,
                filternieuwbouw: null,
                filterlift: null,
                hideUnits: this.hideUnits,

                order: 'asc',

                saleOrLet: 'sale',
                verkochtOptie: true,
                projectId: this.projectId,
                floor: null,
                box: null,
            },
            ranges: [
                {key: '3', label: '3KM'},
                {key: '5', label: '5KM'},
                {key: '10', label: '10KM'},
                {key: '25', label: '25KM'},
            ],
            cities: [
                {key: 'antwerpen', label: 'Antwerpen'},
                {key: 'mechelen', label: 'Mechelen'},
                {key: 'kempen', label: 'Kempen & Limburg'},
                {key: 'leuven', label: 'Leuven'},
            ],
            types: [
                {key: 'apartment', label: 'Appartement'},
                {key: 'house', label: 'Huis'},
                {key: 'land', label: 'Bouwgrond'},
                {key: 'commercial', label: 'Commercieel'},
                {key: 'investment_property', label: 'Opbrengsteigendom'},
            ],
            specifications: [
                {key: 'filtertuin', label: 'Tuin'},
                {key: 'filterterras', label: 'Terras'},
                {key: 'filterparkeerplaats', label: 'Parking'},
                {key: 'filternieuwbouw', label: 'Nieuwbouw'},
                {key: 'filterlift', label: 'Lift'},
            ],
            min: [
                {key: '500000', label: '€500.000'},
                {key: '600000', label: '€600.000'},
                {key: '700000', label: '€700.000'},
                {key: '800000', label: '€800.000'},
                {key: '900000', label: '€900.000'},
            ],
            max: [
                {key: '500000', label: '€500.000'},
                {key: '600000', label: '€600.000'},
                {key: '700000', label: '€700.000'},
                {key: '800000', label: '€800.000'},
                {key: '900000', label: '€900.000'},
                {key: '2000000', label: '> €900.000'},
            ],
            saleOrOption: [
                {key: false, label: 'Te koop'},
                {key: true, label: 'Alle'},
            ],
            order: {key: 'asc', label: 'Nieuwste eerst'},
            orders: [
                {key: 'asc', label: 'Nieuwste eerst'},
                {key: 'price', label: 'Goedkoopste eerst'},
            ],
            success: false,
            loading: false,
            queryParams: '?',
        };
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const search = urlParams.get('search');
        const negotiation = urlParams.get('negotiation');
        const type = urlParams.get('type');

        if (search && search != '') this.data.search = search;
        if (negotiation && negotiation != '') this.data.saleOrLet = negotiation;

        if (type && type != '') {
            if (type === 'apartment') {
                this.data.type = type;
                this.selectedType = {key: 'apartment', label: 'Appartement'};
            }
            if (type === 'house') {
                this.data.type = type;
                this.selectedType = {key: 'house', label: 'Huis'};
            }
            if (type === 'land') {
                this.data.type = type;
                this.selectedType = {key: 'land', label: 'Bouwgrond'};
            }
            if (type === 'commercial') {
                this.data.type = type;
                this.selectedType = {key: 'commercial', label: 'Commercieel'};
            }
            if (type === 'investment_property') {
                this.data.subtype = type;
                this.selectedType = {key: 'investment_property', label: 'Opbrengsteigendom'};
            }
        }
    },
    methods: {
        filterChanged() {
            if (this.data.search) {
                this.queryParams += 'search=' + this.data.search + '&';
            }
            if (this.data.saleOrLet) {
                this.queryParams += 'negotiation=' + this.data.saleOrLet + '&';
            }
            if (this.selectedType) {
                this.queryParams += 'type=' + this.selectedType.key + '&';
            }

            this.setUrlParams();
        },
        specificationChanged(values) {
            this.data.filtertuin = false;
            this.data.filterterras = false;
            this.data.filterparkeerplaats = false;
            this.data.filternieuwbouw = false;
            this.data.filterlift = false;
            values.forEach(val => {
                if (val.key === 'filtertuin') {
                    this.data.filtertuin = true;
                    this.queryParams += 'filtertuin=true&';
                }
                if (val.key === 'filterterras') {
                    this.data.filterterras = true;
                    this.queryParams += 'filterterras=true&';
                }
                if (val.key === 'filterparkeerplaats') {
                    this.data.filterparkeerplaats = true;
                    this.queryParams += 'filterparkeerplaats=true&';
                }
                if (val.key === 'filternieuwbouw') {
                    this.data.filternieuwbouw = true;
                    this.queryParams += 'filternieuwbouw=true&';
                }
                if (val.key === 'filterlift') {
                    this.data.filterlift = true;
                    this.queryParams += 'filterlift=true&';
                }
            });

            this.setUrlParams();
        },
        setUrlParams() {
            if (history.pushState) {
                let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + this.queryParams;
                window.history.pushState({path: newurl}, '', newurl);
            }
        },
    },
};
</script>
