var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        [3, 4, 5, 6, 16].includes(_vm.data.purposeStatus.id) ? "div" : "a",
        {
          tag: "component",
          staticClass: "estate-card",
          class: [
            _vm.wrapperClass,
            { nc: [3, 4, 5, 6, 16].includes(_vm.data.purposeStatus.id) },
          ],
          attrs: { href: _vm.computedRoute },
        },
        [
          _c(
            "div",
            { staticClass: "estate-image" },
            [
              _c("img", {
                staticClass: "lazyload",
                attrs: { "data-srcset": _vm.imageStyle },
              }),
              _vm._v(" "),
              _c("estate-special-label", { attrs: { estate: _vm.data } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 d-flex justify-content-start align-items-center position-relative",
                },
                [
                  _c("EstatePrice", {
                    attrs: {
                      displayPrice: _vm.data.displayPrice,
                      statusId: _vm.data.status.id,
                      purposeStatusId: _vm.data.purposeStatus.id,
                      price: _vm.data.price,
                      isProject: _vm.data.is_project,
                      propertySpecialType: _vm.propertySpecialType
                        ? _vm.propertySpecialType
                        : "",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row p-16" }, [
              _c(
                "div",
                { staticClass: "estate-footer col-12 d-flex " },
                [
                  _c("EstatePropertyLine", {
                    attrs: { type: "rooms", data: _vm.data.rooms },
                  }),
                  _vm._v(" "),
                  _c("EstatePropertyLine", {
                    attrs: { type: "ground", data: _vm.data.groundArea },
                  }),
                  _vm._v(" "),
                  _c("EstatePropertyLine", {
                    attrs: { type: "area", data: _vm.data.area },
                  }),
                  _vm._v(" "),
                  _vm.data.is_project
                    ? _c("EstatePropertyLine", {
                        attrs: { type: "box", data: _vm.data.box },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.is_project
                    ? _c("EstatePropertyLine", {
                        attrs: { type: "floor", data: _vm.data.floor },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("EstatePropertyLine", {
                    attrs: { type: "epc", data: _vm.epcClass },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row pb-16 px-16" }, [
              _c("div", { staticClass: "col-12 d-flex" }, [
                _vm.data.parentId
                  ? _c(
                      "h5",
                      { staticClass: "estate-title text-secondary h6" },
                      [_vm._v(_vm._s(_vm.data.name))]
                    )
                  : !_vm.data.project_id
                  ? _c(
                      "h5",
                      { staticClass: "estate-title text-secondary h6" },
                      [
                        _vm.data.subCategory.id
                          ? _c("span", {}, [
                              _vm._v(
                                "\n\t\t    " +
                                  _vm._s(
                                    _vm.formatSubType(_vm.data.subCategory.id)
                                  ) +
                                  "\n\t\t"
                              ),
                            ])
                          : _vm.data.category.id
                          ? _c("span", {}, [
                              _vm._v(
                                "\n\t\t    " +
                                  _vm._s(_vm.formatType(_vm.data.category.id)) +
                                  "\n\t\t"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        [1].includes(_vm.data.purposeStatus.id)
                          ? _c("span", [_vm._v("te koop")])
                          : [2].includes(_vm.data.purposeStatus.id)
                          ? _c("span", [_vm._v("te huur")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-normal" }, [
                          _vm._v("in " + _vm._s(_vm.data.city)),
                        ]),
                      ]
                    )
                  : _c(
                      "h5",
                      { staticClass: "estate-title text-secondary h6" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.data.description_title) +
                            "\n            "
                        ),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c("div", [
                  _vm.data.address
                    ? _c(
                        "span",
                        { staticClass: "text-primary soft-underline" },
                        [
                          _vm._v(
                            _vm._s(_vm.data.address) +
                              " " +
                              _vm._s(_vm.data.number)
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }