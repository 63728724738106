var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "d-block" }, [_vm._v("Staat")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "select" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.name,
            staticClass: "item",
            class: { active: _vm.localValue.value === item.value },
            on: {
              click: function ($event) {
                return _vm.handleInput(item)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }