<style lang="scss" scoped>
.form-control {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
  height: 56px;
  @media (min-width: 992px) {
    min-width: 300px;
  }
}
.text-danger {
  position: absolute;
  top: -25px;
}
.success-message {
  opacity: 0.8;
  color: #fff;
}
.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
  height: 56px;
}
.form-inline {
  flex-flow: row;
}
</style>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form v-if="!success" class="form-inline w-100 position-relative" @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" class="w-100">
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
          <input v-model="data.email" v-bind="ariaInput" name="email" type="email" class="form-control w-100" placeholder="Emailadres" />
        </validation-provider>

        <button type="submit" class="btn d-flex align-items-center justify-content-center btn-primary">
          Inschrijven
          <el-loader class="ml-16" :loading="loading"></el-loader>
        </button>
      </form>
      <p v-else class="success-message">
        Bedankt, we houden je spoedig op de hoogte <br />
        van het laatste nieuws.
      </p>
    </ValidationObserver>
  </div>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },
  data() {
    return {
      data: {
        email: '',
        list_id: 23,
      },
      success: false,
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      axios.post('/api/newsletter', this.data).then((res) => {
        this.success = true;
        this.loading = false;
        this.data.email = '';
      });
    },
  },
};
</script>
