var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan-slider", class: _vm.wrapperClass }, [
    _c(
      "div",
      { ref: "slider" },
      _vm._l(_vm.plans, function (plan) {
        return _c("div", { key: plan.id }, [
          _c(
            "a",
            {
              staticClass:
                "plan p-20 py-lg-80 d-flex flex-column align-items-center text-center",
              class: { popular: plan.acf.is_popular, gold: plan.acf.is_gold },
              attrs: {
                href:
                  "" +
                  (plan.acf.is_gold
                    ? "https://gold.copandi.be"
                    : plan.relative_link),
              },
            },
            [
              _c("img", {
                staticClass: "lazyload plan-image mb-40",
                attrs: { "data-src": plan.acf.logo.url, alt: "" },
              }),
              _vm._v(" "),
              _c("h4", { staticClass: "mb-16 text-secondary" }, [
                _vm._v(_vm._s(plan.acf.title)),
              ]),
              _vm._v(" "),
              plan.acf.price && plan.acf.price !== NaN
                ? _c("div", { staticClass: "mb-56" }, [
                    _c(
                      "h5",
                      { staticClass: "text-primary font-weight-normal mb-0" },
                      [
                        _vm._v(_vm._s(plan.acf.price) + " "),
                        plan.acf.price !== "Op maat"
                          ? _c("small", [_vm._v("excl. btw")])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    plan.acf.price !== "Op maat"
                      ? _c("div", [
                          _c("small", { staticClass: "text-gray" }, [
                            _vm._v(
                              "€ " +
                                _vm._s(_vm.getPriceIncl(plan.acf.price)) +
                                " incl. btw"
                            ),
                          ]),
                        ])
                      : _c("div", [_c("small", [_vm._v("‎")])]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-56 text-gray text-left text-lg-center" },
                _vm._l(plan.acf.usp_list, function (usp) {
                  return _c("p", {
                    staticClass: "mb-8",
                    domProps: { innerHTML: _vm._s(usp.usp) },
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("button", { staticClass: "btn btn-primary border-50" }, [
                _vm._v("Ontdek"),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }