<template>
  <GmapMap
    ref="map"
    :center="{
      lat: parseFloat(center.lat),
      lng: parseFloat(center.lng) - localOffsetX,
    }"
    :zoom="zoom"
    :map-type-id="mapTypeId"
    style="width: 100%; height: 514px"
    :options="{
      streetViewControl: false,
      draggingCursor: false,
      zoomControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
      styles: styles,
    }"
  >
    <gmap-cluster :styles="clusterStyles">
      <GmapMarker :key="`marker_${index}`" v-for="(m, index) in estates" :position="m.coordinate" :icon="m.icon" v-on:click="window.location.href = m.route" />
    </gmap-cluster>

    <GmapCircle v-for="(m, index) in offices" :key="`circle_${index}`" :center="m.coordinate" :radius="m.radius" :visible="true" :options="{ fillColor: '#92BE64', fillOpacity: 0.5, strokeOpacity: 0 }"></GmapCircle>
  </GmapMap>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

export default {
  props: {
    coordinates: {
      type: [Object, Boolean],
      required: true,
    },
    markers: {
      default: () => [],
      type: Array,
    },
    offsetx: {
      default: 0,
      type: Number,
    },
    zoom: {
      default: 14,
    },
    styles: {
      default: () => [
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [
            { saturation: -100 }, // <-- THIS
          ],
        },
      ],
    },
    mapTypeId: {
      default: 'roadmap',
    },
  },

  data(vm) {
    return {
      localOffsetX: 0,
      center: vm.coordinates ? vm.coordinates : { lat: 50.60229509638775, lng: 3.0247059387528408 },
      markerOptions: {
        url: '/images/marker-filled.svg',
      },
      clusterStyles: [
        {
          textColor: 'white',
          url: '/images/map-cluster.svg',
          height: 50,
          width: 50,
        },
      ],
    };
  },

  mounted() {
    if (window.outerWidth > 992) {
      this.localOffsetX = this.offsetx;
    }
    if (!this.coordinates) {
      this.$nextTick(() => {
        this.fitBounds();
      });
    }
  },

  methods: {
    fitBounds() {
      if (!this.google) return;

      let bounds = new this.google.maps.LatLngBounds();
      this.googleMarkers.forEach((marker) => {
        bounds.extend(marker.coordinate);
      });

      this.$refs.map.fitBounds(bounds);
      this.$refs.map.panToBounds(bounds);
    },
    computedRoute(data) {
      if (data.status == 'sold' || data.status == 'rented' || data.status == 'option') return 'javascript:void(0)';
      if (data.is_project) return this.route('nieuwbouw.show', { slug: data.slug });
      if (data.negotiation === 'sale') return this.route('resultaten.show', { slug: data.slug });
      if (data.negotiation === 'let') return this.route('resultaten.show', { slug: data.slug });
      if (data.negotiation === 'office') return 'kantoren/' + data.slug;
    },
    computedIcon(data) {
      if (data.status == 'sold' || data.status == 'rented' || data.status == 'option') {
        return 'javascript:void(0)';
      }
      if (data.negotiation == 'office') {
        return '/images/logo_simple.svg';
      } else {
        return '/images/marker-filled.svg';
      }
    },
  },

  computed: {
    google: gmapApi,
    googleMarkers() {
      if (!this.google) return;

      return this.markers.map((m) => {
        return {
          negotiation: m.negotiation,
          icon: this.computedIcon(m),
          radius: parseInt(m.radius),
          route: this.computedRoute(m),
          coordinate: new this.google.maps.LatLng(parseFloat(m.lat), parseFloat(m.lng)),
        };
      });
    },
    estates() {
      if (!this.googleMarkers) return [];
      return this.googleMarkers.filter((m) => m.negotiation !== 'office');
    },

    offices() {
      if (!this.googleMarkers) return [];
      return this.googleMarkers.filter((m) => m.negotiation === 'office');
    },
  },

  watch: {
    googleMarkers: function (value, oldVal) {
      if (!this.coordinates) {
        this.$nextTick(() => {
          this.fitBounds();
        });
      }
    },
  },
};
</script>
