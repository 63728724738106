var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "text-subtitle" }, [
        _vm._v(
          "Een nieuwbouwproject commercialiseren kan digitaler, efficiënter én goedkoper."
        ),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary border-50",
          attrs: { href: "/nieuwbouw/verkopen" },
        },
        [_vm._v("Bekijk de mogelijkheden")]
      ),
      _vm._v(" "),
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.svg("checkmark", "checkmark")) },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "text-title" }, [
      _vm._v("Ben je een "),
      _c("span", { staticClass: "text-primary" }, [_vm._v("verkoper")]),
      _vm._v("?"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }