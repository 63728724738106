<style lang="scss" scoped></style>

<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(verifyAndSubmit)">
      <div class="row">
        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Voornaam</label>
          <input v-model="data.first_name" v-bind="ariaInput" name="first_name" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Achternaam</label>
          <input v-model="data.last_name" v-bind="ariaInput" name="last_name" type="text" label="Achternaam" class="form-control mb-0" placeholder="Achternaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Emailadres</label>
          <input v-model="data.email" v-bind="ariaInput" name="email" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Telefoonnummer</label>
          <input v-model="data.phone" v-bind="ariaInput" name="phone" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-gridh">
          <label for="message" class="mb-16">Bericht</label>
          <textarea v-model="data.message" v-bind="ariaInput" name="message" label="Bericht" class="form-control mb-0" placeholder="Plaats hier je Bericht..."> </textarea>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12 mb-gridh">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" required="required" type="checkbox" value="" id="checkakkoord" />
            <label class="custom-control-label" for="checkakkoord">Ik ga akkoord met het <a href="/privacy-policy" target="_blank" :class="{ 'text-gold': theme === 'gold', 'text-primary': theme === 'light' }">privacybeleid</a> van Copandi </label>
          </div>
        </div>

        <div class="col-12 d-flex align-items-center">
          <button type="submit" class="btn d-flex align-items-center justify-content-center" :class="{ 'btn-gold': theme === 'gold', 'btn-primary': theme === 'light' }">
            Verstuur
            <el-loader class="ml-16" :loading="loading"></el-loader>
          </button>
          <div v-if="success && !loading" class="ml-40">
            <div>{{ successMessage }}</div>
          </div>
          <div v-if="errors && errors.length > 0" class="ml-40">
            <div class="text-danger" v-for="(error, key) in errors" :key="`error_${key}`">{{ error }}</div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },

  props: {
    env: {
      required: true,
    },
    theme: {
      required: false,
      default: 'light',
    },
  },

  data() {
    return {
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
      },
      success: false,
      successMessage: 'Bedankt voor je bericht. We nemen zo snel mogelijk contact met je op.',
      loading: false,
      errors: [],
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      axios
        .post('/api/general-contact', this.data)
        .then((res) => {
          this.successMessage = `Bedankt we contacteren je binnen de 24u op het nummer ${this.data.phone} en/of ${this.data.email}.`;
          this.success = true;
          this.loading = false;
          this.errors = [];
          this.data = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contact: '',
            message: '',
          };
          this.$refs.form.reset();
        })
        .catch((e) => {
          this.success = false;
          this.loading = false;
          this.errors = ['Er ging iets mis tijdens het verstuuren van je verzoek.'];
        });
    },
    async verifyAndSubmit() {
      if (this.env === 'local') {
        this.onSubmit();
        return;
      }
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha('login');

      if (token) {
        this.loading = true;
        axios
          .post('/api/verify/token', { token: token })
          .then((res) => {
            if (res.data && res.data.success && res.data.score >= 0.5) {
              this.onSubmit();
            }
          })
          .catch(() => {
            this.success = false;
            this.loading = false;
          });
      }
    },
  },
};
</script>
