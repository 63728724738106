var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usp-slider-new" }, [
    _c(
      "div",
      { ref: "slider", class: _vm.wrapperClass },
      _vm._l(_vm.items, function (column, key) {
        return _c("div", { key: key }, [
          _c("img", {
            staticClass: "mb-18 icon lazyload",
            class: _vm.iconSize,
            attrs: { "data-src": column.icon.url, alt: "" },
          }),
          _vm._v(" "),
          _c("h4", {
            staticClass: "mb-24",
            domProps: { innerHTML: _vm._s(column.title) },
          }),
          _vm._v(" "),
          column.description
            ? _c("p", {
                staticClass: "mb-0",
                domProps: { innerHTML: _vm._s(column.description) },
              })
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }