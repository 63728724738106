import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    props: {
        page: {
            default: 1,
        },
        perPage: {
            default: 3,
        },
        perRow: {
            default: 3,
        },
        canLoadMore: {
            default: false,
        },
        mobileSlider: {
            default: true,
        },
        wrapperClass: {
            default: 'mb-0',
        },
        mode: {
            default: 'cards',
        },
        ctaCards: {
            required: false,
            default: false,
        },
        animate: {
            required: false,
            default: false,
        },
        params: {
            default: () => ({
                per_page: 9,
                page: 0,
                type: "",
                bedrooms: "",
                sort: "putOnlineDateTime,desc",
                cities: "",
                price: false,
            }),
        },
    },

    data() {
        return {
            localPage: parseInt(this.page),
            localPerPage: parseInt(this.perPage),
            localPerRow: parseInt(this.perRow),
            multiLine: false,
            tns: null,
            serverError: false,
            loading: false,
            estateInfo: {
                data: [],
                next_page_url: '/api/v2/estates-gold',
                total: 0,
                current_page: 1,
                last_page: 1,
            },
            pageData: {
                current: 0,
                next: 1,
                last: 1,
                total: 0
            }
        };
    },

    mounted() {
        if (this.localPerPage > this.localPerRow) {
            this.multiLine = true;
        }

        this.fetchEstates();
    },

    methods: {
        fetchEstates: _.debounce(function () {
            this.serverError = false;
            this.loading = true;

            axios
                .get(this.estateInfo.next_page_url, {
                    params: {
                        page: this.pageData.current,
                        per_page: this.localPerPage,
                        order: this.params.order,
                        sort: "putOnlineDateTime,asc",
                        ...this.params,
                    }
                })
                .then(estateInfo => {
                    this.estateInfo.data = [...this.estateInfo.data, ...estateInfo.data.data];
                    this.pageData.current = estateInfo.data.page;
                    this.pageData.next = this.pageData.current + 1;
                    this.pageData.total = parseInt(estateInfo.data.total, 10);
                    this.pageData.last = Math.round(this.pageData.total / this.localPerPage);

                    this.$emit('total-updated', estateInfo.data.total);
                    this.loading = false;
                    this.initSlider();

                })
                .catch(e => {
                    this.serverError = true;
                    this.loading = false;
                });
        }, 500),

        loadMore() {
            this.params.page = this.pageData.next;
            this.fetchEstates();
        },
        initSlider() {
            if (this.multiLine) {
                return;
            }
            const self = this;
            let width = self.$refs.slider.clientWidth;
            this.$nextTick(() => {
                self.tns = tns({
                    container: self.$refs.slider,
                    gutter: 32,
                    controls: false,
                    touch: true,
                    nav: false,
                    loop: false,
                    rewind: true,
                    preventScrollOnTouch: 'auto',
                    items: 1,
                    fixedWidth: width * 0.9,
                    autoWidth: false,
                    responsive: {
                        960: {
                            fixedWidth: false,
                            autoWidth: true,
                            items: self.localPerRow,
                            arrowKeys: true,
                            mouseDrag: true,
                        },
                    },
                });
            });
        },
    },

    computed: {
        multiLineClass() {
            if (!this.multiLine) {
                return '';
            }
            if (this.localPerRow === 2) {
                return 'col-12 col-lg-6 mb-grid';
            }
            if (this.localPerRow === 3) {
                return 'col-12 col-lg-6 col-xl-4 mb-grid';
            }
            if (this.localPerRow === 3) {
                return 'col-12 col-lg-6 col-xl-3 mb-grid';
            }
            return 'col-12';
        },
        markers() {
            return this.estateInfo.data.map(estate => {
                return {
                    lat: estate.location_geo_latitude, lng: estate.location_geo_longitude,
                    slug: estate.slug,
                    status: estate.status,
                    is_project: estate.is_project,
                    negotiation: estate.negotiation
                };
            });
        },
    },
    watch: {
        params: {
            handler(newValue, oldValue) {
                this.estateInfo = {
                    data: [],
                    next_page_url: '/api/v2/estates-gold',
                    total: 0,
                    current_page: 1,
                    last_page: 1,
                };
                this.localPage = 1;
                this.fetchEstates();
            },
            deep: true,
        },
    },
};
