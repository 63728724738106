<style lang="scss" scoped>
.setle-section {
}
</style>
<template>
  <section v-if="setleReferral" id="setle" class="container mb-40 mb-lg-80 mb-xl-120">
    <div class="row">
      <div class="col-12 col-lg-8 col-xl-6 mx-auto">
        <div class="setle-section text-center">
          <h4 class="mb-32 ff-serif">Setle</h4>
          <p class="text-uppercase font-weight-bold text-primary mb-16 caption">KRIJG EEN HELDER BEELD VAN JE VASTGOEDKOSTEN</p>
          <p class="mb-24 text-gray">Met onze digitale oplossing breng je eenvoudig het volledig kostenplaatje van dit vastgoed in kaart. Van renovatie- of notariskosten tot bijkomende kosten: Setle voorkomt verrassingen in de zoektocht naar jouw droomwoning</p>
          <a :href="setleReferral" target="_blank" class="btn btn-primary">Bereken je kosten</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    setleReferral: {
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      success: false,
      valid: false,
      referral: '',
    };
  },

  mounted() {
    //this.getData();
  },

  methods: {
    getData() {
      axios
        .get(`/api/setle/${this.estateId}`)
        .then(res => {
          this.success = true;
          this.loading = false;
          if (res.data && res.data.success) {
            this.valid = true;
            this.referral = res.data.referral;
          }
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
  },
};
</script>
