<style lang="scss" scoped>
.gold-estate-search {
  display: inline-flex;
  align-items: center;
  min-width: 200px;
  height: 20px;

  span {
    display: inline-flex;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  ::v-deep svg {
    width: 16px;
    height: 16px;
  }

  input {
    color: #fff;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    font-weight: 300;
    transition: color 0.2s;
    background: transparent;
    border: none;

    &::placeholder {
      color: #fff;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>

<template>
  <div class="gold-estate-search">
    <span v-html="svg('search')" class="mr-16" @click="submitSearch"></span>
    <input type="text" v-model="searchTerm" @keyup.enter="submitSearch" placeholder="Zoek pand" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: '',
    };
  },

  methods: {
    submitSearch() {
      window.location.href = `/resultaten?search=${this.searchTerm}`;
    },
  },
};
</script>
