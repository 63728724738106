import { render, staticRenderFns } from "./print-layout.vue?vue&type=template&id=2de1c115&scoped=true&"
import script from "./print-layout.vue?vue&type=script&lang=js&"
export * from "./print-layout.vue?vue&type=script&lang=js&"
import style0 from "./print-layout.vue?vue&type=style&index=0&id=2de1c115&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de1c115",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frvanhove/Projects/copandi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2de1c115')) {
      api.createRecord('2de1c115', component.options)
    } else {
      api.reload('2de1c115', component.options)
    }
    module.hot.accept("./print-layout.vue?vue&type=template&id=2de1c115&scoped=true&", function () {
      api.rerender('2de1c115', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/print-layout.vue"
export default component.exports