<template>
  <div :class="wrapperClass" ref="slider">
    <div v-for="office in offices" :key="office.id" data-aos="fade-up">
      <a class="office-card" :href="office.relative_link">
        <div class="office-image mb-24" :style="`background-image: url(${office.acf.image.url});  padding-top: 66%;`"></div>
        <h5 class="mb-16 office-title" v-html="office.acf.title"></h5>
        <p class="mb-24 text-gray office-address soft-underline">
          {{ office.acf.address }}
        </p>
        <div class="link">
          Meer info
          <span v-html="svg('arrow-right')"></span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    offices: {
      default: () => [],
    },
  },

  data() {
    return {
      tns: null,
    };
  },

  mounted() {
    this.initSlider();
  },

  methods: {
    initSlider() {
      const self = this;
      let width = self.$refs.slider.clientWidth;
      this.$nextTick(() => {
        self.tns = tns({
          container: self.$refs.slider,
          gutter: 32,
          controls: false,
          touch: true,
          nav: false,
          loop: false,
          rewind: true,
          preventScrollOnTouch: 'auto',
          items: 1,
          fixedWidth: width * 0.9,
          autoWidth: false,
          responsive: {
            960: {
              fixedWidth: false,
              autoWidth: true,
              items: 3,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
  },

  computed: {
    multiLineClass() {
      return 'col-12 col-lg-6 col-xl-3 mb-grid';
    },
  },
};
</script>
