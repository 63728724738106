<style lang="scss" scoped>
.estate-card {
  cursor: default;
  text-decoration: none;
  display: block;
  background-color: #000;

  &:hover {
    .estate-image {
      img {
        width: 107%;
        height: 107%;
      }
    }

    .estate-bottom {
      border-color: #bba876;
    }
  }

  .estate-title {
  }
  &:not(.nc) {
    cursor: pointer;
  }

  .estate-image {
    height: 240px;
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      object-fit: cover;
    }

    .tag {
      position: absolute;
      color: #bba876;
      background: #000;
      line-height: 0.9375rem;
      padding: 12px 16px;
      bottom: 24px;
      left: 24px;
    }

    .tag span {
      position: relative;
      font-size: 1rem;
      letter-spacing: 0.125rem;
      line-height: 0.9375rem;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .estate-bottom {
    transition: border-color 0.3s;
    border: 1px solid rgba(232, 232, 231, 0.2);
    border-top: none;
  }

  .estate-footer {
    display: flex;
    align-items: center;
    .icon {
      height: 20px;
      width: 20px;
    }
    .dot {
      height: 12px;
      width: 0.5px;
      background-color: #888888;
      margin: 0 14px;
    }

    .estate-line {
      color: #888888;
      font-size: 0.6rem;
      font-weight: 300;
      letter-spacing: 0.09375rem;
      text-transform: uppercase;
      line-height: 0.8125rem;
      @media (min-width: 768px) {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
<template>
  <a v-if="data" :href="computedRoute" class="estate-card" :class="[wrapperClass, { nc: data.status == 'sold' || data.status == 'rented' || data.status == 'option' }]">
    <div class="estate-image">
      <img :data-srcset="imageStyle" class="lazyload" />
      <div v-if="!data.displayPrice" class="tag price">Prijs op aanvraag</div>
      <div class="tag sold" v-else-if="[3, 17].includes(data.purposeStatus.id)"><span>Verkocht</span></div>
      <div class="tag rented" v-else-if="[4].includes(data.purposeStatus.id)"><span>Verhuurd</span></div>
      <div class="tag option" v-else-if="[12, 13, 21].includes(data.purposeStatus.id)"><span>In optie</span></div>
      <div class="tag option" v-else-if="[5, 6, 16].includes(data.purposeStatus.id)"><span>Bod aanvaard</span></div>
      <div class="tag price" v-else-if="(data.price && data.status.id === 1) || (data.price && data.status.id === 2)">
        <span v-if="data.price != 1">{{ formatPrice(data.price, 'EUR') }}</span>
      </div>
      <div class="tag viewday" v-else-if="data.status !== 'sold' && data.status !== 'option' && data.status !== 'rented' && isViewDay">
        <span>{{ isViewDay }}</span>
      </div>
    </div>

    <div class="d-flex estate-bottom flex-column px-24 py-32">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <h5 v-if="!data.project_id" class="estate-title text-light-alt h3 d-flex align-items-center mb-12"><span class="d-inline-flex mr-12" v-html="svg('location-marker-gold')"></span>{{ data.city }}</h5>
          <h5 v-else class="estate-title text-secondary h3"><span class="d-inline-flex align-items-center mr-12" v-html="svg('location-marker-gold')"></span>{{ data.description_title }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="estate-footer col-12 d-flex align-items-center">
          <div v-if="data.groundArea" class="estate-line d-flex justify-content-center align-items-center">
            <span class="d-flex justify-content-center align-items-center"> {{ data.groundArea }}m<sup>2</sup></span>
          </div>

          <div v-if="data.area && data.groundArea" class="dot"></div>

          <div v-if="data.area" class="estate-line d-flex justify-content-center align-items-center">
            <span class="d-flex justify-content-center align-items-center"> {{ data.area }}m<sup>2</sup></span>
          </div>

          <div v-if="data.rooms && data.area" class="dot"></div>

          <div v-if="data.rooms" class="estate-line d-flex justify-content-center align-items-center">{{ data.rooms }} slaapkamers</div>

          <div v-if="data.box && data.is_project" class="dot"></div>
          <div v-if="data.box && data.is_project" class="estate-line">Blok {{ data.box }}</div>
          <div v-if="data.floor && data.is_project" class="dot"></div>
          <div v-if="data.floor && data.is_project" class="estate-line">Vloer {{ data.floor }}</div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import estateCard from '@/mixins/estate-card-gold';

export default {
  mixins: [estateCard],
};
</script>
