<style lang="scss" scoped>
.select {
  display: inline-flex;
  border-radius: 38px;
  background-color: #ffffff;
  gap: 8px;
  height: auto;
  flex-flow: column;
  @media (min-width: 768px) {
    padding: 8px;
    height: 54px;
    border: 1px solid #d9d9d9;
    flex-flow: row;
  }
  .item {
    width: 170px;
    border-radius: 30px;
    display: flex;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    transform: background 0.15s ease-in-out;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    padding: 8px;
      justify-content: center;
    @media (min-width: 768px) {
      border: none;
      align-items: center;
      text-align: center;
    }
    &:hover {
      background-color: #f7f7f7;
    }
    &.active {
      background: #83c056;
      color: #fff;
      font-weight: 600; // Old bold
    }
  }
}
label {
  color: var(--gray);
  font-family: CoreSansC; // Old Lato
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}
</style>

<template>
  <div>
    <label class="d-block">Staat</label>
    <div class="select">
      <div class="item" v-for="item in items" :key="item.name" :class="{ active: localValue.value === item.value }" @click="handleInput(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String },
    value: { type: String },
  },

  data: () => {
    return {
      items: [
        { name: 'Nieuwstaat', value: 'new_or_recently_renovated' },
        { name: 'Goed onderhouden', value: 'well_maintained' },
        { name: 'Renovatie nodig', value: 'renovation_needed' },
      ],
      localValue: '',
    };
  },

  mounted() {
    if (this.value) {
      this.localValue = this.items.find((v) => v.value === this.value);
    }
  },

  methods: {
    handleInput(value) {
      this.localValue = value;
      this.$emit('input', this.localValue.value);
    },
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        return;
      }
      this.localValue = this.items.find((v) => v.value === newValue);
    },
  },
};
</script>
