import { render, staticRenderFns } from "./properties-result.vue?vue&type=template&id=f364c088&scoped=true&"
import script from "./properties-result.vue?vue&type=script&lang=js&"
export * from "./properties-result.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f364c088",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frvanhove/Projects/copandi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f364c088')) {
      api.createRecord('f364c088', component.options)
    } else {
      api.reload('f364c088', component.options)
    }
    module.hot.accept("./properties-result.vue?vue&type=template&id=f364c088&scoped=true&", function () {
      api.rerender('f364c088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/properties-result.vue"
export default component.exports