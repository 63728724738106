<style lang="scss" scoped>
.gold-estate-card {
  display: block;
  position: relative;
  padding-top: 100%;

  &:hover {
    .image-wrapper {
      img {
        width: 107%;
        height: 107%;
      }
    }
  }

  .image-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      background: linear-gradient(243.43deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.3s;
    }
  }

  .content-wrapper {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;

    .name {
      display: inline-flex;
      align-items: center;
      font-size: 2rem;
      font-weight: 600;
      color: #e8e8e7;
      margin-bottom: 12px;

      span {
        margin-right: 12px;
        display: inline-flex;
        align-items: center;
      }
    }

    .address {
      font-size: 0.75rem;
      font-weight: 300;
      letter-spacing: 0.09375rem;
      color: #e8e8e7;
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <a :href="link" class="gold-estate-card">
    <div class="image-wrapper">
      <img :src="image" :alt="name" />
    </div>
    <div class="content-wrapper">
      <p class="name"><span v-html="svg('location-marker-gold')"></span>{{ name }}</p>
      <p class="address">{{ address }}</p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
