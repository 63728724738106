<style lang="scss">
.plan-slider {
  position: relative;
  .tns-controls {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0px;
    height: 46px;
    margin: auto;
    bottom: -70px;
    @media (min-width: 992px) {
      width: calc(100% + 92px + 40px);
      left: -86px;
      top: 0;
      bottom: 0;
    }
    button {
      height: 46px;
      width: 46px;
      background-color: #f7f7f7;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      img {
        transition: all 0.15s ease-in-out;
        height: 16px;
      }
      &:hover {
        img {
          height: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div :class="wrapperClass" class="plan-slider">
    <div ref="slider">
      <div v-for="plan in plans" :key="plan.id">
        <a :href="`${plan.acf.is_gold ? 'https://gold.copandi.be' : plan.relative_link}`" class="plan p-20 py-lg-80 d-flex flex-column align-items-center text-center" :class="{ popular: plan.acf.is_popular, gold: plan.acf.is_gold }">
          <img :data-src="plan.acf.logo.url" class="lazyload plan-image mb-40" alt="" />
          <h4 class="mb-16 text-secondary">{{ plan.acf.title }}</h4>
          <div v-if="plan.acf.price && plan.acf.price !== NaN" class="mb-56">
            <h5 class="text-primary font-weight-normal mb-0">{{ plan.acf.price }} <small v-if="plan.acf.price !== 'Op maat'">excl. btw</small></h5>
            <div v-if="plan.acf.price !== 'Op maat'">
              <small class="text-gray">€ {{ getPriceIncl(plan.acf.price) }} incl. btw</small>
            </div>
            <div v-else>
                <small>‎</small>
            </div>
          </div>
          <div class="mb-56 text-gray text-left text-lg-center">
            <p class="mb-8" v-for="usp in plan.acf.usp_list" v-html="usp.usp"></p>
          </div>
          <button class="btn btn-primary border-50">Ontdek</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    plans: {
      default: () => [],
    },
  },

  data() {
    return {
      tns: null,
    };
  },

  mounted() {
    this.initSlider();
  },

  methods: {
    initSlider() {
      const self = this;
      let width = self.$refs.slider.clientWidth;
      this.$nextTick(() => {
        self.tns = tns({
          container: self.$refs.slider,
          gutter: 32,
          controls: true,
          controlsText: ["<img src='/images/chevron-left.svg'/>", "<img src='/images/chevron-right.svg'/>"],
          touch: true,
          nav: false,
          loop: false,
          rewind: true,
          preventScrollOnTouch: 'auto',
          items: 1,
          fixedWidth: width * 0.85,
          autoWidth: false,
          responsive: {
            960: {
              controls: false,
              fixedWidth: false,
              autoWidth: true,
              items: 3,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
    getPriceEx(price) {
      const p = price.replace('.', '').replace('€', '');
      return Math.round(Number(p) / 1.21);
    },
    getPriceIncl(price) {
      if (price === 'Op maat') {
        return '';
      }

      const p = price.replace('.', '').replace('€', '');
      return Math.round(Number(p) * 1.21);
    },
  },
};
</script>
