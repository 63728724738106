<style lang="scss" scoped>
.gold-office-card {
    display: block;
    position: relative;
    padding-top: 100%;

    &:hover {
        .image-wrapper {
            img {
                width: 107%;
                height: 107%;
            }
        }
    }

    .image-wrapper {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            background: linear-gradient(243.43deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.3s;
        }
    }

    .content-wrapper {
        padding: 32px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        z-index: 10;
        left: 0;
        width: 100%;
        height: 100%;

        .name {
            display: inline-flex;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 600;
            color: #e8e8e7;
            margin-bottom: 12px;
            @media (min-width: 768px) {
                font-size: 2rem;
            }
            span {
                margin-right: 12px;
                display: inline-flex;
                align-items: center;
            }
        }

        .address {
            font-size: 0.75rem;
            font-weight: 300;
            letter-spacing: 0.09375rem;
            color: #e8e8e7;
            margin-bottom: 0;
        }
    }
}
</style>
<template>
  <div :class="wrapperClass" ref="slider">
    <div v-for="office in offices" :key="office.id" data-aos="fade-up">
      <a class="gold-office-card" :href="office.relative_link">
          <div class="image-wrapper">
              <img :src="office.acf.image.url" :alt="office.acf.image.name" />
          </div>
          <div class="content-wrapper">
              <p class="name"><span v-html="svg('location-marker-gold')"></span>{{ office.acf.title }}</p>
              <p class="address">{{ office.acf.address }}</p>
          </div>
      </a>
    </div>
  </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    offices: {
      default: () => [],
    },
  },

  data() {
    return {
      tns: null,
    };
  },

  mounted() {
    this.initSlider();
  },

  methods: {
    initSlider() {
      const self = this;
      let width = self.$refs.slider.clientWidth;
      this.$nextTick(() => {
        self.tns = tns({
          container: self.$refs.slider,
          gutter: 32,
          controls: false,
          touch: true,
          nav: false,
          loop: false,
          rewind: true,
          preventScrollOnTouch: 'auto',
          items: 1,
          fixedWidth: width * 0.9,
          autoWidth: false,
          responsive: {
            960: {
              fixedWidth: false,
              autoWidth: true,
              items: 3,
              arrowKeys: true,
              mouseDrag: true,
            },
          },
        });
      });
    },
  },

  computed: {
    multiLineClass() {
      return 'col-12 col-lg-6 col-xl-3 mb-grid';
    },
  },
};
</script>
