var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "slider",
        class: [_vm.wrapperClass, { "row mb-ngrid": _vm.multiLine }],
      },
      [
        !_vm.serverError
          ? [
              _vm.estateInfo.data.length > 0
                ? [
                    _vm._l(_vm.estateInfo.data, function (estate, key) {
                      return _vm.mode === "cards"
                        ? [
                            key === 4 || key === 28 || key === 52 || key === 76
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.multiLineClass,
                                    attrs: {
                                      "data-aos": _vm.animate
                                        ? "fade-up"
                                        : undefined,
                                    },
                                  },
                                  [
                                    _c("gold-estate-cta-card", {
                                      attrs: { data: _vm.ctaCards[0] },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            key === 12 || key === 36 || key === 60 || key === 84
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.multiLineClass,
                                    attrs: {
                                      "data-aos": _vm.animate
                                        ? "fade-up"
                                        : undefined,
                                    },
                                  },
                                  [
                                    _c("gold-estate-cta-card", {
                                      attrs: {
                                        data: _vm.ctaCards[1],
                                        background: "bg-gray-light",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            key === 20 || key === 44 || key === 68 || key === 92
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.multiLineClass,
                                    attrs: {
                                      "data-aos": _vm.animate
                                        ? "fade-up"
                                        : undefined,
                                    },
                                  },
                                  [
                                    _c("gold-estate-cta-card", {
                                      attrs: { data: _vm.ctaCards[2] },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            estate.pictures
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.multiLineClass,
                                    attrs: {
                                      "data-aos": _vm.animate
                                        ? "fade-up"
                                        : undefined,
                                    },
                                  },
                                  [
                                    _c("gold-estate-card", {
                                      attrs: { data: estate },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.mode === "list"
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-12 table-responsive" },
                            [
                              _c("table", { staticClass: "table" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.estateInfo.data,
                                    function (estate) {
                                      return _c("tr", { key: estate.id }, [
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              width: "400",
                                              "data-label": "Naam",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(estate.description_title)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        estate.price_amount &&
                                        estate.price_hidden === "0" &&
                                        estate.status !== "sold" &&
                                        estate.status !== "rented"
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      estate.price_amount,
                                                      estate.price_currency
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : estate.status === "sold"
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [_vm._v("Verkocht")]
                                            )
                                          : estate.status === "rented"
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [_vm._v("Verhuurd")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: { "data-label": "Opp." },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatRoomSize(
                                                  estate.sizes_liveable_area_size,
                                                  estate.sizes_liveable_area_unit
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              "data-label": "Slaapkamers",
                                            },
                                          },
                                          [_vm._v(_vm._s(estate.bedrooms))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              "data-label": "Verdieping",
                                            },
                                          },
                                          [_vm._v(_vm._s(estate.floor))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: { "data-label": "Blok" },
                                          },
                                          [_vm._v(_vm._s(estate.box))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { attrs: { "data-label": "" } },
                                          [
                                            !estate.is_project
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary float-right",
                                                    attrs: {
                                                      href: _vm.route(
                                                        "resultaten.show",
                                                        { slug: estate.slug }
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Ik ben geïnteresseerd"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary float-right",
                                                    attrs: {
                                                      href: _vm.route(
                                                        "nieuwbouw.show",
                                                        { slug: estate.slug }
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Ik ben geïnteresseerd"
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "map"
                      ? _c(
                          "div",
                          { staticClass: "col-12 col-lg-8" },
                          [
                            _c("google-map", {
                              attrs: {
                                markers: _vm.markers,
                                coordinates: false,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "map"
                      ? _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c(
                            "div",
                            { staticClass: "estate-slider" },
                            [
                              _vm._l(_vm.estateInfo.data, function (estate) {
                                return _c("gold-estate-card", {
                                  key: estate.id,
                                  attrs: {
                                    data: estate,
                                    wrapperClass: "mb-grid",
                                  },
                                })
                              }),
                              _vm._v(" "),
                              _vm.canLoadMore &&
                              _vm.estateInfo.last_page !==
                                _vm.estateInfo.current_page
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn-outline-primary",
                                          on: { click: _vm.loadMore },
                                        },
                                        [_vm._v("Toon meer panden")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]
                : !_vm.loading
                ? _c("div", { staticClass: "row w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-50 mx-auto text-center",
                        staticStyle: {
                          background: "rgba(255,255,255,0.07)",
                          color: "#fff",
                          padding: "32px",
                        },
                      },
                      [
                        _vm._v(
                          "\n          Er konden geen panden gevonden worden\n        "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "row w-100" }, [_vm._m(1)])
                : _vm._e(),
            ]
          : _c("div", { staticClass: "row w-100" }, [
              _c(
                "div",
                {
                  staticClass: "w-50 mx-auto text-center",
                  staticStyle: {
                    background: "rgba(255,255,255,0.07)",
                    color: "#fff",
                    padding: "32px",
                  },
                },
                [_vm._v("\n        Er ging iets mis\n      ")]
              ),
            ]),
      ],
      2
    ),
    _vm._v(" "),
    _vm.canLoadMore &&
    _vm.pageData.current !== _vm.pageData.last - 1 &&
    (_vm.mode === "cards" || _vm.mode === "list")
      ? _c("div", { staticClass: "row mt-grid" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn btn-outline-primary",
                  on: { click: _vm.loadMore },
                },
                [_vm._v("Toon meer panden")]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "400", scope: "col" } }, [_vm._v("Naam")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Vraagprijs"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Opp."),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Slaapkamers"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Verdieping"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Blok"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-50 mx-auto text-center" }, [
      _c("div", { staticClass: "lds-ellipsis" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }