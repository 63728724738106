<style lang="scss" scoped>
.item-title {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 32px;
  font-weight: 600; // Old bold
  letter-spacing: 0;
  line-height: 39px;
  margin-bottom: 17px;
}

.collapse-panel {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 44px;
  cursor: pointer;
  position: relative;
  &:hover {
    .collapse-title {
      color: #83c056;
    }
  }
}
.collapse-panel:not(:first-child) {
  border-top: 1px solid #e1e1e1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.icon {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 0;
  top: 20;
  display: block;
  &:before {
    content: '';
    height: 2px;
    width: 16px;
    background: #83c056;
    display: block;
    position: absolute;
    top: 7px;
  }
  &.open {
    &:before {
      background: #000000;
    }
  }
  &:not(.open) {
    &:after {
      content: '';
      width: 2px;
      height: 16px;
      background: #83c056;
      display: block;
      position: absolute;
      left: 7px;
    }
  }
}

.collapse-title {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  transition: color 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  &.open {
    color: #83c056;
    margin-bottom: 10px;
  }
  .counter {
    color: #83c056;
    font-family: CoreSansC; // Old Lato
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 16px;
  }
}

.collapse-description {
  color: var(--gray);
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  transition: opacity 0.15s ease-in-out, height 0.15s ease-in-out;
  overflow: hidden;
  &.open {
    height: auto;
  }
  &:not(.open) {
    height: 0;
  }
}

.read-more {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 15px;
  font-weight: 600; // Old 900
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 20px;
  display: block;
  &:hover {
    text-decoration: none;
    ::v-deep svg {
      margin-left: 20px;
      stroke: #83c056;
    }
  }
  ::v-deep svg {
    transition: margin-left 0.15s ease-in-out;
    margin-left: 10px;
    stroke: #83c056;
  }
}
</style>

<template>
  <div>
    <div v-if="item.title" class="item-title">{{ item.title }}</div>
    <div>
      <div v-for="(faq, key) in item.items" :key="key" class="collapse-panel" @click="toggleKey(key)">
        <div class="icon" :class="{ open: active === key }"></div>
        <div class="collapse-title" :class="{ open: active === key }">
          <div v-if="item.show_number" class="counter"><template v-if="(Number(startCount) + Number(key)) <= 9">0</template>{{ Number(startCount) + Number(key) }}</div>
          {{ faq.post_title }}
        </div>
        <div class="collapse-description" :class="{ open: active === key }" v-html="faq.post_content"></div>
      </div>
    </div>
    <a v-if="item.link && item.link.url !== ''" :href="item.link.url" class="read-more">
      {{ item.link.title }}
      <span v-html="svg('arrow-right-narrow')"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => {
        return {
          title: '',
          items: [],
        };
      },
    },

    startCount: {
      default: 1,
    },
  },
  data() {
    return {
      active: -1,
    };
  },

  mounted() {},

  methods: {
    toggleKey(key) {
      if (this.active === key) {
        this.active = -1;
      } else {
        this.active = key;
      }
    },
  },
};
</script>
