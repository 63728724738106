var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "gold-estate-card", attrs: { href: _vm.link } },
    [
      _c("div", { staticClass: "image-wrapper" }, [
        _c("img", { attrs: { src: _vm.image, alt: _vm.name } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper" }, [
        _c("p", { staticClass: "name" }, [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.svg("location-marker-gold")) },
          }),
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "address" }, [_vm._v(_vm._s(_vm.address))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }