var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        {
          staticClass: "estate-line",
          class: [_vm.type === "epc" ? _vm.data + " epc-line bw" : ""],
        },
        [
          _vm.type === "rooms"
            ? [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("Slaapkamer", "card-icon fill-gray")
                    ),
                  },
                }),
                _vm._v("\n    " + _vm._s(_vm.data) + "\n  "),
              ]
            : _vm.type === "ground"
            ? [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg(
                        "PerceelOppervlakte",
                        "card-icon stroke-gray fill-none"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(Math.round(_vm.data)) + "m"),
                  _c("sup", [_vm._v("2")]),
                ]),
              ]
            : _vm.type === "area"
            ? [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("house", "card-icon stroke-gray")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(Math.round(_vm.data)) + "m"),
                  _c("sup", [_vm._v("2")]),
                ]),
              ]
            : _vm.type === "box"
            ? [_vm._v("Blok " + _vm._s(_vm.data))]
            : _vm.type === "floor"
            ? [_vm._v("Vloer " + _vm._s(_vm.data))]
            : _vm.type === "epc"
            ? [_vm._v(_vm._s(_vm.data))]
            : void 0,
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }