var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        {
          key: item,
          staticClass: "item",
          class: { active: _vm.localValue === item },
          on: {
            click: function ($event) {
              return _vm.handleInput(item)
            },
          },
        },
        [_c("div", {}, [_vm._v(_vm._s(_vm.translations[item]))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }