var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _c("div", { staticClass: "nav" }, [
      _c(
        "div",
        {
          staticClass: "nav-item",
          class: [{ active: _vm.isActive === "schatting" }, _vm.bgTheme],
          on: {
            click: function ($event) {
              _vm.isActive = "schatting"
            },
          },
        },
        [_vm._v("schatting")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nav-item",
          class: [{ active: _vm.isActive === "kopen" }, _vm.bgTheme],
          on: {
            click: function ($event) {
              _vm.isActive = "kopen"
            },
          },
        },
        [_vm._v("kopen")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nav-item",
          class: [{ active: _vm.isActive === "huren" }, _vm.bgTheme],
          on: {
            click: function ($event) {
              _vm.isActive = "huren"
            },
          },
        },
        [_vm._v("huren")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "hero-form",
        class: { "w-800": ["kopen", "huren"].includes(_vm.isActive) },
      },
      [
        _vm.isActive === "schatting"
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.svg("marker-hollow", "icon")),
                      },
                    }),
                    _vm._v(" "),
                    _c("vue-google-autocomplete", {
                      attrs: {
                        id: "adres",
                        classname: "w-100",
                        placeholder: "Wat is het adres van de woning?",
                        country: "be",
                      },
                      on: { placechanged: _vm.getAddressData },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [_vm._v("Gratis schatting")]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive === "kopen"
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.svg("marker-hollow", "icon")),
                      },
                    }),
                    _vm._v(" "),
                    _vm.advanced
                      ? _c("v-select", {
                          staticClass: "form-select",
                          attrs: {
                            options: _vm.regionOptions,
                            placeholder: "Gemeente of Provincie…",
                          },
                          model: {
                            value: _vm.data.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "region", $$v)
                            },
                            expression: "data.region",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "divider d-none d-lg-block" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.svg("house-outline", "icon ml-lg-0")
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.advanced
                      ? _c("v-select", {
                          staticClass: "form-select last",
                          attrs: {
                            options: _vm.typeOptions,
                            placeholder: "Pandtype",
                          },
                          model: {
                            value: _vm.data.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "type", $$v)
                            },
                            expression: "data.type",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [_vm._v("zoeken")]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isActive === "huren"
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.svg("marker-hollow", "icon")),
                      },
                    }),
                    _vm._v(" "),
                    _vm.advanced
                      ? _c("v-select", {
                          staticClass: "form-select",
                          attrs: {
                            options: _vm.regionOptions,
                            placeholder: "Gemeente of Provincie…",
                          },
                          model: {
                            value: _vm.data.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "region", $$v)
                            },
                            expression: "data.region",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "divider d-none d-lg-block" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.svg("house-outline", "icon ml-lg-0")
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.advanced
                      ? _c("v-select", {
                          staticClass: "form-select last",
                          attrs: {
                            options: _vm.typeOptions,
                            placeholder: "Pandtype",
                          },
                          model: {
                            value: _vm.data.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "type", $$v)
                            },
                            expression: "data.type",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn", attrs: { type: "submit" } },
                  [_vm._v("Zoeken")]
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }