var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.videoType === "youtube-embed"
    ? _c("iframe", {
        staticClass: "video-wrapper",
        attrs: {
          src: _vm.compiledVideoUrl,
          frameborder: "0",
          allow:
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      })
    : _vm.videoType === "default"
    ? _c("video", { staticClass: "video-wrapper", attrs: { controls: "" } }, [
        _c("source", {
          attrs: { src: _vm.compiledVideoUrl, type: "video/mp4" },
        }),
        _vm._v("\n  Your browser does not support the video tag.\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }