<style lang="scss" scoped>
.select {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  .item {
    height: 39px;
    min-width: 158px;
    border: 1px solid #e1e1e1;
    border-radius: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    transition: box-shadow 0.15s ease-in-out, font-weight 0.15s ease-in-out;
    white-space: no-wrap;
    padding-left: 16px;
    padding-right: 16px;
    &.active,
    &:hover {
      box-shadow: 0 0 0 2px #83c056;
      border-color: transparent;
    }
    &.active {
      font-weight: 600; // Old bold
    }
  }
}
</style>

<template>
  <div class="select">
    <div class="item" v-for="item in items" :key="item" :class="{ active: localValue === item }" @click="handleInput(item)">
      <div class="">{{ translations[item] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    items: { default: () => [] },
  },

  data: () => {
    return {
      localValue: '',
      translations: {
        apartment_normal: 'Appartement',
        apartment_studio: 'Studio',
        apartment_penthouse: 'Penthouse',
        apartment_maisonette: 'Duplex',
        house_detached: 'Open',
        house_semi_detached: 'Halfopen',
        house_row_middle: 'Gesloten',
        asap: 'Zo snel mogelijk',
        three_to_six: 'Binnen 3 tot 6 maanden',
        six_or_more: 'Over meer dan 6 maanden',
        not_for_sale: 'Ik wil niet verkopen',
      },
    };
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    handleInput(value) {
      this.localValue = value;
      this.$emit('input', this.localValue);
    },
  },

  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
