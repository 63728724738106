var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "slider",
        class: [_vm.wrapperClass, { "row mb-ngrid": _vm.multiLine }],
      },
      [
        !_vm.serverError
          ? [
              _vm.estateInfo.data.length > 0
                ? [
                    _vm._l(_vm.estateInfo.data, function (estate, key) {
                      return _vm.mode === "cards"
                        ? [
                            key % 8 === 4
                              ? _c(
                                  "div",
                                  {
                                    key: "cta_" + key,
                                    class: _vm.multiLineClass,
                                    attrs: {
                                      "data-aos": _vm.animate
                                        ? "fade-up"
                                        : undefined,
                                    },
                                  },
                                  [
                                    _c(
                                      "" +
                                        (_vm.theme === "gold"
                                          ? "gold-estate-cta-card"
                                          : "estate-cta-card"),
                                      {
                                        tag: "component",
                                        attrs: {
                                          data: _vm.ctaCards[key % 3],
                                          background:
                                            "" +
                                            (key % 3 === 0
                                              ? "bg-gray-light"
                                              : "bg-primary"),
                                        },
                                      }
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: _vm.multiLineClass,
                                attrs: {
                                  "data-aos": _vm.animate
                                    ? "fade-up"
                                    : undefined,
                                },
                              },
                              [
                                _c(
                                  "" +
                                    (_vm.theme === "gold"
                                      ? "gold-estate-card"
                                      : "estate-card"),
                                  { tag: "component", attrs: { data: estate } }
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.mode === "list"
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-12 table-responsive" },
                            [
                              _c("table", { staticClass: "table" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.estateInfo.data,
                                    function (estate) {
                                      return _c("tr", { key: estate.id }, [
                                        _c(
                                          "td",
                                          {
                                            attrs: {
                                              width: "400",
                                              "data-label": "Naam",
                                            },
                                          },
                                          [_vm._v(_vm._s(estate.name))]
                                        ),
                                        _vm._v(" "),
                                        (estate.price &&
                                          estate.status.id === 1) ||
                                        (estate.price && estate.status.id === 2)
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      estate.price,
                                                      "EUR"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : estate.status.id === "sold"
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [_vm._v("Verkocht")]
                                            )
                                          : estate.status.id === "rented"
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "data-label": "Vraagprijs",
                                                },
                                              },
                                              [_vm._v("Verhuurd")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: { "data-label": "Opp." },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                estate.area
                                                  ? _vm.formatRoomSize(
                                                      estate.area,
                                                      "sq_m"
                                                    )
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              "data-label": "Slaapkamers",
                                            },
                                          },
                                          [_vm._v(_vm._s(estate.rooms))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              "data-label": "Verdieping",
                                            },
                                          },
                                          [_vm._v(_vm._s(estate.floor))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "text-right",
                                            attrs: { "data-label": "Blok" },
                                          },
                                          [_vm._v(_vm._s(estate.box))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { attrs: { "data-label": "" } },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "btn btn-primary float-right",
                                                attrs: {
                                                  href: _vm.route(
                                                    "resultaten.show",
                                                    {
                                                      slug:
                                                        estate.id +
                                                        "-" +
                                                        estate.city +
                                                        "-" +
                                                        estate.name,
                                                    }
                                                  ),
                                                },
                                              },
                                              [_vm._v("Ik ben geïnteresseerd")]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "map"
                      ? _c(
                          "div",
                          { staticClass: "col-12 col-lg-8" },
                          [
                            _c("google-map", {
                              attrs: {
                                markers: _vm.markers,
                                coordinates: false,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "map"
                      ? _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c(
                            "div",
                            { staticClass: "estate-slider" },
                            [
                              _vm._l(_vm.estateInfo.data, function (estate) {
                                return _c("estate-card", {
                                  key: estate.id,
                                  attrs: {
                                    data: estate,
                                    wrapperClass: "mb-grid",
                                  },
                                })
                              }),
                              _vm._v(" "),
                              _vm.canLoadMore &&
                              _vm.pageData.current !== _vm.pageData.last
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _vm.canLoadMore &&
                                      _vm.pageData.current !==
                                        _vm.pageData.last - 1 &&
                                      _vm.mode === "map"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "btn btn-outline-primary  border-50",
                                              on: { click: _vm.loadMore },
                                            },
                                            [_vm._v("Toon meer panden")]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]
                : !_vm.loading
                ? _c("div", { staticClass: "row w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-50 mx-auto text-center",
                        staticStyle: {
                          background: "whitesmoke",
                          color: "#333",
                          padding: "15px",
                          "border-radius": "4px",
                        },
                      },
                      [_vm._v("Er konden geen panden gevonden worden")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loading
                ? _c("div", { staticClass: "col-12" }, [_vm._m(1)])
                : _vm._e(),
            ]
          : _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass: "w-50 mx-auto text-center",
                  staticStyle: {
                    background: "whitesmoke",
                    color: "#333",
                    padding: "15px",
                    "border-radius": "4px",
                  },
                },
                [_vm._v("Er ging iets mis")]
              ),
            ]),
      ],
      2
    ),
    _vm._v(" "),
    !!_vm.canLoadMore &&
    _vm.pageData.current !== _vm.pageData.last &&
    (_vm.mode === "cards" || _vm.mode === "list") &&
    _vm.estateInfo.data.length > 0
      ? _c("div", { staticClass: "row mt-grid" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 d-flex align-items-center justify-content-center",
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn btn-outline-primary  border-50",
                  on: { click: _vm.loadMore },
                },
                [_vm._v("Toon meer panden")]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "400", scope: "col" } }, [_vm._v("Naam")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Vraagprijs"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Opp."),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Slaapkamers"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Verdieping"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Blok"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-50 mx-auto text-center" }, [
      _c("div", { staticClass: "lds-ellipsis" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }