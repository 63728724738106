<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  @media (min-width: 768px) {
    width: 700px;
  }
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.error-toast {
  padding: 4px 16px;
  color: #ff3b30;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #ffe6e4;
  display: table;
}
</style>

<template>
  <transition name="modal" v-if="$root.showModal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.stop="$root.showModal = false">
        <div @click.stop class="modal-container">
          <div class="modal-body">
            <div class="form-row">
              <div class="col-12">
                <h2 class="text-secondary font-weight-bold d-flex w-100 justify-content-between align-items-center">
                  Download plan
                  <i class="fas fa-times fa-xs" style="cursor: pointer" @click.stop="$root.showModal = false"></i>
                </h2>
                <p class="mb-27">Download de belangrijkste plannen in verband met dit pand.</p>
              </div>
            </div>
            <form v-if="!success" @submit.prevent="download()">
              <div class="form-row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <input required v-model="data.name" type="text" class="form-control" placeholder="Naam" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <input required v-model="data.phone" type="text" class="form-control" placeholder="Telefoonnummer" />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <input required v-model="data.email" type="email" class="form-control" placeholder="E-mail adres" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div v-if="!success && !loading && error !== ''" class="error-toast mt-3">{{ error }}</div>
              </div>
              <div class="mt-3 row justify-content-center align-items-center">
                <div class="col-auto">
                  <button class="btn btn-green mb-2" type="submit">
                    Download plan
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  </button>
                </div>
              </div>
            </form>
            <div v-else class="form-row">
              <a :href="$root.plan" download target="_blank" class="btn btn-grey mx-0 mx-lg-9 small mb-18">{{ $root.planName }} <i class="fas fa-download ml-9"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: (vm) => ({
    data: {
      id: '',
      name: '',
      email: '',
      phone: '',
    },
    success: false,
    loading: false,
    error: '',
  }),
  methods: {
    download() {
      if (this.data.email === '' || this.data.name === '') return;

      this.data.id = this.$root.propertyId;
      this.data.estateId = this.$root.estateId;
      this.loading = true;
      this.error = '';
      axios
        .post('/property/downloaddocuments', this.data)
        .then((res) => {
          this.success = true;
          this.loading = false;
        })
        .catch((e) => {
          this.success = false;
          this.loading = false;
          this.error = 'Er is iets misgegaan bij het verzenden van uw gegevens. Controleer of uw gegevens correct zijn en probeer het opnieuw.';
        });
    },
  },
  watch: {
    '$root.showModal': function () {
      this.success = false;
      this.loading = false;
    },
  },
};
</script>
