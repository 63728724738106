var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row project mb-24 mb-lg-80",
      class: "card-" + _vm.direction,
    },
    [
      _c(
        "div",
        {
          staticClass: "col-12 card-image",
          class: _vm.direction === "left" ? "col-lg-7" : "col-lg-7 offset-lg-1",
        },
        [
          _c("div", { staticClass: "position-relative" }, [
            _vm.project.pictures.length > 0
              ? _c("img", {
                  staticClass: "w-100 mb-24 mb-lg-0 large-img",
                  attrs: { src: _vm.project.pictures[0].urlXXL, alt: "" },
                })
              : _c("div", { staticClass: "w-100 mb-24 mb-lg-80 large-img" }),
            _vm._v(" "),
            _vm.project.purposeStatus.id === 3
              ? _c("div", { staticClass: "tag bg-primary" }, [
                  _vm._v("UITVERKOCHT"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.project.purposeStatus.id === 5
              ? _c("div", { staticClass: "tag bg-secondary" }, [
                  _vm._v("Bod aanvaard"),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-12 card-text",
          class: {
            "offset-lg-1 col-lg-4": _vm.direction === "left",
            "col-lg-4": _vm.direction === "right",
          },
        },
        [
          _c("div", {}, [
            _vm.project.purposeStatus.id === 1
              ? _c("p", { staticClass: "mb-8 project-tag" }, [
                  _vm._v("Beschikbaar"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.project.purposeStatus.id === 12
              ? _c("p", { staticClass: "mb-8 project-tag" }, [
                  _vm._v("In optie"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.project.purposeStatus.id === 3
              ? _c("p", { staticClass: "mb-8 project-tag" }, [
                  _vm._v("Verkocht"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h3", {
              staticClass: "mb-24 d-none d-lg-block project-title",
              domProps: {
                innerHTML: _vm._s(
                  _vm.project.name + " <span>in " + _vm.project.city + "</span>"
                ),
              },
            }),
            _vm._v(" "),
            _vm.project.acf && _vm.project.acf.intro
              ? _c("p", {
                  staticClass: "text-gray",
                  domProps: { innerHTML: _vm._s(_vm.project.acf.intro) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "mb-56 mb-lg-80 d-flex flex-row text-primary" },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.svg("marker", "icon icon-primary mr-8")
                    ),
                  },
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.project.address) +
                    " " +
                    _vm._s(_vm.project.number) +
                    ", " +
                    _vm._s(_vm.project.zip) +
                    " " +
                    _vm._s(_vm.project.city) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-24 mb-lg-80 d-lg-none" }, [
            _c("h3", {
              staticClass: "h4 mb-24",
              domProps: { innerHTML: _vm._s(_vm.project.name) },
            }),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary-v2",
                attrs: {
                  href: _vm.route("nieuwbouw.show", {
                    slug:
                      _vm.project.id +
                      "-" +
                      _vm.project.city +
                      "-" +
                      _vm.project.name,
                  }),
                },
              },
              [_vm._v(" Bekijk het project ")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }