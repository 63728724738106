var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "div",
        {
          key: item.value,
          staticClass: "item",
          class: { active: _vm.localValue === item.value },
          on: {
            click: function ($event) {
              return _vm.handleInput(item.value)
            },
          },
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.svg(item.icon, "icon " + item.iconClass)),
            },
          }),
          _vm._v(" "),
          _c("div", {}, [_vm._v(_vm._s(item.name))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }