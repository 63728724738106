var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.item.title
      ? _c("div", { staticClass: "item-title" }, [
          _vm._v(_vm._s(_vm.item.title)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.item.items, function (faq, key) {
        return _c(
          "div",
          {
            key: key,
            staticClass: "collapse-panel",
            on: {
              click: function ($event) {
                return _vm.toggleKey(key)
              },
            },
          },
          [
            _c("div", {
              staticClass: "icon",
              class: { open: _vm.active === key },
            }),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "collapse-title",
                class: { open: _vm.active === key },
              },
              [
                _vm.item.show_number
                  ? _c(
                      "div",
                      { staticClass: "counter" },
                      [
                        Number(_vm.startCount) + Number(key) <= 9
                          ? [_vm._v("0")]
                          : _vm._e(),
                        _vm._v(_vm._s(Number(_vm.startCount) + Number(key))),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v("\n        " + _vm._s(faq.post_title) + "\n      "),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "collapse-description",
              class: { open: _vm.active === key },
              domProps: { innerHTML: _vm._s(faq.post_content) },
            }),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.item.link && _vm.item.link.url !== ""
      ? _c(
          "a",
          { staticClass: "read-more", attrs: { href: _vm.item.link.url } },
          [
            _vm._v("\n    " + _vm._s(_vm.item.link.title) + "\n    "),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.svg("arrow-right-narrow")) },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }