<style lang="scss" scoped></style>

<template>
    <div>
        <div id="results" class="row"></div>

        <div class="row pt-4">
            <div class="col-12 text-center">
                <button
                    id="nextpage"
                    data-page="0"
                    @click="loadProperties(null, false)"
                    class="btn btn-green"
                    :class="{ disabled: done }"
                >
                    Meer laden
                </button>
            </div>
        </div>

        <div v-if="map" id="map" :class="{ 'position-absolute': !map }">
            <leaflet-map
                :lat="51.21"
                :long="4.4"
                :markers="properties"
            ></leaflet-map>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projectId: {
            default: false
        }
    },
    data() {
        return {
            properties: [],
            page: 0,
            done: false,
            map: false
        };
    },

    mounted() {
        let self = this;
        window.propertyres = this;
        this.$nextTick(() => {
            self.page = 0;
            self.done = false;
            self.loadProperties();
            if (!this.projectId) {
                $("#searchform").focus();
            }
            $(document).on("click", "#switch-map", function() {
                self.switchMap();
            });
            $(document).on("click", "#clear-filters", function() {
                self.clearFilters();
            });
            $(document).on("click", "#switch-map-off", function() {
                self.switchMapOff();
            });
            $(document).on("change", ".changesettings", function() {
                self.changesettings();
            });
            $(document).on("change", "#selectplaats", function() {
                self.selectPlace();
            });
        });
    },

    methods: {
        clearFilters() {
            $("#searchform").val("");
            $("#minprice").val("geenvoorkeur");
            $("#maxprice").val("geenvoorkeur");
            $("#saleOrLet").val("sale");
            $("#selectplaats").val("alle");
            $("#selectstraal").val("none");
            $("#verkochtOptie").prop("checked", true);
            $("#filtertuin").prop("checked", false);
            $("#filterterras").prop("checked", false);
            $("#filterparkeerplaats").prop("checked", false);
            $("#filternieuwbouw").prop("checked", false);
            $("#filterlift").prop("checked", false);
            $("#selectstraal").attr("disabled", true);
            $("#results").html(" ");
            window.propertyres.page = 0;
            window.propertyres.done = false;
            window.propertyres.loadProperties();
        },
        switchMapOff() {
            $("#results").show();
            $("#nextpage").show();
            window.propertyres.map = false;
        },
        switchMap() {
            $("#results").hide();
            $("#nextpage").hide();
            window.propertyres.page = 0;
            window.propertyres.done = false;
            window.propertyres.map = true;
            window.propertyres.loadProperties();
        },
        changesettings() {
            $("#results").html(" ");
            window.propertyres.page = 0;
            window.propertyres.done = false;
            window.propertyres.loadProperties();
        },
        selectPlace() {
            var plaats = $("#selectplaats");
            if (plaats.val() === "alle") {
                $("#selectstraal").attr("disabled", true);
                $("#selectstraal").val("none");
            } else {
                $("#selectstraal").attr("disabled", false);
            }
        },
        loadProperties() {
            let search = $("#searchform").val();
            let minprice = $("#minprice").val();
            let maxprice = $("#maxprice").val();
            let city = $("#selectplaats").val();
            let floor = $("#selectedfloor").val();
            let box = $("#selectedbox").val();
            let straal = $("#selectstraal").val();
            let type = $("#selecttype").val();
            let saleOrLet = $("#saleOrLet").val();
            let filtertuin = $("#filtertuin").prop("checked");
            let filterterras = $("#filterterras").prop("checked");
            let filterparkeerplaats = $("#filterparkeerplaats").prop("checked");
            let filternieuwbouw = $("#filternieuwbouw").prop("checked");
            let filterlift = $("#filterlift").prop("checked");
            let verkochtOptie = $("#verkochtOptie").prop("checked");

            let data = {
                page: this.page,
                search: search,
                minprice: minprice,
                maxprice: maxprice,
                city: city,
                type: type,
                filtertuin: filtertuin,
                filterterras: filterterras,
                filterparkeerplaats: filterparkeerplaats,
                filternieuwbouw: filternieuwbouw,
                filterlift: filterlift,
                straal: straal,
                saleOrLet: saleOrLet,
                verkochtOptie: verkochtOptie,
                map: this.map,
                projectId: this.projectId,
                floor: floor,
                box: box
            };

            if (history.pushState) {
                let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?`;
                Object.keys(data).forEach(key => {
                    if (data[key] !== undefined)
                        newurl += `&${key}=${data[key]}`;
                });
                window.history.pushState({ path: newurl }, "", newurl);
            }
            axios.post("/resultaten/filter", data).then(res => {
                if (!window.propertyres.map) {
                    $("#results").append(res.data.properties);
                    window.propertyres.page = res.data.next;
                    window.propertyres.done = res.data.done;
                } else {
                    window.propertyres.properties = res.data.properties;
                }

                $(".amount-shown").html(res.data.count);
            });
        }
    }
};
</script>
