<style lang="scss" scoped>
header {
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ::v-deep .logo {
    height: 40px;
    @media (min-width: 768px) {
      height: 60.07px;
    }
  }
  .close-link {
    color: #afafaf;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    ::v-deep .close {
      height: 14px;
      width: 14px;
      margin-left: 15px;
      path {
        transition: all 0.15s ease-in-out;
        stroke: #afafaf;
      }
    }
    &:hover {
      color: #000000;
      ::v-deep .close {
        path {
          stroke: #000000;
        }
      }
    }
  }
}

main {
  padding: 20px 20px 20px 20px;
  //min-height: 100vh;
  display: flex;
  flex-flow: row;
  gap: 20px;
  height: 100vh;
  @media (min-width: 768px) {
    padding: 0px 40px 40px 40px;
    height: calc(100vh - 120px - 140.06px);
    height: calc(var(--vh, 1vh) * 100 - 120px - 140.06px);
    gap: 40px;
  }
}

aside {
  border-radius: 40px 40px 0 0;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 100%;
  z-index: 50;
  padding: 24px;
  left: 0;
  @media (min-width: 768px) {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    top: unset;
    left: unset;
    z-index: 10;
    position: relative;
    width: 320px;
    flex: none;
    height: 100%;
    padding: 0;
  }
  .side-title {
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 26px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .side-subtext {
    color: var(--gray);
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .side-group-title {
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    font-weight: 600; // Old 900
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .side-block {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    transition: opacity 0.15s ease-in-out;
    margin-bottom: 20px;
    ::v-deep .side-icon {
      height: 24px;
      margin-right: 20px;

      &:not(.stroke):not(.fill) {
        path {
          stroke: #c6e8b7;
        }
      }
      &.stroke {
        path {
          stroke: #c6e8b7;
        }
      }
      &.fill {
        path {
          fill: #c6e8b7;
        }
      }
    }
  }
}

.page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.progress-wrapper {
  width: 100%;
  padding-bottom: 40px;
  display: block;
  position: relative;
  .progress-bar {
    height: 10px;
    border-radius: 5px;
    background-color: #83c056;
    position: relative;
    transition: width 0.15s ease-in-out;
  }
  .progress-holder {
    height: 4px;
    width: 100%;
    border-radius: 5px;
    background-color: #c6e8b7;
    position: absolute;
    top: 3px;
  }
}
.content-wrapper {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: calc(100% - 180px);
  @media (min-width: 768px) {
    height: calc(100% - 50px);
  }
  &.success-step {
    padding: 40px 20px;
    align-items: center;
    display: flex;
    flex-flow: column;

    @media (min-width: 768px) {
      padding: 80px 40px;
    }
  }
  > form {
    overflow: auto;
    max-height: 100%;
    padding: 40px 20px;
    @media (min-width: 768px) {
      padding: 80px 40px;
    }
  }
  &.calendly-step {
    padding: 0;
  }
  .next-button,
  .prev-button {
    border-radius: 30px;
    background-color: #ffffff;
    height: 41px;
    display: flex;
    padding: 0 21.5px;
    text-transform: uppercase;
    align-items: center;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    font-weight: 600; // Old 900
    letter-spacing: 1px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    z-index: 100;
    &:focus {
      outline: none;
    }
  }

  .prev-button {
    border: 1px solid #e1e1e1;
    left: 20px;
    top: -19px;
    transition: box-shadow 0.15s ease-in-out;
    &:hover {
      box-shadow: 0 0 0 2px #e1e1e1;
    }
  }
  .next-button {
    border: 3px solid #83c056;
    border-radius: 30px;
    right: 20px;
    bottom: -19px;
    transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    &:hover {
      background: #83c056;
      color: #fff;
      ::v-deep .lds-ring {
        div {
          border: 1px solid #fff !important;
          border-color: #fff transparent transparent transparent !important;
        }
      }
    }
    ::v-deep .lds-ring {
      div {
        border: 1px solid #83c056 !important;
        border-color: #83c056 transparent transparent transparent !important;
      }
    }
  }
}

.price-hubble-step {
  &.gap-40 {
    gap: 40px;
  }
  .step-title {
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 24px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 39px;
    }
  }
  .step-sub-title {
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .step-description {
    color: var(--gray);
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .step-label {
    color: #83c056;
    font-family: CoreSansC; // Old Lato
    font-size: 12px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 10px;
    &.mt-10 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
  .faded {
    opacity: 0;
    pointer-events: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .custom-control-label {
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 500;
    padding-top: 3px !important;
  }
  .step-form-control {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    transition: border 0.15s ease-in-out;
    caret-color: #83c056;
    height: 39px;
    padding: 0 12.5px;
    width: 100%;
    &:is(textarea) {
      height: 150px;
      padding-top: 15px;
    }
    &:disabled {
      border: 1px solid #e1e1e1;
      background-color: #f7f7f7;
      color: #e1e1e1;
    }
    &:hover {
      border: 1px solid #afafaf;
    }
    &:focus,
    &:active {
      outline: none;
      border: 1px solid #c6e8b7;
    }
    &::placeholder {
      color: #afafaf;
    }
    &.has-errors {
      border: 1px solid #ff3b30;
    }
  }
  .suffix {
    color: #83c056;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
    position: absolute;
    right: 30px;
    top: 10px;
    display: flex;
    flex-flow: wrap;
    gap: 5.67px;
    .circle {
      height: 18.33px;
      width: 18.33px;
      border: 2px solid #83c056;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600; // Old bold
      font-size: 16px;
      transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
      cursor: pointer;
      &:hover {
        background: #83c056;
        color: #fff;
      }
    }
  }
  .error-message {
    margin-top: 10px;
    color: #ff3b30;
    font-family: CoreSansC; // Old Lato
    font-size: 12px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 15px;
  }
}
.error-toast {
  padding: 4px 16px;
  color: #ff3b30;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #ffe6e4;
  display: table;
}

.success-title {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 32px;
  font-weight: 600; // Old bold
  letter-spacing: 0;
  line-height: 39px;
  margin-bottom: 20px;
}
.success-title-sm {
  color: #000000;
  font-family: CoreSansC; // Old Lato
  font-size: 22px;
  font-weight: 600; // Old bold
  letter-spacing: 0;
  line-height: 39px;
  margin-bottom: 16px;
}
.success-description {
  color: var(--gray);
  font-family: CoreSansC; // Old Lato
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 40px;
}

.estimation-range {
  font-size: 22px;
  font-weight: 600;
  color: #393941;
}
.estimation-avg {
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
}

.estimation-quality {
  .select {
    display: inline-flex;
    flex-flow: row;
    gap: 8px;
    height: 40px;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .item {
      &.active ~ *,
      &.active {
        ::v-deep .star {
          path {
            fill: #83c056;
            stroke: #83c056;
          }
        }
      }
      ::v-deep .star {
        height: 20px;
        path {
          transition: path 0.15s ease-in-out, stroke 0.15s ease-in-out;
          stroke: #e1e1e1;
        }
      }
    }
  }
  .description {
    margin-left: 12px;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }
  label {
    color: var(--gray);
    font-family: CoreSansC; // Old Lato
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0px;
    font-weight: 500;
  }
}
::v-deep .success-logo {
  height: 60.07px;
}
</style>

<template>
  <div>
    <header>
      <a href="/" v-html="svg('logo', 'logo')"></a>
      <a href="/" class="close-link">
        Sluiten
        <span v-html="svg('cross', 'close')"></span>
      </a>
    </header>
    <main>
      <aside class="sidebar">
        <h1 class="side-title">Een kennismaking inplannen met uw lokale Copandi makelaar?</h1>
        <div class="side-subtext">
          U kan hiernaast onmiddellijk een vrijblijvende kennismaking inplannen in de agenda van onze lokale Copandi makelaar via <span class="text-primary">Kennismaking afspraak</span>. De kennismaking is een vrijblijvende afspraak waarbij onze makelaar kennis maakt met u als eigenaar en met uw
          woning. Indien gewenst leveren we na het bezoek een gratis waardebepaling van uw woning af en doen we u een concreet voorstel tot samenwerking. <br />

          <span v-if="readMore">
            <br />
            Je kan ook kiezen voor een <span class="text-primary">Digitale schatting</span> wanneer je graag reeds een indicatie hebt van de prijs van de woning. Houd er rekening mee dat dit slechts een prijsindicatie is op basis van AI en dit de expertise van een makelaar nooit kan vervangen. Het
            is aangeraden om nadien onze makelaar uit te nodigen om de waardebepaling persoonlijk af te werken.<br /><br />
            Indien u hierbij ondersteuning nodig hebt, kan u ons steeds bereiken op <a href="tel:080054311">0800 54 311</a> of via <a href="mailto:info@copandi.be">info@copandi.be</a>
          </span>

          <div class="mt-8">
            <a href="#" class="small soft-underline" @click="readMore = !readMore">{{ !readMore ? 'Lees meer' : 'Lees minder' }}</a>
          </div>
        </div>
        <div class="side-group-title" v-show="data.location.address.street !== ''">overzicht</div>
        <div class="side-block" v-show="data.location.address.street !== ''">
          <span v-html="svg('marker-hollow', 'side-icon')"></span>
          {{ data.location.address.street }} {{ data.location.address.houseNumber }}, {{ data.location.address.postCode }} {{ data.location.address.city }}
        </div>
        <div class="side-block" v-show="data.propertyType.code !== ''">
          <span v-if="data.propertyType.code === 'house'" v-html="svg('house-outline', 'side-icon stroke')"></span>
          <span v-if="data.propertyType.code === 'apartment'" v-html="svg('appartment-outline', 'side-icon fill')"></span>
          <span v-if="data.propertyType.code === 'ground'" v-html="svg('ground-outline', 'side-icon fill')"></span>
          <span v-if="data.propertyType.code === 'commercial'" v-html="svg('commercial-outline', 'side-icon fill')"></span>
          {{ humanized.propertyType[data.propertyType.code] }} <template v-if="data.propertyType.subcode !== ''"> - {{ humanized.propertyType[data.propertyType.subcode] }}</template>
        </div>
        <div class="side-block align-items-start">
          <span v-if="data.numberOfBedrooms !== undefined || data.numberOfBathrooms !== undefined" v-html="svg('list', 'side-icon')"></span>
          <div class="d-flex flex-column">
            <span v-if="data.numberOfBedrooms !== undefined">{{ data.numberOfBedrooms }} slaapkamer(s)</span>
            <span v-if="data.numberOfBathrooms !== undefined">{{ data.numberOfBathrooms }} badkamer(s)</span>
            <span v-if="data.propertyType.code === 'Appartement' && data.numberOfFloorsInBuilding !== undefined">{{ data.numberOfFloorsInBuilding }} verdiepingen</span>
            <span v-if="data.propertyType.code === 'Appartement' && data.floorNumber !== undefined">Verdieping {{ data.floorNumber }}</span>
            <span v-if="data.livingArea">{{ data.livingArea }}m² woonoppervlakte</span>
            <span v-if="data.landArea">{{ data.landArea }}m² grondoppervlakte</span>
            <span v-if="data.buildingYear">bouwjaar {{ data.buildingYear }}</span>
            <span v-if="has_garden && data.gardenArea">{{ data.gardenArea }}m² tuin</span>
            <span v-if="has_balcony && data.balconyArea">{{ data.balconyArea }}m² balkon/terras</span>
            <span v-if="data.hasLift">Lift aanwezig</span>
          </div>
        </div>

        <div v-if="data.quality && data.condition" class="side-block align-items-start">
          <span
            v-if="(data.quality && data.quality.kitchen && data.condition.kitchen) || (data.quality && data.quality.bathrooms && data.condition.bathrooms) || (data.quality && data.quality.flooring && data.condition.flooring) || (data.quality && data.quality.windows && data.condition.windows)"
            v-html="svg('badge', 'side-icon')"
          ></span>
          <div class="d-flex flex-column">
            <span v-if="data.quality.kitchen && data.condition.kitchen">Keuken — {{ humanized.quality[data.quality.kitchen] }} en {{ humanized.condition[data.condition.kitchen] }}</span>
            <span v-if="data.quality.bathrooms && data.condition.bathrooms">Badkamer — {{ humanized.quality[data.quality.bathrooms] }} en {{ humanized.condition[data.condition.bathrooms] }}</span>
            <span v-if="data.quality.flooring && data.condition.flooring">Vloeren — {{ humanized.quality[data.quality.flooring] }} en {{ humanized.condition[data.condition.flooring] }}</span>
            <span v-if="data.quality.windows && data.condition.windows">Ramen — {{ humanized.quality[data.quality.windows] }} en {{ humanized.condition[data.condition.windows] }}</span>
          </div>
        </div>
      </aside>
      <div class="page-content">
        <div class="progress-wrapper">
          <div class="progress-holder"></div>
          <div class="progress-bar" :style="{ width: `${(step / totalSteps) * 100}%`, maxWidth: '100%' }"></div>
        </div>
        <ValidationObserver v-if="!success && step !== 8" ref="form" class="content-wrapper" :class="{ 'calendly-step': step === 8 }" v-slot="{ passes }">
          <button v-if="!isFirstStep" type="button" class="prev-button" @click="prevStep">Vorige</button>
          <form @submit.prevent>
            <!-- STEP 1 -->
            <section v-if="step === 1" id="step-1" class="price-hubble-step">
              <h2 class="step-title">Welke schatting verkies je?</h2>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-80 d-block" persist rules="required" :bails="false" name="Type schatting">
                <el-estimation-type-select v-model="estimation_type" v-bind="ariaInput" :class="{ 'has-errors': errors.length }"></el-estimation-type-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </section>
            <!-- END STEP 1 -->

            <!-- STEP 2 -->
            <section v-else-if="step === 2" id="step-2" class="price-hubble-step d-flex flex-column flex-lg-row gap-40 h-100">
              <div class="w-lg-50">
                <h2 class="step-title">Wat is het adres van de te schatten woning?</h2>
                <div class="step-description">Om een locatie te selecteren, kunt u ook de pin op de kaart verplaatsen.</div>
                <label for="adres" class="step-label">Adres</label>

                <div v-if="!data.location.address.street">
                  <vue-google-autocomplete id="adres" classname="step-form-control" placeholder="Wat is het adres van de woning?" type="search" autocomplete="off" v-on:placechanged="getAddressData" country="be"></vue-google-autocomplete>
                </div>

                <div class="row mx-n8" :class="{ faded: !data.location.address.street }">
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" class="col-7 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.street" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Straat" />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" class="col-3 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.houseNumber" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Nummer"
                  /></ValidationProvider>
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="" :bails="false" class="col-2 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.bus" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Bus"
                  /></ValidationProvider>
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="" :bails="false" class="col-4 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.floor" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Verdiep"
                  /></ValidationProvider>
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" class="col-4 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.postCode" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Postcode"
                  /></ValidationProvider>
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" class="col-4 mb-16 px-8"
                    ><input v-bind="ariaInput" v-model="data.location.address.city" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Stad"
                  /></ValidationProvider>
                </div>
              </div>
              <div class="w-lg-50">
                <google-map v-if="coordinates" ref="googleMap" :markers="[coordinates]" :zoom="18" :coordinates="coordinates" class="h-100"></google-map>
              </div>
            </section>
            <!-- END STEP 2 -->

            <section v-else-if="step === 3" id="step-3" class="price-hubble-step h-100">
              <h2 class="step-title">Wat is het type van het pand?</h2>
              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-80 d-block" persist rules="required" :bails="false" name="Type pand">
                <el-estate-type-select v-model="data.propertyType.code" v-bind="ariaInput" :class="{ 'has-errors': errors.length }" @input="data.propertyType.subcode = ''"></el-estate-type-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider v-if="subTypes.length" :key="`subtypes_${data.propertyType.subcode}`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Type ${data.type}`">
                <div class="mb-40">Over welk subtype pand gaat het?</div>
                <el-estate-sub-type-select :items="subTypes" v-model="data.propertyType.subcode" v-bind="ariaInput" :class="{ 'has-errors': errors.length }"></el-estate-sub-type-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </section>

            <section v-else-if="step === 4" id="step-4" class="price-hubble-step h-100">
              <h2 class="step-title">Kan je er iets meer over vertellen over het pand?</h2>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required|min_value:1|max_value:20" :bails="false" name="Aantal slaapkamers" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="bedrooms">Aantal slaapkamers</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix"><span class="circle" @click="data.numberOfBedrooms = subtractOrDefault(data.numberOfBedrooms)">-</span><span class="circle" @click="data.numberOfBedrooms = addOrDefault(data.numberOfBedrooms)">+</span></span>
                  <input v-bind="ariaInput" id="bedrooms" v-model="data.numberOfBedrooms" min="1" max="20" type="number" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Aantal slaapkamers" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required|min_value:1|max_value:5" :bails="false" name="Aantal badkamers" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="bathrooms">Aantal badkamers</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix"><span class="circle" @click="data.numberOfBathrooms = subtractOrDefault(data.numberOfBathrooms)">-</span><span class="circle" @click="data.numberOfBathrooms = addOrDefault(data.numberOfBathrooms)">+</span></span>
                  <input v-bind="ariaInput" id="bathrooms" v-model="data.numberOfBathrooms" min="1" max="5" type="number" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Aantal badkamers" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'apartment'" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" name="Aantal verdiepingen" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="numberOfFloorsInBuilding">Aantal verdiepingen</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix"><span class="circle" @click="data.floors = subtractOrDefault(data.numberOfFloorsInBuilding)">-</span><span class="circle" @click="data.numberOfFloorsInBuilding = addOrDefault(data.numberOfFloorsInBuilding)">+</span></span>
                  <input v-bind="ariaInput" id="numberOfFloorsInBuilding" v-model="data.numberOfFloorsInBuilding" min="0" type="number" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Aantal verdiepingen" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'apartment'" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" name="Verdiep" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="floorNumber">Verdiep van de woning</label>
                <div class="col-12 col-lg-4">
                  <input v-bind="ariaInput" id="floorNumber" v-model="data.floorNumber" min="0" type="number" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Verdiep" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'house'" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required|min_value:50|max_value:5000" :bails="false" name="Grondoppervlakte" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="livable_surface">Grondoppervlakte</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix">m²</span>
                  <input v-bind="ariaInput" id="livable_surface" v-model="data.landArea" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Grondoppervlakte" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required|min_value:10|max_value:800" :bails="false" name="Bewoonbare oppervlakte" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="livable_surface">Bewoonbare oppervlakte</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix">m²</span>
                  <input v-bind="ariaInput" id="livable_surface" v-model="data.livingArea" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Bewoonbare oppervlakte" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required|min_value:1850" :bails="false" name="Bouwjaar" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="construction_year">Bouwjaar</label>
                <div class="col-12 col-lg-4">
                  <input v-bind="ariaInput" id="construction_year" v-model="data.buildingYear" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Bouwjaar" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'apartment'" v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Tuin" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="has_garden">Tuin</label>
                <div class="col-12 col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input v-model="has_garden" id="has_garden" name="has_garden" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="has_garden"> Tuin aanwezig </label>
                  </div>
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="has_garden" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" name="Tuin oppervlakte" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="garden_surface">Tuin oppervlakte</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix">m²</span>
                  <input v-bind="ariaInput" id="garden_surface" v-model="data.gardenArea" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Tuin oppervlakte" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Balkon of terras" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="has_balcony">Balkon of terras</label>
                <div class="col-12 col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input v-model="has_balcony" id="has_balcony" name="has_balcony" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="has_balcony"> Balkon of terras aanwezig </label>
                  </div>
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="has_balcony" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" name="Balkon / Terras oppervlakte" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="balcony_surface">Balkon / Terras oppervlakte</label>
                <div class="col-12 col-lg-4">
                  <span class="suffix">m²</span>
                  <input v-bind="ariaInput" id="balcony_surface" v-model="data.balconyArea" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Balkon / Terras oppervlakte" />
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'apartment'" v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Lift" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="hasLift">Lift</label>
                <div class="col-12 col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input v-model="data.hasLift" id="hasLift" name="hasLift" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="hasLift">Lift aanwezig </label>
                  </div>
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'house'" v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Sauna" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="hasLift">Sauna</label>
                <div class="col-12 col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input v-model="data.hasSauna" id="hasSauna" name="hasSauna" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="hasSauna">Sauna aanwezig </label>
                  </div>
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider v-if="data.propertyType.code === 'house'" v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Zwembad" class="row mb-16">
                <label class="col-12 col-lg-3 mt-10 step-label" for="hasLift">Zwembad</label>
                <div class="col-12 col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input v-model="data.hasPool" id="hasPool" name="hasPool" type="checkbox" class="custom-control-input" />
                    <label class="custom-control-label" for="hasPool">Zwembad aanwezig </label>
                  </div>
                  <span class="error-message" v-if="errors.length">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </section>

            <section v-else-if="step === 5" id="step-5" class="price-hubble-step h-100">
              <h2 class="step-title">Wat is de staat van het pand?</h2>

              <div class="step-sub-title">Keuken</div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`q_kitchen`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Keuken kwaliteit`">
                    <el-estate-quality v-bind="ariaInput" v-model="data.quality.kitchen"></el-estate-quality>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-12 col-lg-8">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`c_kitchen`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Keuken staat`">
                    <el-estate-state v-bind="ariaInput" v-model="data.condition.kitchen"></el-estate-state>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="step-sub-title">Badkamers</div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`q_bathrooms`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Badkamer kwaliteit`">
                    <el-estate-quality v-bind="ariaInput" v-model="data.quality.bathrooms"></el-estate-quality>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-12 col-lg-8">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`c_bathrooms`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Badkamer staat`">
                    <el-estate-state v-bind="ariaInput" v-model="data.condition.bathrooms"></el-estate-state>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="step-sub-title">Vloeren</div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`q_flooring`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Vloer kwaliteit`">
                    <el-estate-quality v-bind="ariaInput" v-model="data.quality.flooring"></el-estate-quality>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-12 col-lg-8">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`c_flooring`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Vloer staat`">
                    <el-estate-state v-bind="ariaInput" v-model="data.condition.flooring"></el-estate-state>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="step-sub-title">Ramen</div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`q_windows`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Raam kwaliteit`">
                    <el-estate-quality v-bind="ariaInput" v-model="data.quality.windows"></el-estate-quality>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-12 col-lg-8">
                  <ValidationProvider v-if="subTypes.length" class="mb-20 d-block" :key="`c_windows`" v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" :name="`Raam staat`">
                    <el-estate-state v-bind="ariaInput" v-model="data.condition.windows"></el-estate-state>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </section>

            <section v-else-if="step === 6" id="step-6" class="price-hubble-step h-100">
              <h2 class="step-title">Wanneer wil je verkopen?</h2>

              <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist rules="required" :bails="false" name="Duurtijd">
                <el-estate-sub-type-select :items="['asap', 'three_to_six', 'six_or_more', 'not_for_sale']" v-model="data.duration" v-bind="ariaInput" :class="{ 'has-errors': errors.length }"></el-estate-sub-type-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </section>

            <section v-else-if="step === 7" id="step-7" class="price-hubble-step h-100">
              <h2 class="step-title">Wat zijn je contactgegevens?</h2>

              <div class="row">
                <div class="col-12 col-lg-6">
                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-16 d-block" persist rules="required" :bails="false" name="Voornaam">
                    <label class="step-label">Voornaam</label>
                    <input v-bind="ariaInput" v-model="data.user.first_name" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Voornaam" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-16 d-block" persist rules="required" :bails="false" name="Achternaam">
                    <label class="step-label">Achternaam</label>
                    <input v-bind="ariaInput" v-model="data.user.last_name" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Achternaam" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-16 d-block" persist rules="required" :bails="false" name="Email">
                    <label class="step-label">Email</label>
                    <input v-bind="ariaInput" v-model="data.user.email" type="email" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Email" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" mode="passive" class="mb-16 d-block" persist rules="required|phone" :bails="false" name="Telefoonnummer">
                    <label class="step-label">Telefoonnummer</label>
                    <input v-bind="ariaInput" v-model="data.user.phone" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Telefoonnummer" />

                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" class="mb-16 d-block" persist :bails="false" name="Opmerking">
                    <label class="step-label">Opmerking</label>
                    <textarea v-bind="ariaInput" v-model="data.user.remark" type="text" class="step-form-control" :class="{ 'has-errors': errors.length }" placeholder="Opmerking" cols="8"> </textarea>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" persist :bails="false" name="Nieuwsbrief">
                    <div class="custom-control custom-checkbox">
                      <input v-model="data.accept_newsletter" id="accept_newsletters" name="accept_newsletters" type="checkbox" class="custom-control-input" />
                      <label class="custom-control-label" for="accept_newsletters">Ja, ik wil de nieuwsbrieven ontvangen.</label>
                    </div>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </section>

            <div v-if="Object.values(errors).length > 0" :class="{ 'mt-32': step !== 8 }">
              <div v-for="(errorMessage, errorType) in errors" class="error-toast">
                <template v-if="errorMessage.length > 0">
                  {{ errorMessage[0] }}
                </template>
              </div>
            </div>
            <div v-else-if="errors.pricehubble && errors.pricehubble.length > 0" :class="{ 'mt-32': step !== 8 }">
              <div v-for="(errorMessage, errorType) in errors.pricehubble" class="error-toast">
                <template v-if="errorMessage.length > 0">
                  {{ errorMessage }}
                </template>
              </div>
            </div>

            <button v-if="step !== 8" type="button" class="next-button" @click.prevent="passes(nextStep)">
              {{ isLastStep && estimation_type !== 'appointment' ? 'Schatting aanvragen' : 'Volgende' }}
              <el-loader class="ml-16 loading" :loading="loading"></el-loader>
            </button>
          </form>
        </ValidationObserver>
        <div v-else-if="!loading && step !== 8 && valuation" class="content-wrapper success-step text-center">
          <h2 class="success-title mb-32">Ons <span class="text-primary">Schattingsbereik</span></h2>

          <div v-if="Object.values(errors).length > 0" :class="{ 'mt-32': step !== 8 }">
            <div v-for="(errorMessage, errorType) in errors" class="error-toast">
              <template v-if="errorMessage.length > 0">
                {{ errorMessage[0] }}
              </template>
            </div>
          </div>

          <div class="estimation-range mb-16">{{ formatPrice(valuation.salePriceRange.lower, valuation.currency) }} — {{ formatPrice(valuation.salePriceRange.upper, valuation.currency) }}</div>
          <div class="estimation-avg mb-32">Gemiddelde prijs {{ formatPrice(valuation.salePrice, valuation.currency) }}</div>

          <div class="estimation-quality mb-8 text-left">
            <label class="d-block">Nauwkeurigheid</label>
            <div class="select">
              <span class="description">{{ confidence[valuation.confidence] }}</span>
              <div class="item" v-for="item in ['good', 'medium', 'poor', 'bad', 'none']" :key="item.name" :class="{ active: valuation.confidence === item }">
                <span v-html="svg('star', 'star')"></span>
              </div>
            </div>
          </div>

          <p class="success-description mb-64">
            De schatting van je woning is vrij nauwkeurig. Al vervangt een online schatting nooit het <br />
            werk van een specialist in je regio. Contacteer je makelaar om je online schatting te bevestigen.
          </p>

          <h3 class="success-title-sm">Wil je een gedetailleerde schatting?</h3>
          <p class="success-description">Op basis van jouw gegevens heeft Copandi alvast een makelaar voor jou gekozen.</p>
          <button class="btn btn-primary" @click="goToCalendlyStep()">Plan een afspraak in</button>
        </div>
        <div v-else-if="step === 8" class="content-wrapper success-step calendly-step">
          <section id="step-8" class="price-hubble-step h-100 w-100">
            <div v-if="Object.values(errors).length > 0">
              <div v-for="(errorMessage, errorType) in errors" class="error-toast">
                <template v-if="errorMessage.length > 0">
                  {{ errorMessage[0] }}
                </template>
              </div>
            </div>

            <div id="calendly-inline-widget" class="w-100 h-100"></div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ElLoader from '../parts/el-loader';
import ElEstateTypeSelect from '../parts/el-estate-type-select';
import ElEstimationTypeSelect from '../parts/el-estimation-type-select';
import ElEstateSubTypeSelect from '../parts/el-estate-sub-type-select';
import ElEstateState from '../parts/el-estate-state';
import ElEstateQuality from '../parts/el-estate-quality';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email, min_value, max_value } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

import VueGoogleAutocomplete from 'vue-google-autocomplete';

localize('nl', nl);
extend('required', required);
extend('min_value', min_value);
extend('max_value', max_value);
extend('email', email);
extend('phone', (value) => {
  const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value.replace(/\s/g, ''));

  if (!isValid) {
    return 'Gelieve een correct telefoonnummer in te geven.';
  }

  return true;
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoogleAutocomplete,
    ElLoader,
    ElEstateTypeSelect,
    ElEstateSubTypeSelect,
    ElEstateState,
    ElEstateQuality,
    ElEstimationTypeSelect,
  },

  data() {
    return {
      totalSteps: 7,
      step: 1,
      address: undefined,
      coordinates: undefined,
      estimation_type: '',
      has_garden: false,
      has_balcony: false,
      pipedriveId: null,
      ziplist: [],

      utm_source: '',
      utm_medium: '',
      utm_id: '',

      readMore: false,

      confidence: {
        good: 'Goed',
        medium: 'Gemiddeld',
        poor: 'Minder',
      },

      data: {
        location: {
          address: {
            street: '',
            city: '',
            postCode: '',
            houseNumber: '',
          },
          coordinates: {
            latitude: NaN,
            longitude: NaN,
          },
        },
        propertyType: {
          code: '',
          subcode: '',
        },
        numberOfBathrooms: undefined,
        numberOfBedrooms: undefined,
        numberOfFloorsInBuilding: undefined,
        floorNumber: undefined,
        buildingYear: '',
        livingArea: '',
        landArea: '',
        hasLift: false,
        hasSauna: false,
        hasPool: false,
        gardenArea: '',
        balconyArea: '',
        duration: '',
        condition: { kitchen: '', flooring: '', windows: '', bathrooms: '' },
        quality: { kitchen: '', flooring: '', windows: '', bathrooms: '' },
        user: {
          email: '',
          first_name: '',
          last_name: '',
          phone: '',
          remark: '',
        },
        accept_newsletter: false,
      },
      humanized: {
        propertyType: {
          house: 'Huis',
          apartment: 'Appartement',
          ground: 'Grond',
          commercial: 'Handelspand',
          apartment_normal: 'Appartement',
          apartment_studio: 'Studio',
          apartment_penthouse: 'Penthouse',
          apartment_maisonette: 'Duplex',
          house_detached: 'Open',
          house_semi_detached: 'Halfopen',
          house_row_middle: 'Gesloten',
        },
        condition: {
          new_or_recently_renovated: 'Nieuwstaat',
          well_maintained: 'Goed onderhouden',
          renovation_needed: 'Renovatie nodig',
        },
        quality: {
          simple: 'Eenvoudig',
          normal: 'Normaal',
          high_quality: 'Kwalitatief',
          luxury: 'Premium',
        },
        duration: {
          asap: 'Zo snel mogelijk',
          three_to_six: 'Binnen 3 tot 6 maanden',
          six_or_more: 'Over meer dan 6 maanden',
          not_for_sale: 'Ik wil niet verkopen',
        },
      },
      success: false,
      loading: false,
      errors: [],
      valuation: false,
    };
  },

  computed: {
    isFirstStep() {
      return this.step === 1;
    },
    isLastStep() {
      return this.step === this.totalSteps;
    },
    subTypes() {
      if (this.data.propertyType.code === 'house') {
        return ['house_detached', 'house_semi_detached', 'house_row_middle'];
      }

      if (this.data.propertyType.code === 'apartment') {
        return ['apartment_normal', 'apartment_studio', 'apartment_penthouse', 'apartment_maisonette'];
      }

      return [];
    },
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    const street = urlParams.get('street');
    if (street) this.data.location.address.street = street;

    const street_number = urlParams.get('street_number');
    if (street_number) this.data.location.address.houseNumber = street_number;

    const postal_code = urlParams.get('postal_code');
    if (postal_code) this.data.location.address.postCode = postal_code;

    const locality = urlParams.get('locality');
    if (locality) this.data.location.address.city = locality;

    const lat = urlParams.get('lat');
    if (lat) this.data.location.coordinates.latitude = lat;

    const long = urlParams.get('long');
    if (long) this.data.location.coordinates.longitude = long;

    const utm_source = urlParams.get('utm_source');
    if (utm_source) this.utm_source = utm_source;

    const utm_medium = urlParams.get('utm_medium');
    if (utm_medium) this.utm_medium = utm_medium;

    const utm_id = urlParams.get('utm_id');
    if (utm_id) this.utm_id = utm_id;

    const type = urlParams.get('type');

    axios.get('/api/settings/ziplist').then((response) => {
      this.ziplist = response.data;
    });

    if (type === 'digitale-schatting') {
      this.estimation_type = 'digital';
      this.step = 2;
    } else if (type === 'schatting-afspraak') {
      this.estimation_type = 'appointment';
      this.step = 2;
    }
  },

  methods: {
    prevStep() {
      if (this.isFirstStep) {
        return;
      }

      if ((this.step === 6 && ['ground', 'commercial'].includes(this.data.propertyType.code)) || (this.step === 6 && this.estimation_type === 'appointment')) {
        return (this.step = 3);
      }

      this.step--;
    },

    nextStep() {
      if (this.isLastStep) {
        if (this.isLastStep && this.estimation_type !== 'appointment') {
          return this.submit();
        } else {
          this.success = true;
          this.goToCalendlyStep();
          return;
        }
      }

      if ((this.step === 3 && ['ground', 'commercial'].includes(this.data.propertyType.code)) || (this.step === 3 && this.estimation_type === 'appointment')) {
        return (this.step = 6);
      }

      this.step++;

      if (window.hasOwnProperty('dataLayer') && dataLayer && this.step === 2) {
        // @ts-ignore
        let dlEvent = dataLayer.push({
          event: `copandi.${this.estimation_type === 'appointment' ? 'afspraak' : 'schatting'}.start`,
          response: [
            { name: 'href', value: window.location.href },
            { name: 'pathname', value: window.location.pathname },
          ],
        });
        console.log('Datalayer event send: ', dlEvent);
      }
    },

    goToCalendlyStep() {
      this.step = 8;
      this.submitCalendlyStep();
      this.$nextTick(function () {
        this.initCalendly();
      });
      return;
    },

    subtractOrDefault(value) {
      if (value === undefined) {
        return 0;
      }
      if (value === 0) {
        return 0;
      }
      return Number(value) - 1;
    },

    addOrDefault(value) {
      if (value === undefined) {
        return 1;
      }
      return Number(value) + 1;
    },

    submitCalendlyStep() {
      let data = JSON.parse(JSON.stringify(this.data));

      data.utm_source = this.utm_source;
      data.utm_medium = this.utm_medium;
      data.utm_id = this.utm_id;

      delete data['buildingYear'];
      delete data['condition'];
      delete data['floorNumber'];
      delete data['gardenArea'];
      delete data['landArea'];
      delete data['livingArea'];
      delete data['numberOfBedrooms'];
      delete data['numberOfBathrooms'];
      delete data['numberOfFloorsInBuilding'];
      delete data['balconyArea'];
      delete data['gardenArea'];
      delete data['hasSauna'];
      delete data['hasPool'];
      delete data['quality'];

      this.errors = [];
      this.loading = true;
      this.success = false;
      this.pipedriveId = null;
      axios
        .post('/api/make-appointment', data)
        .then((response) => {
          this.success = true;
          if (response.data.pipedrive_id) {
            this.pipedriveId = response.data.pipedrive_id;
            console.log(this.pipedriveId);
          }

          if (window.hasOwnProperty('dataLayer') && dataLayer) {
            // @ts-ignore
            let dlEvent = dataLayer.push({
              event: 'copandi.afspraak',
              response: [
                { name: 'href', value: window.location.href },
                { name: 'pathname', value: window.location.pathname },
              ],
            });
            console.log('Datalayer event send: ', dlEvent);
          }
        })
        .catch((e) => {
          if (e.response.data.hasOwnProperty('errors')) {
            this.errors = e.response.data.errors;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    submit: _.debounce(function () {
      if (['ground', 'commercial'].includes(this.data.propertyType.code)) {
        this.goToCalendlyStep();
        return;
      }
      const data = this.clean(this.data);

      data.utm_source = this.utm_source;
      data.utm_medium = this.utm_medium;
      data.utm_id = this.utm_id;

      this.errors = [];
      this.loading = true;
      this.success = false;
      this.pipedriveId = null;
      axios
        .post('/api/make-estimation', data)
        .then((response) => {
          const valuations = response.data.data.valuations;
          if (response.data.pipedrive_id) {
            this.pipedriveId = response.data.pipedrive_id;
          }
          this.valuation = valuations[0][0];
          this.success = true;

          if (window.hasOwnProperty('dataLayer') && dataLayer) {
            // @ts-ignore
            let dlEvent = dataLayer.push({
              event: 'copandi.schatting',
              response: [
                { name: 'href', value: window.location.href },
                { name: 'pathname', value: window.location.pathname },
              ],
            });
            console.log('Datalayer event send: ', dlEvent);
          }
        })
        .catch((e) => {
          if (e.response.data.hasOwnProperty('errors')) {
            this.errors = e.response.data.errors;
          }
          if (e.response.data.hasOwnProperty('error')) {
            this.errors = { pricehubble: [JSON.parse(e.response.data.error.message)[0]] };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),

    initCalendly() {
      this.$nextTick(() => {
        Calendly.initInlineWidget({
          url: this.getCalendlyLink(),
          parentElement: document.getElementById('calendly-inline-widget'),
          prefill: {
            name: `${this.data.user.first_name} ${this.data.user.last_name}`,
            email: this.data.user.email,
            phone: this.data.user.phone,
            customAnswers: {
              a1: this.data.user.phone,
              a2: `Type: ${this.humanized.propertyType[this.data.propertyType.code]} ${this.humanized.propertyType[this.data.propertyType.subcode]}
Duur: ${this.humanized.duration[this.data.duration]}
Opermerking: ${this.data.user.remark}`,
              a3: this.data.location.address.street,
              a4: this.data.location.address.houseNumber,
              a5: this.data.location.address.postCode,
              a6: this.data.location.address.city,
              a7: this.pipedriveId,
            },
          },
        });

        window.removeEventListener('message', this.handleCalendlyScheduled);
        window.addEventListener('message', this.handleCalendlyScheduled);
      });
    },

    handleCalendlyScheduled(e) {
      if (e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0) {
        if (e.data.event === 'calendly.event_scheduled') {
          if (this.pipedriveId) {
            this.updatePipedriveAppointment();
          }
        }
      }
    },

    updatePipedriveAppointment: _.debounce(function () {
      if (!this.pipedriveId) {
        return;
      }

      const data = {
        id: this.pipedriveId,
        event: 'appointment_created',
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_id: this.utm_id,
      };

      this.errors = [];
      this.loading = true;
      this.success = false;
      axios
        .post('/api/update-appointment', data)
        .then(() => {
          this.success = true;
        })
        .catch((e) => {
          if (e.response.data.hasOwnProperty('errors')) {
            this.errors = e.response.data.errors;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),

    getCalendlyLink() {
      if (!this.data.location.address.postCode) {
        return 'https://calendly.com/copandidigital/kennismakingsgesprek';
      }

      let link = false;
      this.ziplist.forEach((person) => {
        let zipcodes = person.zipcodes.replace(/\s/g, '').split(',');

        if (zipcodes.length > 0 && zipcodes.includes(`${this.data.location.address.postCode}`)) {
          link = person.calendly_url;
        }
      });

      if (link) {
        return link;
      }

      return 'https://calendly.com/copandidigital/kennismakingsgesprek';
    },

    clean(object) {
      Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === 'object') {
          this.clean(v);
        }
        if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v === '') {
          if (Array.isArray(object)) {
            object.splice(k, 1);
          } else {
            delete object[k];
          }
        }
      });
      return object;
    },

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.coordinates = { lat: this.address.latitude, lng: this.address.longitude };
      this.data.location.coordinates.latitude = this.address.latitude;
      this.data.location.coordinates.longitude = this.address.longitude;

      this.data.location.address.street = this.address.route;
      this.data.location.address.houseNumber = this.address.street_number;
      this.data.location.address.postCode = this.address.postal_code;
      this.data.location.address.city = this.address.locality;

      this.$nextTick(function () {
        this.$refs.googleMap.center = this.coordinates;
      });
    },
  },
};
</script>
