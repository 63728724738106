<style lang="scss" scoped></style>

<template>
  <div></div>
</template>

<script>
export default {
  props: {
    reviews: {
      default: () => [],
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
