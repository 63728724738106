var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.setleReferral
    ? _c(
        "section",
        {
          staticClass: "container mb-40 mb-lg-80 mb-xl-120",
          attrs: { id: "setle" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-8 col-xl-6 mx-auto" }, [
              _c("div", { staticClass: "setle-section text-center" }, [
                _c("h4", { staticClass: "mb-32 ff-serif" }, [_vm._v("Setle")]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "text-uppercase font-weight-bold text-primary mb-16 caption",
                  },
                  [_vm._v("KRIJG EEN HELDER BEELD VAN JE VASTGOEDKOSTEN")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mb-24 text-gray" }, [
                  _vm._v(
                    "Met onze digitale oplossing breng je eenvoudig het volledig kostenplaatje van dit vastgoed in kaart. Van renovatie- of notariskosten tot bijkomende kosten: Setle voorkomt verrassingen in de zoektocht naar jouw droomwoning"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { href: _vm.setleReferral, target: "_blank" },
                  },
                  [_vm._v("Bereken je kosten")]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }