<style lang="scss" scoped>
.title{
    font-size: 1rem;
    color: #fff;
    font-weight: 400;
    line-height: 1.5;
    &:hover{
        color: #BBA876;
    }
}
.pointer {
    cursor: pointer;
}
</style>

<template>
    <div class="container pt-24">
        <div class="row" id="test">
            <div class="col-12 d-flex justify-content-start align-items-center px-0">
                <a class="d-flex flex-row pointer" @click="downloadPdf">
                    <p class="mr-2 title">Print pagina </p>
                    <img style="height: 40px; width: 40px" src="/images/print-gold.svg" alt="print-button">
                </a>
            </div>
        </div>
        <print-layout :property="property" v-show="false" id="element-to-print"></print-layout>
    </div>

</template>

<script>
import PrintLayout from "../print-layout";
export default {
    components: {
        PrintLayout
    },
    props: {
        property: {
            type: Object,
        },
    },
    data: vm => ({
    }),
    methods: {
        async downloadPdf() {
            var element = document.getElementById('element-to-print').innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = element;
            window.print();
            window.location.reload();
        },
    },
}
</script>
