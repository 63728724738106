var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "a",
        {
          staticClass: "estate-card",
          class: [
            _vm.wrapperClass,
            {
              nc:
                _vm.data.status == "sold" ||
                _vm.data.status == "rented" ||
                _vm.data.status == "option",
            },
          ],
          attrs: { href: _vm.computedRoute },
        },
        [
          _c("div", { staticClass: "estate-image" }, [
            _c("img", {
              staticClass: "lazyload",
              attrs: { "data-srcset": _vm.imageStyle },
            }),
            _vm._v(" "),
            !_vm.data.displayPrice
              ? _c("div", { staticClass: "tag price" }, [
                  _vm._v("Prijs op aanvraag"),
                ])
              : [3, 17].includes(_vm.data.purposeStatus.id)
              ? _c("div", { staticClass: "tag sold" }, [
                  _c("span", [_vm._v("Verkocht")]),
                ])
              : [4].includes(_vm.data.purposeStatus.id)
              ? _c("div", { staticClass: "tag rented" }, [
                  _c("span", [_vm._v("Verhuurd")]),
                ])
              : [12, 13, 21].includes(_vm.data.purposeStatus.id)
              ? _c("div", { staticClass: "tag option" }, [
                  _c("span", [_vm._v("In optie")]),
                ])
              : [5, 6, 16].includes(_vm.data.purposeStatus.id)
              ? _c("div", { staticClass: "tag option" }, [
                  _c("span", [_vm._v("Bod aanvaard")]),
                ])
              : (_vm.data.price && _vm.data.status.id === 1) ||
                (_vm.data.price && _vm.data.status.id === 2)
              ? _c("div", { staticClass: "tag price" }, [
                  _vm.data.price != 1
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formatPrice(_vm.data.price, "EUR"))),
                      ])
                    : _vm._e(),
                ])
              : _vm.data.status !== "sold" &&
                _vm.data.status !== "option" &&
                _vm.data.status !== "rented" &&
                _vm.isViewDay
              ? _c("div", { staticClass: "tag viewday" }, [
                  _c("span", [_vm._v(_vm._s(_vm.isViewDay))]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex estate-bottom flex-column px-24 py-32" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 d-flex align-items-center" }, [
                  !_vm.data.project_id
                    ? _c(
                        "h5",
                        {
                          staticClass:
                            "estate-title text-light-alt h3 d-flex align-items-center mb-12",
                        },
                        [
                          _c("span", {
                            staticClass: "d-inline-flex mr-12",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.svg("location-marker-gold")
                              ),
                            },
                          }),
                          _vm._v(_vm._s(_vm.data.city)),
                        ]
                      )
                    : _c(
                        "h5",
                        { staticClass: "estate-title text-secondary h3" },
                        [
                          _c("span", {
                            staticClass:
                              "d-inline-flex align-items-center mr-12",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.svg("location-marker-gold")
                              ),
                            },
                          }),
                          _vm._v(_vm._s(_vm.data.description_title)),
                        ]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "estate-footer col-12 d-flex align-items-center",
                  },
                  [
                    _vm.data.groundArea
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "estate-line d-flex justify-content-center align-items-center",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center",
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.data.groundArea) + "m"),
                                _c("sup", [_vm._v("2")]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.area && _vm.data.groundArea
                      ? _c("div", { staticClass: "dot" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.area
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "estate-line d-flex justify-content-center align-items-center",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center",
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.data.area) + "m"),
                                _c("sup", [_vm._v("2")]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.rooms && _vm.data.area
                      ? _c("div", { staticClass: "dot" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.rooms
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "estate-line d-flex justify-content-center align-items-center",
                          },
                          [_vm._v(_vm._s(_vm.data.rooms) + " slaapkamers")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.box && _vm.data.is_project
                      ? _c("div", { staticClass: "dot" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.box && _vm.data.is_project
                      ? _c("div", { staticClass: "estate-line" }, [
                          _vm._v("Blok " + _vm._s(_vm.data.box)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.floor && _vm.data.is_project
                      ? _c("div", { staticClass: "dot" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.floor && _vm.data.is_project
                      ? _c("div", { staticClass: "estate-line" }, [
                          _vm._v("Vloer " + _vm._s(_vm.data.floor)),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }