<style lang="scss" scoped>
.select {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  .item {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    transition: box-shadow 0.15s ease-in-out;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 23.33px;
    color: #000000;
    font-family: CoreSansC; // Old Lato
    font-size: 15px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    height: 140px;
    width: 140px;
    @media (min-width: 768px) {
      height: 159px;
      width: 159px;
    }
    &.active,
    &:hover {
      box-shadow: 0 0 0 2px #83c056;
      border-color: transparent;
    }
    ::v-deep .icon {
      height: 73.33px;
      &.fill {
        path {
          fill: #83c056;
        }
      }
      &.stroke {
        path {
          stroke: #83c056;
        }
      }
    }
  }
}
</style>

<template>
  <div class="select">
    <div class="item" v-for="item in items" :key="item.value" :class="{ active: localValue === item.value }" @click="handleInput(item.value)">
      <div v-html="svg(item.icon, `icon ${item.iconClass}`)"></div>
      <div class="">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },

  data: () => {
    return {
      items: [
        { name: 'Huis', value: 'house', icon: 'Huis' },
        { name: 'Appartement', value: 'apartment', icon: 'Appartement' },
        { name: 'Grond', value: 'ground', icon: 'Grond' },
        { name: 'Handelspand', value: 'commercial', icon: 'Handelspand' },
      ],
      localValue: '',
    };
  },

  mounted() {
    this.localValue = this.value;
  },

  methods: {
    handleInput(value) {
      this.localValue = value;
      this.$emit('input', this.localValue);
    },
  },

  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
