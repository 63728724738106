<style lang="scss" scoped></style>

<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(verifyAndSubmit)">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-40">Meer info aanvragen</h4>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Voornaam</label>
          <input v-model="data.first_name" v-bind="ariaInput" name="first_name" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Achternaam</label>
          <input v-model="data.last_name" v-bind="ariaInput" name="last_name" type="text" label="Achternaam" class="form-control mb-0" placeholder="Achternaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Emailadres</label>
          <input v-model="data.email" v-bind="ariaInput" name="email" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Telefoonnummer</label>
          <input v-model="data.phone" v-bind="ariaInput" name="phone" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-24">
          <label for="" class="mb-16">Bericht</label>
          <textarea v-model="data.message" v-bind="ariaInput" name="message" type="text" label="Bericht" class="form-control mb-0" placeholder="Typ je bericht"></textarea>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12">
          <button type="submit" class="btn d-flex align-items-center justify-content-center btn-primary">
            Verstuur
            <el-loader class="ml-16" :loading="loading"></el-loader>
          </button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },
  props: {
    estateId: {
      type: String,
      required: true,
    },
    negotiator: {
      type: String,
      required: true,
    },
    estateName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        gold: false,
      },
      success: false,
      loading: false,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.data.estateId = this.estateId;
      this.data.negotiator = this.negotiator;
      this.data.estateName = this.estateName;
      axios
        .post('/api/v2/estate/contact', this.data)
        .then(res => {
          this.success = true;
          this.loading = false;
          this.data = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message: '',
          };
          this.$refs.form.reset();
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    async verifyAndSubmit() {
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha('login');

      if (token) {
        this.loading = true;
        axios
          .post('/api/verify/token', { token: token })
          .then(res => {
            if (res.data && res.data.success && res.data.score >= 0.5) {
              this.onSubmit();
            }
          })
          .catch(() => {
            this.success = false;
            this.loading = false;
          });
      }
    },
  },
};
</script>
