<style lang="scss" scoped></style>

<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form v-if="!showFiles" @submit.prevent="handleSubmit(onSubmit)">
      <div class="row">
        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-16">
          <label for="" class="mb-8 small">* Voornaam</label>
          <input v-model="data.first_name" v-bind="ariaInput" name="first_name" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-16">
          <label for="" class="mb-8 small">* Emailadres</label>
          <input v-model="data.email" v-bind="ariaInput" name="email" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-16">
          <label for="" class="mb-8 small">* Telefoonnummer</label>
          <input v-model="data.phone" v-bind="ariaInput" name="phone" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12">
          <button type="submit" class="btn btn-gold d-flex align-items-center justify-content-center btn-primary">
            Download documenten
            <el-loader class="ml-16" :loading="loading"></el-loader>
          </button>
        </div>
      </div>
    </form>
    <div v-else class="d-flex flex-column mx-n8">
      <a v-for="doc in docs" :key="doc.id" :href="doc.url" target="_blank" class="d-flex flex-row w-100 align-items-center p-8 document-line">
        <span v-html="svg('file-download', 'file-download flex-none mr-16')"></span>
        <span class="text-secondary w-100 font-weight-bold break-line">{{ doc.filename }}</span>
      </a>
    </div>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },
  props: {
    docs: {
      default: () => [],
    },
    estateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {
        first_name: '',
        phone: '',
        email: '',
      },
      success: false,
      loading: false,
      showFiles: false,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.data.estateId = this.estateId;
      axios.post('/api/v2/documents/download', this.data).then(res => {
        this.success = true;
        this.loading = false;
        this.showFiles = true;
      });
    },
  },
};
</script>
