var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row", attrs: { id: "results" } }),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-4" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-green",
            class: { disabled: _vm.done },
            attrs: { id: "nextpage", "data-page": "0" },
            on: {
              click: function ($event) {
                return _vm.loadProperties(null, false)
              },
            },
          },
          [_vm._v("\n                Meer laden\n            ")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.map
      ? _c(
          "div",
          { class: { "position-absolute": !_vm.map }, attrs: { id: "map" } },
          [
            _c("leaflet-map", {
              attrs: { lat: 51.21, long: 4.4, markers: _vm.properties },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }