<style lang="scss" scoped></style>

<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(verifyAndSubmit)">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-40">Uw gegevens</h5>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Voornaam</label>
          <input v-model="data.first_name" v-bind="ariaInput" name="first_name" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Achternaam</label>
          <input v-model="data.last_name" v-bind="ariaInput" name="last_name" type="text" label="Achternaam" class="form-control mb-0" placeholder="Achternaam" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Emailadres</label>
          <input v-model="data.email" v-bind="ariaInput" name="email" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Telefoonnummer</label>
          <input v-model="data.phone" v-bind="ariaInput" name="phone" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-12">
          <h5 class="my-40">Info over <span class="text-primary">jouw woning</span> te koop</h5>
        </div>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">* Straatnaam + Huisnummer</label>
          <input v-model="data.address" v-bind="ariaInput" name="address" type="text" label="Straatnaam + Huisnummer" class="form-control mb-0" placeholder="Straatnaam + Huisnummer" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 col-xl-3 mb-gridh">
          <label for="" class="mb-16">* Postcode</label>
          <input v-model="data.postal_code" v-bind="ariaInput" name="postal_code" type="text" label="Postcode" class="form-control mb-0" placeholder="Postcode" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 col-xl-3 mb-gridh">
          <label for="" class="mb-16">* Gemeente</label>
          <input v-model="data.city" v-bind="ariaInput" name="city" type="text" label="Gemeente" class="form-control mb-0" placeholder="Gemeente" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">Bouwjaar</label>
          <input v-model="data.build_year" v-bind="ariaInput" name="build_year" type="text" label="Bouwjaar" class="form-control mb-0" placeholder="Bouwjaar" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="" class="mb-16">Rennovatiejaar</label>
          <input v-model="data.renovation_year" v-bind="ariaInput" name="renovation_year" type="text" label="Rennovatiejaar" class="form-control mb-0" placeholder="Rennovatiejaar" />
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="totalBedrooms" class="mb-16">Aantal slaapkamers</label>
          <select v-model="data.bedrooms" v-bind="ariaInput" name="bedrooms" class="form-control" id="totalBedrooms">
            <option value="1" selected>1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
          <label for="totalBathrooms" class="mb-16">Aantal badkamers</label>
          <select v-model="data.bathrooms" v-bind="ariaInput" name="bathrooms" class="form-control" id="totalBathrooms">
            <option value="1" selected>1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
        </ValidationProvider>

        <div class="col-6 col-lg-3">
          <div class="form-check">
            <input v-model="data.garden" type="checkbox" class="form-check-input" id="garden" />
            <label class="form-check-label" for="garden">Tuin</label>
          </div>
        </div>

        <div class="col-6 col-lg-3">
          <div class="form-check">
            <input v-model="data.garage" type="checkbox" class="form-check-input" id="garage" />
            <label class="form-check-label" for="garage">Garage</label>
          </div>
        </div>

        <div class="col-12 mt-80 d-flex align-items-center">
          <button type="submit" class="btn d-flex align-items-center justify-content-center btn-primary">
            Verstuur
            <el-loader class="ml-16" :loading="loading"></el-loader>
          </button>
          <div v-if="success && !loading" class="ml-40">
            <div>Je verzoek werd verzonden!</div>
          </div>
          <div v-if="errors && errors.length > 0" class="ml-40">
            <div class="text-danger" v-for="(error, key) in errors" :key="`error_${key}`">{{ error }}</div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import ElLoader from '../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ElLoader,
  },

  props: ['env'],

  data() {
    return {
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        postal_code: '',
        city: '',
        bedrooms: '1',
        bathrooms: '1',
        build_year: '',
        renovation_year: '',
        garden: false,
        garage: false,
      },
      success: false,
      loading: false,
      errors: [],
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      axios
        .post('/api/estimation', this.data)
        .then(res => {
          this.success = true;
          this.loading = false;
          this.errors = [];
          this.data = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            postal_code: '',
            city: '',
            bedrooms: '',
            bathrooms: '',
            build_year: '',
            renocation_year: '',
            garden: false,
            garage: false,
          };
          this.$refs.form.reset();
          window.location.assign("/schattingsformulier-bedankpagina");
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
          this.errors = ['Er ging iets mis tijdens het verstuuren van je verzoek.'];
        });
    },
    async verifyAndSubmit() {
      if (this.env === 'local') {
        this.onSubmit();
        return;
      }
      await this.$recaptchaLoaded();

      const token = await this.$recaptcha('login');

      if (token) {
        this.loading = true;
        axios
          .post('/api/verify/token', { token: token })
          .then(res => {
            if (res.data && res.data.success && res.data.score >= 0.5) {
              this.onSubmit();
            }
          })
          .catch(() => {
            this.success = false;
            this.loading = false;
          });
      }
    },
  },
};
</script>
