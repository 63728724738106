var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "office-spotlight" }, [
    _c(
      "div",
      { ref: "slider", class: _vm.wrapperClass },
      _vm._l(_vm.offices, function (office) {
        return _c("div", { key: office.id, attrs: { "data-aos": "fade-up" } }, [
          _c("div", { staticClass: "office-card" }, [
            _c("a", {
              staticClass: "office-image",
              style:
                "background-image: url(" +
                office.acf.image.url +
                ");  padding-top: 66%;",
              attrs: { href: office.relative_link },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "office-text" }, [
              _c(
                "a",
                {
                  staticClass: "office-tag",
                  attrs: { href: "tel:" + office.acf.phone },
                },
                [_vm._v(_vm._s(office.acf.phone))]
              ),
              _vm._v(" "),
              _c("h5", {
                staticClass: "mb-16 office-title",
                domProps: {
                  innerHTML: _vm._s(
                    "<span>Copandi</span> <span class='text-normal'> " +
                      office.acf.title +
                      "</span>"
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-gray office-address soft-underline mb-18",
                  attrs: {
                    href:
                      "https://www.google.com/maps/place/" +
                      office.acf.location.lat +
                      "," +
                      office.acf.location.lng,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    _vm._s(office.acf.location.street_name) +
                      ", " +
                      _vm._s(office.acf.location.street_number) +
                      " " +
                      _vm._s(office.acf.location.city)
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-block btn-sm btn-primary-v2",
                  attrs: { href: office.acf.call_to_action, target: "_blank" },
                },
                [_vm._v("Plan een afspraak")]
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }