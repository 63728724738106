import { render, staticRenderFns } from "./print-page.vue?vue&type=template&id=3532328c&scoped=true&"
import script from "./print-page.vue?vue&type=script&lang=js&"
export * from "./print-page.vue?vue&type=script&lang=js&"
import style0 from "./print-page.vue?vue&type=style&index=0&id=3532328c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3532328c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frvanhove/Projects/copandi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3532328c')) {
      api.createRecord('3532328c', component.options)
    } else {
      api.reload('3532328c', component.options)
    }
    module.hot.accept("./print-page.vue?vue&type=template&id=3532328c&scoped=true&", function () {
      api.rerender('3532328c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/print-page.vue"
export default component.exports