<style lang="scss" scoped>
label {
    color: #E8E8E7;
    font-size: 14px;
    font-weight: 600; // Old bold
    letter-spacing: 0;
    line-height: 17px;

    span {
        font-weight: 200 !important;
    }
}

.privacy-label {
    font-size: 16px;
    line-height: 24px;
    color: rgba(232, 232, 231, 0.5);
    font-weight: normal;
    a {
        color: #BBA876;
    }
}

input[type="checkbox"]:checked {
    background-color: #BBA876;
}

input, textarea{
    color: rgba(232,232,231,0.5);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    border: 1px solid rgba(232,232,231,0.5);
    border-radius: 4px;
    background-color: rgba(0,0,0,0.9);
    &:focus{
        background-color: rgba(0,0,0,0.9);
        border: 1px solid #BBA876;
        color: rgba(232,232,231,0.5);
    }

}

.alert-success {
    color: #BBA876;;
    background-color: rgba(0,0,0,0.9);
    border-color: rgba(0,0,0,0.9);
}
</style>

<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">

        <form @submit.prevent="handleSubmit(verifyAndSubmit)">
            <div v-if="success" class="row">
                <div class="col-12">
                    <p class="alert alert-success py-18 rounded">Bedankt voor uw bericht, uw Gold makelaar contacteert u spoedig.</p>
                </div>
            </div>
            <div v-if="!success" class="row">
                <div class="col-12">
                    <h4 class="mb-40">Meer info aanvragen</h4>
                </div>

                <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
                    <label for="" class="mb-16"><span>*</span> Voornaam</label>
                    <input v-model="data.first_name" v-bind="ariaInput" name="first_name" type="text" label="Voornaam" class="form-control mb-0" placeholder="Voornaam" />
                    <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
                    <label for="" class="mb-16"><span>*</span> Achternaam</label>
                    <input v-model="data.last_name" v-bind="ariaInput" name="last_name" type="text" label="Achternaam" class="form-control mb-0" placeholder="Achternaam" />
                    <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider rules="required|email" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
                    <label for="" class="mb-16"><span>*</span> Emailadres</label>
                    <input v-model="data.email" v-bind="ariaInput" name="email" type="email" label="Emailadres" class="form-control mb-0" placeholder="Emailadres" />
                    <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 col-lg-6 mb-gridh">
                    <label for="" class="mb-16"><span>*</span> Telefoonnummer</label>
                    <input v-model="data.phone" v-bind="ariaInput" name="phone" type="text" label="Telefoonnummer" class="form-control mb-0" placeholder="Telefoonnummer" />
                    <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-24">
                    <label for="" class="mb-16">Bericht</label>
                    <textarea v-model="data.message" v-bind="ariaInput" name="message" type="text" label="Bericht" class="form-control mb-0" placeholder="Typ je bericht"></textarea>
                    <span class="text-danger" v-bind="ariaMsg">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors, ariaMsg, ariaInput }" :bails="false" tag="div" class="col-12 mb-24">
                    <input type="checkbox" required name="privacypolicy" id="privacypolicy"/>
                    <label for="privacypolicy" class="privacy-label">Ik ga akkoord met de <a href="">algemene voorwaarden</a>.</label>
                </ValidationProvider>

                <div class="col-12">
                    <button type="submit" class="btn btn-gold align-items-center justify-content-center">
                        Verstuur
                        <el-loader class="ml-16" :loading="loading"></el-loader>
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import ElLoader from '../../parts/el-loader';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json';

localize('nl', nl);
extend('required', required);
extend('email', email);

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ElLoader,
    },
    props: {
        estateId: {
            type: String,
            required: true,
        },
        negotiator: {
            type: String,
            required: true,
        },
        estateName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            data: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
                gold: true,
            },
            success: false,
            loading: false,
        };
    },

    methods: {
        onSubmit() {
            this.loading = true;
            this.data.estateId = this.estateId;
            this.data.negotiator = this.negotiator;
            this.data.estateName = this.estateName;
            axios
                .post('/api/v2/estate/contact', this.data)
                .then(res => {
                    this.success = true;
                    this.loading = false;
                    this.data = {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        message: '',
                    };
                    this.$refs.form.reset();
                })
                .catch(() => {
                    this.success = false;
                    this.loading = false;
                });
        },
        async verifyAndSubmit() {
            await this.$recaptchaLoaded();

            const token = await this.$recaptcha('login');

            if (token) {
                this.loading = true;
                axios
                    .post('/api/verify/token', { token: token })
                    .then(res => {
                        if (res.data && res.data.success && res.data.score >= 0.5) {
                            this.onSubmit();
                        }
                    })
                    .catch(() => {
                        this.success = false;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
