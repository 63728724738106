<style lang="scss">
.blog-slider {
  position: relative;
}
</style>

<template>
  <div :class="wrapperClass" class="blog-slider row">
    <!-- {{ route('blog.show', ['slug' => blog.slug]) }} -->
    <a v-for="blog in blogs" :key="blog.id" :href="route('blog.show', { slug: blog.slug })" class="blog-item d-block col-span-12 col-md-4">
      <img v-if="blog.acf && blog.acf.image && blog.acf.image.url" :data-src="blog.acf.image.url" class="lazyload blog-image mb-16 rounded" alt="" />
      <h5 class="title font-weight-bold mb-27" v-html="blog.title.rendered.replace(/(<([^>]+)>)/gi, '')"></h5>
      <p class="text-gray mb-0" v-html="`${blog.acf.intro.replace(/(<([^>]+)>)/gi, '').substring(0, 100)}...`"></p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    wrapperClass: {
      default: 'mb-0',
    },
    categoryIds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      blogs: [],
    };
  },

  mounted() {
    axios.get('/api/blog', { params: { categories: this.categoryIds } }).then((response) => {
      this.blogs = response.data;
    });
  },
};
</script>
