var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.displayPrice
    ? _c(
        "h4",
        {
          staticClass: "estate-price",
          class: _vm.propertySpecialType === "gold" ? "gold-price" : "",
        },
        [_vm._v("Prijs op aanvraag")]
      )
    : _vm.price &&
      [1, 2].includes(_vm.statusId) &&
      ![3, 4, 5, 6, 16].includes(_vm.purposeStatusId)
    ? _c(
        "h5",
        {
          staticClass: "estate-price",
          class: _vm.propertySpecialType === "gold" ? "gold-price" : "",
        },
        [
          _vm._v(_vm._s(_vm.formatPrice(_vm.price, "EUR")) + " "),
          [2].includes(_vm.purposeStatusId) ? [_vm._v("/ maand")] : _vm._e(),
        ],
        2
      )
    : _vm.purposeStatusId === 2
    ? _c("h5", { staticClass: "estate-price" }, [_vm._v("Verhuurd")])
    : _vm.purposeStatusId === 3
    ? _c("h5", { staticClass: "estate-price" }, [_vm._v("Verkocht")])
    : [12, 13, 21].includes(_vm.purposeStatusId)
    ? _c("h5", { staticClass: "estate-price" }, [_vm._v("In optie")])
    : [5, 6, 16].includes(_vm.purposeStatusId)
    ? _c("h5", { staticClass: "estate-price" }, [_vm._v("Bod aanvaard")])
    : _vm.isProject
    ? _c("h5", { staticClass: "estate-price" }, [_vm._v("Project")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }