var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "slider", class: _vm.wrapperClass },
    _vm._l(_vm.offices, function (office) {
      return _c("div", { key: office.id, attrs: { "data-aos": "fade-up" } }, [
        _c(
          "a",
          {
            staticClass: "gold-office-card",
            attrs: { href: office.relative_link },
          },
          [
            _c("div", { staticClass: "image-wrapper" }, [
              _c("img", {
                attrs: {
                  src: office.acf.image.url,
                  alt: office.acf.image.name,
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content-wrapper" }, [
              _c("p", { staticClass: "name" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.svg("location-marker-gold")),
                  },
                }),
                _vm._v(_vm._s(office.acf.title)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "address" }, [
                _vm._v(_vm._s(office.acf.address)),
              ]),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }